import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import PersonajeOscar from './images/Oscar-min.png';
import PersonajeMilena from './images/milenaAmaya.png';
import PersonajeAngela from './images/PaolaArchila.png';
import PersonajeAndrea from './images/AndreaOspina.png';
import PersonajeHeidy from './images/heidyRamirez.png';
import PersonajeJohanna from './images/Johanna.png';
import PersonajeAdriana from './images/Tatiana.png';
import PersonajeJohannaP from './images/JohanaPerez.png';
import PersonajeAlvaro from './images/Alvaro.png';
import PersonajeSara from './images/SaraAldana.png';
import PersonajeSebas from './images/MariaFernanda.png';
import PersonajeAura from './images/Aura1.png';
import PersonajeAuraM from './images/AuraM.png';
import logoVertical from './images/logo-vertical-blanco.png';
import PersonajeAnaGuerrero from './images/anaGuerrero.png';
import PersonajeOscarB from './images/Alex.png';
import PersonajeCatalina from './images/Catalina.png';
import PersonajeFabio from './images/fabioSanchez.png';
import PersonajeAngie from './images/angieCespedes.png';
import PersonajeCristina from './images/Cristina.png';
import PersonajeAngelica from './images/AngelicaCardenas.png';
import PersonajeMaibe from './images/maibeParedes.png';
import PersonajeElisa from './images/elisaAmezquita.png';
import PersonajeDavidc from './images/David-Castro.png';
import PersonajeDavid from './images/david.png';
import PersonajeErika from './images/Erika.png';
import PersonajeEstefania from './images/Estefania.png';
import PersonajeJessica from './images/Jessica.png';
import PersonajeJulian from './images/Julian.png';
import PersonajePaola from './images/Paola.png';
import PersonajeLaura from './images/LauraRiano.png';
import PersonajeWilmer from './images/Wilmer.png';
import PersonajeGiovanni from './images/giovanniGuatibonza.png';
import puntos from './images/puntos.png';
import { animated } from 'react-spring';
import { useSpring } from 'react-spring';
import {FormattedMessage} from 'react-intl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  equipo: {
    color: '#384c5b',
    textDecoration: 'underline',
    textUnderlineOffset: '0.2em',   
    fontWeight: 'bold',
    marginTop: '40px',
    width: '930px',
    margin: 'auto',
    fontFamily: 'proxima-nova',
    
  },
  equipoMobile: {
    color: '#384c5b',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',  
    textUnderlineOffset: '0.2em',  
    fontWeight: 'bold',
    marginBottom: '3%',    
    marginLeft: '10px',
    fontFamily: 'proxima-nova',
  },
  tagline: {
    color: '#384c5b',
    marginBottom: '120px',
    width: '930px',
    margin: 'auto',
    fontFamily: 'proxima-nova',
  },
  taglineMobile: {
    color: '#384c5b',    
    marginBottom: '15%',
    fontSize: '100%',
    marginLeft: '10px',
    fontFamily: 'proxima-nova',
  },
  title: {
    color: '#384c5b',
    fontWeight: 'bold',
    margin: '2%',
    fontSize: '170%',
  },
  box: {
    overflow: 'hidden',  
    position: 'relative'
  },
  boxChica:{    
    overflow: 'hidden',
    padding: '0%', 

  },
  boxChica2:{
    width:'32%',
    height: '17.3em', 
    marginTop:'-21%',
    marginLeft:'34%',
    overflow: 'hidden',
    padding: '0%', 

  },
  boxChica3:{
    width:'31.2%',
    height: '17.3em', 
    marginTop: '-21%',
    marginLeft: '1.8%',
    overflow: 'hidden',
    padding: '0%', 

  },  
  boxChica4:{
    width:'34%',
    marginTop: '-21.8%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
    overflow: 'hidden',
    padding: '0%', 

  },
  boxChica5:{
    width:'35%',    
    marginTop: '-21.5%',
    marginLeft: '0.5%',
    marginRight: '0.5%',
    overflow: 'hidden',
    padding: '0%', 

  },  
  boxChica6:{
    width:'32%',
    height: '17.3em', 
    marginTop:'-19.5%',
    marginLeft:'34%',
    padding: '0%', 
    overflow: 'hidden',
  },
  boxGrande:{
    width:'34.4%',
    height:'492px',
    marginRight: '0.5%',
    marginTop: '0.8%',
    marginLeft: '0.5%',
    overflow: 'hidden',  
    padding: '0%', 
  },
  boxErika:{
    width:'33.4%',
    height:'492px',
    marginLeft: '0.5%',
    marginRight: '0.5%',
    marginTop: '0.8%',
    overflow: 'hidden',  
    padding: '0%', 
  },
  boxJesica:{
    width:'33.5%',
    height:'492px',
    marginLeft: '0.2%',
    marginRight: '0.5%',
    marginTop: '0.8%',
    overflow: 'hidden',  
    padding: '0%', 
  },
  imagenBox: {
    width: '40em', 
    height: '350px',     
    margin: '0 0 0 -45%',
    float: 'left'
  },
  imagenBoxP: {
    width: '12em', 
    position: 'absolute',
    left: '0.5em'
  },
  imagenBoxP2: {
    width: '11.5em', 
    position: 'absolute',
    right: '0em'
  },
  imagenBoxP3: {
    width: '14em', 
    position: 'absolute',
    right: '-1em',
    top: '-10px'
  },
  imagenBoxP6: {
    width: '18em', 
    position: 'absolute',
    right: '-3em',
    top: '-1em'
  },
  imagenBoxP5: {
    width: '17em', 
    position: 'absolute',
    right: '-3em',
    top: '-1em',
  },
  imagenBoxP4: {
    width: '17em', 
    position: 'absolute',
    left: '-2.5em',
    top: '-0.5em'
  },
  imagenBoxP7: {
    width: '16em', 
    position: 'absolute',
    left: '-2em',
    top: '-1em'
  },
  imagenBox2: {
    width: '150%', 
    height: '350px',     
    margin: '5%  0 0 -45%',
    float: 'left'
  },
  imagenChicaBox:{
    width: '95%', 
    height: '90%',
    float: 'left',
    margin: '0 0 0 -30%'
  },
  imagenChicaBox2:{
    width: '95%', 
    height: '90%',
    float: 'left',
    margin: '7% 0 0 -30%'
  },
  imagenChicaBoxAura:{
    width: '55%', 
    height: '104%',
    float: 'left',
    margin: '0 0 0 50%',
    position: 'relative'
  },
  titleImage: {
    color: 'white',
    fontWeight: 'bold',
    marginTop: '0.5em',
    fontSize: '24px',
    left: '1em',
    position: 'absolute',
    fontFamily: 'proxima-nova',
  }, 
  titleImagenAngelica: {
    color: 'white',
    fontWeight: 'bold',
    marginTop: '0.5em',
    fontSize: '24px',
    left: '0.9em',
    width: '1em',
    position: 'absolute',
    fontFamily: 'proxima-nova',
  },
  titleImageColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1.5em',
    left: '7.5em',
    fontSize: '24px',
    fontFamily: 'proxima-nova',

  }, 
  titleImageColorDavid: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1.5em',
    fontSize: '24px',
    width:'40%', 
    textAlign: 'right',
    left: '53%',
    fontFamily: 'proxima-nova',

  },
  titleImagePaola: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '5em',
    left: '6.7em',
    fontSize: '24px',
    textAlign: 'right',
    width:'40%',
    fontFamily: 'proxima-nova',
  },
  titleImageTatiana: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1em',
    left: '70%',
    fontFamily: 'proxima-nova',
    fontSize: '24px',
  },
  titleImageColorWhite: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1em',
    left: '7em',
    fontSize: '24px',
    fontFamily: 'proxima-nova',

  },
  titleImageColorWhiteA: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1em',
    left: '6.5em',
    fontSize: '24px',
    textAlign: 'right',
    width: '40%',
    fontFamily: 'proxima-nova',

  },
  titleImageColorWhiteQuieres: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    top: '2em',
    left: '8.5em',
    fontSize: '22px',
    fontFamily: 'proxima-nova',

  },
  titleImageColorWhiteQuieres2: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    top: '4.5em',
    left: '15.8em',
    fontSize: '22px',
    fontFamily: 'proxima-nova',

  },
  titleImageColorWhiteQuieres3: {
    color: '#f4f4f4',
    position: 'absolute',
    top: '5.5em',
    fontFamily: 'proxima-nova',
    fontWeight: '100'   ,
    left: '64%',

  },
  titleImageColorWhiteQuieres3Mobile: {
    color: '#f4f4f4',
    position: 'relative',
    fontFamily: 'proxima-nova',
    fontWeight: '100'   ,
    marginLeft: '10%',

  },
  titleImageColorAdriana: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1em',
    left: '65%',
    fontSize: '24px',
    fontFamily: 'proxima-nova',

  },
  titleImageJessica: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '50%',
    left: '52%',
    fontSize: '24px',
    width: '5em',
    textAlign: 'right',
    fontFamily: 'proxima-nova',
  }, 
  titleImageColor2: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '3.5em',
    left: '0.5em',
    fontSize: '24px',
    fontFamily: 'proxima-nova',
  }, 
  titleImagenJohanna: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1em',
    left: '1em',
    fontSize: '24px',
    width: '1em',
    fontFamily: 'proxima-nova',
  }, 
  titleImagenJulian: {
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1em',
    left: '1em',
    fontSize: '24px',
    width: '1em',
    fontFamily: 'proxima-nova',
  }, 
  titleImagenMaibe: {
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'absolute',
    top: '1.5em',
    left: '1.5em',
    fontSize: '24px',
    width: '1em',
    fontFamily: 'proxima-nova',
  }, 
  titleImageColorGiovanni: {
    color: 'white',
    width: '1em',
    fontWeight: 'bold',
    position: 'absolute',
    top: '4em',
    left: '1em',
    fontSize: '24px',
    fontFamily: 'proxima-nova',
  }, 

  titleImage2: {
    color: 'white',
    fontWeight: 'bold',
    margin: '10%',
  }, 
  titleImageChica: {
    color: 'white',
    fontWeight: 'bold', 
    marginTop: '7%',
    fontSize: '2em',
  }, 
  titleImageEstefania: {
    color: 'white',
    fontWeight: 'bold', 
    marginTop: '7%',
    fontSize: '24px',
    width: '3em',
    left: '1em',
    position: 'absolute',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMilena: {
    color: 'white',
    fontWeight: 'bold', 
    position: 'absolute',
    top: '5em',
    left: '6.7em',
    fontSize: '24px',
    textAlign: 'right',
    width:'40%',
    fontFamily: 'proxima-nova',
  }, 
  titleImageChicaColor: {
    color: '#314c5d',
    fontWeight: 'bold', 
    marginTop: '7%',
    fontSize: '170%',
  }, 
  titleImageChicaAura: {
    color: 'white',
    fontWeight: 'bold', 
    marginTop: '2%',
    fontSize: '170%',
    position: 'absolute'
  }, 
  ubicacionPuntos:{
    margin: '1px 0 0 -0.4%',
    position: 'absolute',
  },
  ubicacionPuntosMobile:{
    margin: '0.6% 0 0 -10px',
    position: 'absolute',
  },
  sizePuntos:{
    width: '50%',
    marginLeft: '-100%',
  },
  sizePuntosMobile:{
    width: '4px',
    marginLeft: '-100%',
  },
  boxMobile:{
    width: '1%',
    height: '1%',
    },
  titleMobile:{
    color: '#384c5b',
    fontWeight: 'bold',  
    marginLeft: '2%', 
    fontSize: '100%'   
    },
  boxPersonajeMobile:{
    maxWidth: '30em',
    height: '15em',
    margin: '1em auto', 
    padding: '0%',
    overflow: 'hidden',
    position: 'relative',
    minWidth:'290px'
    },
    boxPersonajeMobile2:{
      width:'95%',
      height: '12em',
      margin: '2%', 
      padding: '0%',
      overflow: 'hidden',
      position: 'relative'
      },
  imagenBoxMobile: {
    width: '11em', 
    position: 'relative',
    float: 'right',
    top: '0.5em'
     },
  imagenBoxMobile2: {
    width: '11em', 
    position: 'relative',
    float: 'left',
    top: '0.5em'

    },
  titleImageMobile:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '0%'
  }, 
  titleImageMobileOscar:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    top: '10%',
    left: '10%',
    lineHeight:'1',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMobileCarge:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    fontFamily: 'proxima-nova',
    fontWeight: '100'   ,
    fontStyle:'italic',
    top: '10%',
    width: '90%'
  }, 
  titleImageMobileCargeColor:{
    color: '#314c5d',
    fontWeight: 'bold',
    position: 'relative',
    fontFamily: 'proxima-nova',
    fontWeight: '100'   ,
    fontStyle:'italic',
    top: '10%',
    width: '90%'

  }, 
  titleImageMobileJohanna:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    top: '1em',
    marginRight: '10%',
    textAlign: 'right',
    color: '#314c5d',
    lineHeight: '1',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMobileJohanna2:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    top: '1em',
    marginRight: '9%',
    textAlign: 'right',
    color: '#314c5d',
    lineHeight:'1',
    fontFamily: 'proxima-nova', 
  }, 
  titleImageMobileAura:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    top: '1em',
    left: '10%',
    color: '#334D5C',
    lineHeight: '1',
    fontFamily: 'proxima-nova',
  },
  titleImageMobileAngela:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    color: '#334d5c',
    marginRight: '10%',
    top: '1em',
    textAlign: 'right',
    fontFamily: 'proxima-nova',
  },  
  titleImageMobileAdriana:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-30% 0 0 3%'
  }, 
  titleImageMobileCatalina:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    textAlign: 'right',
    marginRight: '10%',
    top: '1em',
    lineHeight: '1',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMobileQuieres:{
    color: 'white',
    fontWeight: 'bold',
    position: 'relative',
    textAlign: 'left',
    marginLeft: '10%',
    top: '1em',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMobileCristina:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '10% 0 0 3%',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMobileWilmer:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-30% 0 0 37%'
  }, 
  titleImageMobileDavid:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-35% 0 0 3%'
  }, 
  titleImageMobileDavidc:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-30% 0 0 45%'
  }, 
  titleImageMobilePaola:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-30% 0 0 3%'
  }, 
  titleImageMobileJulian:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-35% 0 0 3%'
  }, 
  titleImageMobileTatiana:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '10% 0 0 40%',
    fontFamily: 'proxima-nova',
  }, 
  titleImageMobileEstefania:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '-30% 0 0 37%',
    fontSize: '130%'
  }, 
  titleImageMobileErika:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '10% 0 0 3%'
  }, 
  titleImageMobileJessica:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '10% 0 0 40%'
  }, 
  titleImageMobileMas:{
    color: 'white',
    fontWeight: 'bold',
    position: 'absolute',
    margin: '0 0 0 0'
  },
  columnaVerde:{
    left: '0%',
    top: '410px',
    ['@media (max-width:1209px)']: {
      top: '390px',
    },
    height: '452px',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#00bc9d',
  } ,
  columnaAmarilla:{
    left: '98%',
    top: '875px',
    ['@media (max-width:1209px)']: {
      top: '855px',
    },
    height: '452px',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#e0bd49',
  } ,
  columnaAzul:{
    left: '0%',
    top: '1350px',
    height: '450px',
    ['@media (max-width:1209px)']: {
      top: '1325px',
    },
    width: '2%',
    position: 'absolute',
    backgroundColor:'#99ccd9',
  } ,
  columnaRoja:{
    left: '98%',
    top: '161em',
    height: '36em',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#df5a49',
  },
  contenedor: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 300px)',
    gap: '1em',
    justifyContent:'center',
    gridAutoRows:'220px',
    marginBottom: '1em',
  },
  fotoGrande: {
    gridRow: 'span 2',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center'

  },
  fotoLarga: {
    gridColumn: 'span 2',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#DF5A49'

  },
  boxLargo:{
    backgroundColor: '#9e9c9c',
    width: '928px',    
    position: 'relative',
    marginTop: '1em',
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  boxLargoMobile:{
    backgroundColor: '#9e9c9c',
    maxWidth: '30em',
    position: 'relative',
    marginTop: '1em',
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  titleVerMas: {
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'proxima-nova',
    fontWeight: '100'
  },
  flechaVerMas: {
    color: 'white',
    cursor: 'pointer',
    fontSize: '46px',
    position: 'absolute',
    top: '4px'
  },
  imagenBoxOscar: {
    width: '20em',
    position: 'absolute',
    top: '6em'
    
  },
  imagenBoxAura: {
    width: '55em',
    position: 'absolute',
    top: '4em'
    
  },
  imagenBoxWilmer: {
    width: '20em',
    position: 'absolute',
    top: '7em',
    marginLeft: '7em'
    
  },
  imagenBoxDavid: {
    width: '22em',
    position: 'absolute',
    top: '5em',
    marginLeft: '8.5em'
    
  },
  imagenBoxJessica: {
    width: '25em',
    position: 'absolute',
    top: '0.5em',
    marginRight: '13em'    
  },
  imagenBoxP8: {
    width: '130px', 
    position: 'absolute',
    left: '3em',
    top: '1em'
  },
  descriptionBox:{
    color: 'white',
    position: 'absolute',
    left: '1.5em',
    fontSize: '16px',
    fontFamily: 'proxima-nova',
    fontStyle: 'italic',
    fontWeight: '100'



  },
  descriptionBoxColor:{
    color: '#314c5d',
    position: 'absolute',
    top: '13%',
    fontSize: '14px',
    fontFamily: 'proxima-nova',
    fontStyle: 'italic',
    fontWeight: '100',
    width: '92%'

  },

}));

export default function EquipoPage() {

  console.log = console.warn = console.error = () => {};
  
  const [state2] = React.useState(true);
  const { x } = useSpring({ from: { x: 0 }, x: state2 ? 1 : 0, config: { duration: 700 } });
  const [spacing] = React.useState(2);
  const [state, setState] = React.useState({
    mobileView: false,
  
  });
  
  const [show, toggleShow] = React.useState(false);
  const { mobileView } = state;
  const classes = useStyles();
  function componentDidMount() {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  React.useEffect(()=>{
    componentDidMount();
  }, [])

  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayMobile = () => {

  return (
    <div style={{backgroundColor: 'white'}}>
      <CssBaseline />
        <Container >                  
                    <animated.div
                      style={{
                        transition: 'all 1.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <br></br>
                      <Typography variant="h6" component="h6" className={classes.equipoMobile}>
                        <div className={classes.ubicacionPuntosMobile}>
                          <img className={classes.sizePuntosMobile} src={puntos} alt="" />
                        </div> 
                          <FormattedMessage id = "EquipoPage1" defaultMessage="Equipo" />
                      </Typography>
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 2s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Typography variant="h6" component="h6" className={classes.taglineMobile}> 
                        <FormattedMessage id = "EquipoPage2" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" /> 
                      </Typography>
                    </animated.div> 
                    <div style={{ alignItems: 'center' }}>                  
                    <animated.div
                      style={{
                        transition: 'all 3s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxPersonajeMobile} bgcolor="#DF5A49" p={1} >
                        <Typography variant="h6" className={classes.titleImageMobileQuieres}><FormattedMessage id = "EquipoPage26" /><br></br><FormattedMessage id = "EquipoPage27" /></Typography>
                        <h2 className={classes.titleImageColorWhiteQuieres3Mobile}>info@innovahub.org</h2>                        
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#00bc9d" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeOscar} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileOscar}>Oscar Rodríguez</Typography>                        
                        <Typography style={{left: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage3" /> 
                        </Typography>                        
                      </Box>
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 3.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxPersonajeMobile} bgcolor="#9ECBD8" p={1}>            
                        <img className={classes.imagenBoxMobile2} src={PersonajeJohannaP} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileJohanna}>Johanna Pérez</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}}   className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage22" /> 
                        </Typography>
                      </Box> 
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 4s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1}>            
                        <img className={classes.imagenBoxMobile} src={PersonajeHeidy} alt=""/>
                        <Typography variant="h6"  className={classes.titleImageMobileAura}>Heidy Ramírez</Typography>
                        <Typography style={{marginLeft: '10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage10" /> 
                        </Typography>
                      </Box>   
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 4.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxPersonajeMobile} bgcolor="#9ECBD8" p={1}>
                        <img className={classes.imagenBoxMobile2} src={PersonajeAngela} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAngela}>Paola Archila</Typography>     
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage4" /> 
                        </Typography>                   
                      </Box>                            
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >            
                        <img className={classes.imagenBoxMobile} src={PersonajeJessica} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAura}>Jessica Pérez</Typography>
                        <Typography style={{marginLeft:'10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage5" /> 
                        </Typography>   
                      </Box> 
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 5.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile2} src={PersonajeEstefania} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Estefanía Rodríguez</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage6" /> 
                        </Typography>
                      </Box>                            
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 6s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >           
                      <Box className={classes.boxPersonajeMobile} bgcolor="#DF7A3F" p={1} >
                      <img className={classes.imagenBoxMobile} src={PersonajeErika} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCristina}>Erika Ramírez</Typography>
                        <Typography style={{left: '3%', marginTop: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage20" /> 
                        </Typography>                
                      </Box>
                    </animated.div> 
                    {show && <div>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile2} src={PersonajeDavidc} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileJohanna}>David Castro</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage7" /> 
                        </Typography>                           
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#00bc9d" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeGiovanni} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileOscar}>Giovanni Guatibonza</Typography>
                        <Typography style={{left: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage12" /> 
                        </Typography>                        
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#00bc9d" p={1} >
                        <img className={classes.imagenBoxMobile2} src={PersonajeWilmer} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Wilmer Castañeda</Typography>   
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage8" /> 
                        </Typography>                     
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile} src={PersonajeAura} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileOscar}>Aura Romualdo</Typography>
                        <Typography style={{left: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage9" /> 
                        </Typography> 
                      </Box> 
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile2} src={PersonajePaola} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileJohanna}>Paola González</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage10" /> 
                        </Typography>                          
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#DF5A49" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeDavid} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileOscar}>David Yomayusa</Typography>
                        <Typography style={{left: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage11" /> 
                        </Typography>                         
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile2} src={PersonajeAngie} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Angie Céspedes</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage13" /> 
                        </Typography>      
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeElisa} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAura}>Elisa Amézquita</Typography>  
                        <Typography style={{marginLeft:'10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage14" /> 
                        </Typography>                       
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile2} src={PersonajeFabio} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Fabio Sánchez</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage16" /> 
                        </Typography>      
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeMaibe} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAura}>Maibe Paredes</Typography> 
                        <Typography style={{marginLeft:'10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage14" /> 
                        </Typography>                       
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#DF7A3F" p={1} >
                        <img style={{marginLeft:'-4%'}} className={classes.imagenBoxMobile2} src={PersonajeAngelica} alt=""/>
                        <Typography variant="h6"  className={classes.titleImageMobileCatalina}>Angélica Cárdenas</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage15" /> 
                        </Typography>                          
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile} src={PersonajeAdriana} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileOscar}>Catalina Gelvez</Typography>
                        <Typography style={{left: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage25" /> 
                        </Typography> 
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#DF7A3F" p={1} >
                        <img className={classes.imagenBoxMobile2} src={PersonajeJulian} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Julián Yomayusa</Typography>  
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage17" /> 
                        </Typography>                      
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile} style={{marginRight:'-5%'}} src={PersonajeCristina} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileOscar}>Cristina Cortés</Typography>
                        <Typography style={{left: '10%'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage18" /> 
                        </Typography> 
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile2} src={PersonajeAnaGuerrero} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Ana Guerrero</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage19" /> 
                        </Typography>
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeLaura} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAura}>Laura Riaño</Typography>
                        <Typography style={{marginLeft:'10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage23" /> 
                        </Typography>                          
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile2} src={PersonajeOscarB} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileJohanna2}>Alex Villarraga</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage24" /> 
                        </Typography>                          
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeJohanna} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAura}>Johanna Rodríguez</Typography>
                        <Typography style={{marginLeft:'10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage14" /> 
                        </Typography>                          
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#334D5C" p={1}>            
                        <img className={classes.imagenBoxMobile2} src={PersonajeMilena} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>Milena Amaya</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage14" /> 
                        </Typography>
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#EFC94C" p={1} >
                        <img className={classes.imagenBoxMobile} src={PersonajeAndrea} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileAura}>Andrea Ospina</Typography>
                        <Typography style={{marginLeft:'10%'}} className={classes.titleImageMobileCargeColor}>
                        <FormattedMessage id = "EquipoPage10" /> 
                        </Typography>                          
                      </Box>
                      <Box className={classes.boxPersonajeMobile} bgcolor="#DF7A3F" p={1} >
                        <img className={classes.imagenBoxMobile2} src={PersonajeSebas} alt=""/>
                        <Typography variant="h6" className={classes.titleImageMobileCatalina}>María Fernanda Chacón</Typography>
                        <Typography style={{marginRight: '-10%', textAlign: 'right'}} className={classes.titleImageMobileCarge}>
                        <FormattedMessage id = "EquipoPage21" /> 
                        </Typography>                        
                      </Box>
                      
                      </div>} 
                    <Box className={classes.boxLargoMobile} p={1} >
                      <Typography variant="h5" style={{marginRight: '10%'}} className={classes.titleVerMas} onClick={() => toggleShow(!show)}>{show ? <div><FormattedMessage id = "InicioPage50"/><ArrowDropUpIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div> :<div><FormattedMessage id = "InicioPage43"/><ArrowDropDownIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div>} </Typography>
                    </Box>                   
                    </div>                     
        </Container>	
      </div> 
    );
  }


const displayDesktop = () => {

return (
	<div style={{backgroundColor: 'white'}}>  
    <CssBaseline />
      <Container>             
       
                  <animated.div
                    style={{
                      transition: 'all 0s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                    <Box p={1} className={classes.columnaVerde} >
                    </Box>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 0s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                    <Box p={1} className={classes.columnaAmarilla} >
                    </Box>
                  </animated.div> 
                  <animated.div
                    style={{
                      transition: 'all 0s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                  <Box p={1} className={classes.columnaAzul} >
                    </Box>   
                  </animated.div> 
    
                  <animated.div
                    style={{
                      transition: 'all 0.2s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                    <br></br><br></br>
                    <Typography variant="h4" component="h2" className={classes.equipo}>
                      <div className={classes.ubicacionPuntos}>
                        <img className={classes.sizePuntos} src={puntos} alt="" />
                      </div> 
                        <FormattedMessage id = "EquipoPage1D" defaultMessage="EQUIPO" />
                    </Typography><br></br>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 0.6s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                    <Typography variant="h5" component="h2" className={classes.tagline}> 
                      <FormattedMessage id = "EquipoPage2" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" />
                    </Typography>
                  </animated.div> 
                  <animated.div
                    style={{
                      transition: 'all 1.8s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >                   
                  <Box className={classes.contenedor}>
                  <Box className={classes.fotoLarga} p={1}>                   
                        <img className={classes.imagenBoxP8} src={ logoVertical} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageColorWhiteQuieres}><FormattedMessage id = "EquipoPage26" /></Typography>
                        <Typography variant="h1"  className={classes.titleImageColorWhiteQuieres2}><FormattedMessage id = "EquipoPage27" /></Typography>
                        <h2 className={classes.titleImageColorWhiteQuieres3}>info@innovahub.org</h2>
                    </Box>                                  
                      <Box className={classes.fotoGrande} bgcolor="#00bc9d" p={1} >          
                        <Typography variant="h1"  className={classes.titleImage}>Oscar Rodríguez</Typography>
                        <Typography style={{top: '3em'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage3"/>
                        </Typography> 
                        <img className={classes.imagenBoxOscar} src={PersonajeOscar} alt=""/>
                      </Box>
                                           
                      <Box className={classes.box} bgcolor="#EFC94C" p={1}>            
                        <img className={classes.imagenBoxP} src={PersonajeHeidy} alt=""/>
                        <Typography variant="h1" style={{textAlign:'right', left: '55%', width:'40%', top: '40%'}} className={classes.titleImageColor}>Heidy Ramírez</Typography>
                        <Typography style={{top: '65%', textAlign:'right',}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage10"/>
                        </Typography> 
                      </Box>
                      <Box className={classes.box} bgcolor="#9ECBD8" p={1}>
                        <Typography variant="h1"  className={classes.titleImageColor2}>Paola Archila</Typography>
                        <img className={classes.imagenBoxP2} src={PersonajeAngela} alt=""/>
                        <Typography style={{width: '40%', top: '50%', left: '1em'}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage4"/>
                        </Typography> 
                      </Box>                                      
                    <Box className={classes.fotoGrande} bgcolor="#EFC94C" p={1}>            
                      <img className={classes.imagenBoxJessica} src={PersonajeJessica} alt=""/>
                      <Typography variant="h1" className={classes.titleImageJessica}>Jessica Pérez</Typography>
                      <Typography style={{top: '62%', left: '53%', width: '40%', textAlign: 'right'}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage5"/>
                      </Typography> 
                    </Box>
                    
                    <Box className={classes.box} bgcolor="#334D5C" p={1} >            
                        <img className={classes.imagenBoxP3} src={PersonajeEstefania} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageEstefania}>Estefanía Rodríguez</Typography>
                        <Typography style={{width: '40%', top: '40%', left: '1.5em'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage6"/>
                      </Typography> 
                      </Box>          
                           
                      <Box className={classes.fotoGrande} bgcolor="#00bc9d" p={1} >
                        <Typography variant="h1"  className={classes.titleImage}>Wilmer Castañeda</Typography>
                        <img className={classes.imagenBoxWilmer} src={PersonajeWilmer} alt=""/>
                        <Typography style={{ top: '3em', left: '1.5em'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage8"/>
                        </Typography>
                      </Box>
                      <Box className={classes.box} bgcolor="#EFC94C" p={1}>            
                      <img className={classes.imagenBoxP} src={PersonajeDavidc} alt=""/>
                      <Typography variant="h1"  className={classes.titleImageColorDavid}>David Castro</Typography>
                      <Typography style={{ top: '40%', textAlign:'right', width: '90%'}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage7"/>
                      </Typography>
                    </Box>          
                   
                    <Box className={classes.fotoGrande} bgcolor="#334D5C" p={1}>
                        <img className={classes.imagenBoxAura} src={PersonajeAuraM} alt=""/>
                        <Typography variant="h1"  className={classes.titleImage}>Aura Romualdo</Typography>
                        <Typography style={{ top: '3em', left: '1.5em', width: '60%'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage9"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <img className={classes.imagenBoxP} src={PersonajePaola} alt=""/>
                        <Typography variant="h1"  className={classes.titleImagePaola}>Paola González</Typography>
                        <Typography style={{ top: '80%', textAlign:'right', }} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage10"/>
                        </Typography>
                    </Box>
                    
                    <Box className={classes.fotoGrande} bgcolor="#df5a49" p={1}>
                        <Typography variant="h1"  className={classes.titleImage}>David Yomayusa</Typography>
                        <img className={classes.imagenBoxDavid} src={PersonajeDavid} alt=""/>
                        <Typography style={{ top: '3em', left: '1.5em', width:'50%'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage11"/>
                        </Typography>
                    </Box> 
                    <Box className={classes.box} bgcolor="#00bc9d" p={1}>            
                      <img className={classes.imagenBoxP2} src={PersonajeGiovanni} alt=""/>
                      <Typography variant="h1" className={classes.titleImageColorGiovanni}>Giovanni Guatibonza</Typography>
                      <Typography style={{ top: '70%', left: '1.5em', width: '40%'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage12"/>
                      </Typography>
                    </Box>
                                                    
                </Box>
                </animated.div> 
                {show && <div className={classes.contenedor}>
                  <Box className={classes.box} bgcolor="#334D5C" p={1} >
                        <Typography variant="h1"  className={classes.titleImageColorWhiteA}>Angie Céspedes</Typography>
                        <img className={classes.imagenBoxP} src={PersonajeAngie} alt=""/>
                        <Typography style={{ top: '40%', left: '52%', width: '40%', textAlign: 'right'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage13"/>
                        </Typography>
                      </Box>           
                      <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <Typography variant="h1"  className={classes.titleImagenJohanna}>Elisa Amézquita</Typography>
                          <img className={classes.imagenBoxP2} src={PersonajeElisa} alt="" />
                          <Typography style={{ top: '6em', left: '1.5em', width: '40%',}} className={classes.descriptionBoxColor}>
                          <FormattedMessage id = "EquipoPage14"/>
                          </Typography>
                      </Box>
                      <Box className={classes.fotoGrande} bgcolor="#DF7A3F" p={1}>
                        <img className={classes.imagenBoxDavid} src={PersonajeAngelica} alt=""/>
                        <Typography variant="h1"   className={classes.titleImagenAngelica}>Angélica Cárdenas</Typography>
                        <Typography style={{ top: '5em', left: '1.5em', width: '40%',}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage15"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <img className={classes.imagenBoxP2} src={PersonajeMaibe} alt=""/>
                        <Typography variant="h1"  className={classes.titleImagenMaibe}>Maibe Paredes</Typography>
                        <Typography style={{ top: '6.5em', left: '2.5em', width: '40%',}} className={classes.descriptionBoxColor}>
                          <FormattedMessage id = "EquipoPage14"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#334D5C" p={1}>
                        <img className={classes.imagenBoxP4} src={PersonajeFabio} alt=""/>
                        <Typography variant="h1" style={{textAlign: 'right', left: '50%', width: '40%'}} className={classes.titleImageColorWhite}>Fabio Sánchez</Typography>
                        <Typography style={{ top: '5em', left: '50%', width: '40%', textAlign: 'right'}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage16"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#334D5C" p={1}>
                        <img className={classes.imagenBoxP} src={PersonajeAdriana} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageColorAdriana}>Catalina Gelvez</Typography>
                        <Typography style={{ top: '5em', left: '54%', width: '40%', textAlign: 'right'}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage25"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#DF7A3F" p={1}>
                        <img className={classes.imagenBoxP2} src={PersonajeJulian} alt=""/>
                        <Typography variant="h1"  className={classes.titleImagenJulian}>Julián Yomayusa</Typography>
                        <Typography style={{ top: '5em', left: '1.5em', width: '40%',}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage17"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#334D5C" p={1}>
                        <img className={classes.imagenBoxP5} src={PersonajeCristina} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageEstefania}>Cristina Cortés</Typography>
                        <Typography style={{ top: '5.5em', left: '1.5em', width: '40%',}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage18"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#334D5C" p={1}>
                        <img className={classes.imagenBoxP2} src={PersonajeAnaGuerrero} alt=""/>
                        <Typography variant="h1"  className={classes.titleImagenJulian}>Ana Guerrero</Typography>
                        <Typography style={{ top: '5em', left: '1.5em', width: '40%',}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage19"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <img className={classes.imagenBoxP} src={PersonajeLaura} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageTatiana}>Laura Riaño</Typography>
                        <Typography style={{ top: '40%', left: '52%', width: '40%', textAlign: 'right'}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage23"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <img className={classes.imagenBoxP2} src={PersonajeOscarB} alt=""/>
                        <Typography variant="h1"  className={classes.titleImagenJohanna}>Alex Villarraga</Typography>
                        <Typography style={{ top: '5.5em', left: '1.7em'}} className={classes.descriptionBoxColor}>
                          <FormattedMessage id = "EquipoPage24"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <img className={classes.imagenBoxP4} src={PersonajeJohanna} alt=""/>
                        <Typography variant="h1" style={{top: '1em'}}  className={classes.titleImagePaola}>Johanna Rodríguez</Typography>
                        <Typography style={{ top: '40%', left: '52%', width: '40%', textAlign: 'right'}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage14"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#334D5C" p={1}>
                        <img style={{width:'11em', top: '1.2em'}} className={classes.imagenBoxP} src={PersonajeMilena} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageMilena}>Milena Amaya</Typography>
                        <Typography style={{ top: '80%', left: '45%'}} className={classes.descriptionBox}>
                        <FormattedMessage id = "EquipoPage14"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#EFC94C" p={1}>
                        <img className={classes.imagenBoxP2} src={PersonajeAndrea} alt=""/>
                        <Typography variant="h1"  className={classes.titleImagenJohanna}>Andrea Ospina</Typography>
                        <Typography style={{ top: '40%', left: '1.8em', width: '40%'}} className={classes.descriptionBoxColor}>
                        <FormattedMessage id = "EquipoPage10"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#DF7A3F" p={1}>
                        <img className={classes.imagenBoxP7} src={PersonajeSebas} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageColorWhite}>María F. Chacón</Typography>
                        <Typography style={{ top: '5em', left: '56%', width: '40%',}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage21"/>
                        </Typography>
                    </Box>
                    <Box className={classes.box} bgcolor="#DF7A3F" p={1}>            
                        <img className={classes.imagenBoxP} src={PersonajeErika} alt=""/>
                        <Typography variant="h1"  className={classes.titleImageColorWhite}>Erika Ramírez</Typography>
                        <Typography style={{ top: '5em', left: '56%', width: '40%',}} className={classes.descriptionBox}>
                          <FormattedMessage id = "EquipoPage20"/>
                        </Typography>
                      </Box>
                      <Box className={classes.box} bgcolor="#9ECBD8" p={1} >
                      <img className={classes.imagenBoxP2} src={PersonajeJohannaP} alt=""/>
                      <Typography variant="h1" className={classes.titleImagenJohanna}>Johanna Pérez</Typography>
                      <Typography style={{ top: '5.5em', left: '1.8em', width: '40%',}} className={classes.descriptionBoxColor}>
                          <FormattedMessage id = "EquipoPage22"/>
                        </Typography>
                    </Box> 
                    
                  </div>} 
                <Box className={classes.boxLargo} p={1} >
                      <Typography variant="h5" className={classes.titleVerMas} onClick={() => toggleShow(!show)}>{show ? <div><FormattedMessage id = "InicioPage50"/><ArrowDropUpIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div> :<div><FormattedMessage id = "InicioPage43"/><ArrowDropDownIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div>}</Typography>
                    </Box>                
      </Container>         
    </div>
  );
}

return (
  <header>
      {mobileView ? displayMobile() : displayDesktop()}
  </header>
);
}
