import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import logo2 from './images/logo-innovahub-web.png';
import logo3 from './images/logo-innovahub-web-azul.png';
import tierra from './images/render-2-min.png';
import {FormattedMessage} from 'react-intl';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Espanish from './images/Espanish.png';
import Portuguese from './images/Portuguese.png';
import USA from './images/USA.png';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor:'#121D2F',
  },
  menuButton: {
    right: "5px",
    top: '0.8em',
    position: 'absolute',
    color: '#334D5C'
  },
  menuButtonBlanco: {
    right: "5px",
    top: '1.5em',
    color: 'white',
    position: 'absolute'
  },
  menuButtonHidden: {
    display: 'none',
  },
  div: {
    display:'flex',
    flexGrow:1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    maxWidth:'100%'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    display: 'none',
  },
  toolbar: {
    paddingRight: 0, // keep right padding when drawer closed
    height: '60px',
  },
  drawerPaper: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '100%',
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    width: '0%',
  },
  toolbarIcon: {
    justifyContent: 'flex-end',
    alignItems: 'center',    
    display: 'flex',
    padding: '0 8px',
    backgroundColor:'#111D2F',
    ...theme.mixins.toolbar,
  },
  boxSize:{
    position: 'absolute',
    top: '1.5em',
    left: '-5%',  
    height: '3em',
    width: '6%',
  },
  boxSize2:{
    position: 'absolute',
    top: '0%',
    left: '-5%',  
    height: '5em',
    width: '6%',
  },
  boxSize3:{
    position: 'absolute',
    top: '0%',
    left: '-5%',  
    height: '5em',
    width: '6%',
  },
  boxSize4:{
    position: 'absolute',
    top: '0%',
    left: '-5%',  
    height: '3em',
    width: '6%',
  },

  item:{
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  navMenu: {
    display: "flex",
    justifyContent: "space-between",
    //width: '100%',
    ['@media (max-width:1550px)']: {
      width: '85vw'
    },
    width: '99vw',
    height: '80px',
  },
  logoImg:{
    width: '10em', 
    position: 'relative',
    top: '2em',

  },
  logoImg2:{
    width: '10em', 
    top: '1em',
    left: '2em',
    position: 'absolute',
  },
  logoImgWhite:{
    width: '10em', 
  },
  logoImgWhiteDesktop:{
    width: '150px',
    marginTop: '35px',
    marginLeft: '40px',
    cursor: 'pointer'

    
  },
  logoImgWhiteDesktopBlanco:{
    width: '150px',
    marginTop: '10px',
    marginLeft: '40px',
    cursor: 'pointer'
  },
  sizeTierraMobile:{
    width: '545px',
    marginTop: '12vh',
    position:'relative',
  },
  backgroundColorMobile:{
    backgroundColor:'#111D2F',
    overflow: 'hidden',
  },
  textColor:{
    color:'white', 
    padding: '1em 2em',
    top: '0%',
    left: '0%',
  },
  textColorAmarillo:{
    color:'#EFC94C', 
    fontWeight: 'bold',
    padding: '1em 2em',
    top: '0%',
    left: '0%',
  },  
  locationMenuMobile:{
    margin: '2% 0 0 0%',
    position: 'relative',
  },
  DivContainer:{
    width: '50%',
    display:'flex',
    marginLeft:'1vw',
    marginTop: '15px', 
    justifyContent: 'center', 
  },
  DivInicio:{
    display:'flex',
    marginRight: '25px',
    marginTop: '10px'
  },
  DivSoluciones:{
    display:'flex',
    marginRight: '25px',
    marginTop: '10px'


  },
  DivProyectos:{
    display:'flex',
    marginRight: '25px',
    marginTop: '10px'


  },
  DivEquipo:{
    display:'flex',
    marginTop: '10px'

  },
  fondoAzul:{
    //backgroundImage:'url(./fondo/homeInicio4.png)',
    backgroundImage:'url(./fondo/home-min.png)',
    backgroundColor:'#121D2F',
    height: '80px',
  },
  fondoBlanco:{
    height:'6.5em',
    position: 'absolute',
    width: '100%',
  },
  anchoMenu:{
    width: '100%',
  },
  MenuBlanco:{    
    height: '80px',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#FFFFFF',
    
  },
  MenuBlancoMobile2:{
    height: '5em',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  colorBlanco:{
    color: '#bfc4cb',
  },
  colorRosado: {
    color: '#df5a49',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',
  },
  linkInicio:{
    position: 'absolute',
    top: '2%',
  },
  fondo1Mobile:{
    backgroundImage:'url(./fondo/homeMobile8-min.png)',
    backgroundSize:'200%',
    ['@media (min-width:1000px)']: {
      backgroundImage:'url(./fondo/home-min.png)',
    },
    
  },
  ocultar:{
    position:'inherit',
  },
  ocultar2:{
    width: '100%',
    position:'fixed',
  },
  logoSize:{
    width: '100%',
    marginTop: '-1em'
  },
  menuSize:{
    left: '0%',
    backgroundColor:'#111D2F',
    width: '100%',
    position:'fixed',
  },
  Ubicacion:{
    margin: '0 0 0 0.5em',
    fontFamily: 'proxima-nova',
  },
  imagePosition:{
    width: '45vw',
    marginLeft:'0vw',
    marginTop: '1.5vh',
  },
  typographySize:{
    fontSize:'14px', 
    fontFamily: 'proxima-nova',
  },
  typographySize2:{
    fontSize:'14px',
    margin: '20px 0 0 0px', 
    color: '#334D5C',
    fontFamily: 'proxima-nova',

  },
  typographySize3:{
    fontSize:'14px', 
    fontFamily: 'proxima-nova',
  },
  typographySize4:{
    fontSize:'14px',
    margin: '20px 0 0 0px', 
    color: '#334D5C',
    fontFamily: 'proxima-nova',

  },
  typographySize4Rosado:{
    fontSize:'14px',
    color: '#df5a49',
    margin: '20px 0 0 0px', 
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',

  },
  typographySize5:{
    fontSize:'14px', 
    fontFamily: 'proxima-nova',

  },
  typographySize6:{
    fontSize:'14px',
    margin: '20px 0 0 0px', 
    color: '#334D5C',
    fontFamily: 'proxima-nova',

  },
  typographySize6Rosado:{
    fontSize:'14px',
    margin: '20px 0 0 0px', 
    color: '#df5a49',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',

  },
  typographySize7:{
    fontSize:'14px', 
    fontFamily: 'proxima-nova',

  },
  typographySize8Rosado:{
    fontSize:'14px', 
    color: '#3A5261',
    margin: '20px 0 0 0px', 
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',

  },
  typographySize8:{
    fontSize:'14px',
    margin: '20px 0 0 0px', 
    color: '#334D5C',
    fontFamily: 'proxima-nova',

  },
  pruebaCerrar: {
    backgroundColor: 'white',
    fontSize: '50px', 
    top: '-0.3em',
    left: '0.41em',
    position: 'relative',
    borderBottomLeftRadius: '7px',
    padding: '0.2em',
    color: '#334D5C'

  },
  SelectLanguage2:{
    border: '1px solid #1f3251',
    backgroundColor:'#111D2F',
    "& ul": {
    padding: 0,
    backgroundColor:'#111D2F'
    },

  },
	
	SelectLanguage3:{
    position: 'absolute',
    top: '25px',
    right: '50px',
    ['@media (max-width:1209px)']: {     
      display: 'none'
    },
  },

  SelectLanguageMobile:{
    position:'relative',
    left: '37px',
    marginTop: '18px'

  },

}));

export default function AppMenu() {

  const [age, setAge] = React.useState(localStorage.getItem("lang"));
  const handleChange = (event) => {
    setAge(event.target.value);
    localStorage.setItem("lang",event.target.value)
    window.location.reload()
  };

  //inicio modal
  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };



  //fin modal
  const [open, setOpen] = React.useState(false);

  const [openLogoMobileBlanco, setOpenLogoMobileBlanco] = React.useState(true);
  React.useEffect(()=>{
    CambioLogoMobileBlanco();
  }, [])

  function CambioLogoMobileBlanco (){
    if(window.location.href.includes('inicio')){
      setOpenLogoMobileBlanco(true);
    }
    else{
      setOpenLogoMobileBlanco(false);
    }
  }
  const handleOpenLogoMobileBlanco = () => {
    setOpenLogoMobileBlanco(true);
  };

  const handleCloseLogoMobileBlanco = () => {
    setOpenLogoMobileBlanco(false);
  };

  const [openMenuBlancoMobile, setOpenMenuBlancoMobile] = React.useState(true);
  const [inicioMobile, setMenuInicioMobile] = React.useState(false);
  const [solucionesMobile, setMenuSolucionesMobile] = React.useState(false);
  const [proyectosMobile, setMenuProyectosMobile] = React.useState(false);
  const [equipoMobile, setMenuEquipoMobile] = React.useState(false);
  const [inicioD, setMenuInicioD] = React.useState(false);
  const [solucionesD, setMenuSolucionesD] = React.useState(false);
  const [proyectosD, setMenuProyectosD] = React.useState(false);
  const [equipoD, setMenuEquipoD] = React.useState(false);
  React.useEffect(()=>{
    CambioFondoMenuMobile();
  }, [])

  function CambioFondoMenuMobile (){
    if(window.location.href.includes('inicio')){
      setOpenMenuBlancoMobile(false);
      setMenuInicioMobile(true)
    }
    if(window.location.href.includes('soluciones')){
      setOpenMenuBlancoMobile(true);
      setMenuSolucionesMobile(true)
    }
    if(window.location.href.includes('proyectos')){
      setOpenMenuBlancoMobile(true);
      setMenuProyectosMobile(true);
    }
    if(window.location.href.includes('equipo')){
      setOpenMenuBlancoMobile(true);
      setMenuEquipoMobile(true);
    }


  }
  const handleOpenMenuBlancoMobile = () => {
    setOpenMenuBlancoMobile(true);
  };

  const handleCloseMenuBlancoMobile = () => {
    setOpenMenuBlancoMobile(false);
  };

  const [openMenuBlanco, setOpenMenuBlanco] = React.useState(false);
  React.useEffect(()=>{
    CambioFondoMenu();
  }, [])

  function CambioFondoMenu (){
    if(window.location.href.includes('inicio')){
      setOpenMenuBlanco(false);
    }
    if(window.location.href.includes('proyectos')){
      setOpenMenuBlanco(true);
      setMenuProyectosD(true);
    }
    if(window.location.href.includes('soluciones')){
      setOpenMenuBlanco(true);
      setMenuSolucionesD(true)
    }
    if(window.location.href.includes('equipo')){
      setOpenMenuBlanco(true);
      setMenuEquipoD(true)

    }
  }
  const handleOpenMenuBlanco = () => {
    setOpenMenuBlanco(true);
  };
 
  const handleCloseMenuBlanco = () => {
    setOpenMenuBlanco(false);
  };

  function CambioColorLetra (){
    if(window.location.href.includes('inicio')){
      setOpenTextoNegro(false);
      }
    else{
      setOpenTextoNegro(true);
    }
  }

  const handleOpenTextoNegro = () => {
    setOpenTextoNegro(true);
  }
  const handleCloseTextoNegro = () => {
    setOpenTextoNegro(false);
  }

  const [textoNegro, setOpenTextoNegro] = React.useState(true);
  React.useEffect(()=>{
    CambioColorLetra();
  }, [])

  const [state, setState] = React.useState({
    mobileView: false,
  });
    
  const { mobileView } = state;
  const classes = useStyles();

  React.useEffect(() => {

    const setResponsiveness = () => {
      return window.innerWidth < 1209
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

  return () => {
    window.removeEventListener("resize", () => setResponsiveness());
  }
  }, []);

  const displayMobile = () => {
   
    const handleDrawerOpen = () => {
      setOpen(true);
    };
    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
      <div >
        <CssBaseline />
          {openMenuBlancoMobile &&<div className={classes.MenuBlancoMobile2} >
          <a href='#/inicio'>
            <img src={logo3} alt="logo" className={classes.logoImg2} onClick={() => {handleCloseMenuBlancoMobile();handleOpenLogoMobileBlanco()}}  />
            </a>
          </div>} 
          <div className={classes.fondo1Mobile} >
            <Container className={clsx(classes.appBar, open && classes.appBarShift)} >
              <div className={classes.toolbar} >
                {openLogoMobileBlanco &&<div >
                  <img src={logo2} alt="logo" className={classes.logoImg} />
                </div>}
                <Typography component="h1" variant="h5" color="inherit"  className={classes.title}>
                </Typography>
                {openLogoMobileBlanco ? <Button  
                  aria-controls="simple-menu" 
                  aria-haspopup="true" 
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {handleDrawerOpen();handleOpen2()}}
                  className={clsx(classes.menuButtonBlanco, open && classes.menuButtonHidden)}
                >
                  <MenuIcon  />
                </Button>: <Button  
                  aria-controls="simple-menu" 
                  aria-haspopup="true" 
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {handleDrawerOpen();handleOpen2()}}
                  className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                  <MenuIcon />
                </Button>}
                
              </div>
            </Container>
          </div>

          <Drawer 
            border={0}
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          >
            {open2 &&<div className={classes.ocultar} >            
              <div className={classes.ocultar2} >              
                <div className={classes.toolbarIcon} >
                  <div className={classes.logoSize} align="center" > 
                    <img src={logo2} alt="logo" className={classes.logoImgWhite} /> 
                  </div> 
                  <IconButton onClick={() => {handleDrawerClose();handleClose2()}}>
                    <CloseIcon className={classes.pruebaCerrar} />
                  </IconButton>
                </div>
                
              </div>              
            </div>}   
            <div className={classes.backgroundColorMobile}><br></br><br></br>
              <div className={classes.locationMenuMobile}> 
                {open2 &&<div  className={classes.ocultar}>  
                  <div className={classes.menuSize} >  
                    <ListItem button className={classes.item} color="inherit" component={Link} onClick={() => {handleCloseMenuBlancoMobile();handleOpenLogoMobileBlanco()}} href="#/inicio">
                      <Box className={classes.boxSize} bgcolor="#EFC94C" p={2}></Box>
                      {inicioMobile ?  <div><ListItemText className={classes.textColorAmarillo} >
                        <div className={classes.Ubicacion} >
                          <FormattedMessage id = "Header1" defaultMessage="INICIO"></FormattedMessage>
                        </div>
                      </ListItemText></div> : <ListItemText className={classes.textColor} >
                        <div className={classes.Ubicacion} >
                          <FormattedMessage id = "Header1" defaultMessage="INICIO"></FormattedMessage>
                        </div>
                      </ListItemText> }
                      
                    </ListItem>
                    <ListItem button className={classes.item} color="inherit" component={Link} onClick={() => {handleOpenMenuBlancoMobile();handleCloseLogoMobileBlanco()}} href="#/soluciones">
                      <Box className={classes.boxSize2} bgcolor="#EFC94C" p={2}></Box>
                      {solucionesMobile ? <ListItemText className={classes.textColorAmarillo} >
                        <div className={classes.Ubicacion} >
                          <FormattedMessage id = "Header2" defaultMessage="SOLUCIONES EMBLEMÁTICAS"></FormattedMessage>
                        </div>
                      </ListItemText>: <ListItemText className={classes.textColor} >
                        <div className={classes.Ubicacion} >
                          <FormattedMessage id = "Header2" defaultMessage="SOLUCIONES EMBLEMÁTICAS"></FormattedMessage>
                        </div>
                      </ListItemText> }
                      
                    </ListItem>
                    <ListItem button className={classes.item} color="inherit" component={Link} onClick={() => {handleOpenMenuBlancoMobile();handleCloseLogoMobileBlanco()}} href="#/proyectos">
                      <Box className={classes.boxSize3} bgcolor="#EFC94C" p={2}></Box>
                      { proyectosMobile ? <ListItemText className={classes.textColorAmarillo} >
                        <div className={classes.Ubicacion}>
                          <FormattedMessage id = "Header3" defaultMessage="PROYECTOS"></FormattedMessage>
                        </div>
                      </ListItemText>: <ListItemText className={classes.textColor} >
                        <div className={classes.Ubicacion}>
                          <FormattedMessage id = "Header3" defaultMessage="PROYECTOS"></FormattedMessage>
                        </div>
                      </ListItemText>}                      
                    </ListItem>
                    <ListItem button className={classes.item} color="inherit" component={Link} onClick={() => {handleOpenMenuBlancoMobile();handleCloseLogoMobileBlanco()}} href="#/equipo">
                      <Box className={classes.boxSize4} bgcolor="#EFC94C" p={2}></Box>
                      {equipoMobile ?  <ListItemText className={classes.textColorAmarillo} >
                        <div className={classes.Ubicacion}>
                          <FormattedMessage id = "Header4" defaultMessage="EQUIPO"></FormattedMessage>
                        </div>
                      </ListItemText>: <ListItemText className={classes.textColor} >
                        <div className={classes.Ubicacion}>
                          <FormattedMessage id = "Header4" defaultMessage="EQUIPO"></FormattedMessage>
                        </div>
                      </ListItemText>}
                      
                    </ListItem>
                    <div align="left" className={classes.SelectLanguageMobile}>          
                                <Select
                                  MenuProps={{ classes: { paper: classes.SelectLanguage2 } }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={age}
                                  label="Age"
                                  onChange={handleChange}
                                  style={{backgroundColor: '#1F3251', width: '100px', borderRadius:'5px'}}
                                >
                                  <MenuItem style={{margin: '0 0 0 0%',backgroundColor:'#111D2F', minHeight: '32px',fontFamily: 'proxima-nova'}}  value={"es"}> <img src={Espanish} style={{width:'18px', height: '18px', position: 'absolute', left: '20%',  borderRadius: '50%' }} alt="logo" /><Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',top: '6px' ,fontFamily: 'proxima-nova'}}>ES</Box></MenuItem>
                                  <MenuItem style={{margin: '0% 0 0 0%',backgroundColor:'#111D2F',minHeight: '32px',fontFamily: 'proxima-nova'}} value={"pt"}> <img src={Portuguese} style={{width:'18px',height: '18px',  position: 'absolute', left: '20%', borderRadius: '50%' }} alt="logo" /><Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',top: '6px',fontFamily: 'proxima-nova'}}>PT</Box></MenuItem>
                                  <MenuItem style={{margin: '0% 0 0 0%',backgroundColor:'#111D2F',minHeight: '32px',fontFamily: 'proxima-nova'}} value={"en"}> <img src={USA} style={{width:'18px',height: '18px',  position: 'absolute', left: '20%',  borderRadius: '50%' }} alt="logo" /> <Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',top: '6px',fontFamily: 'proxima-nova'}}>EN</Box> </MenuItem> 
                                </Select>     
                              </div>       
                    <div style={{backgroundColor:'#111D2F', display: 'flex', justifyContent: 'center' }}>
                    <img className={classes.sizeTierraMobile} src={tierra} alt="" />
                    </div>
                  </div>
                </div>}  
              </div>
            </div>
          </Drawer>
        </div>
      );
    }
    
  const displayDesktop = () => {

    return (   
      <div className={classes.root} >
        <div className={classes.anchoMenu} >
          {openMenuBlanco &&<div className={classes.MenuBlanco} >
            <a href= "#/inicio" >
            <img src={logo3} alt="logo" className={classes.logoImgWhiteDesktop}  onClick={() => {handleCloseMenuBlanco();handleCloseTextoNegro()}}/> 
            </a> 
          </div>
          } 
          <div className={classes.fondoAzul}  >  
            <nav className={classes.navMenu}>
              <div className={classes.imagePosition} >
                <img src={logo2} alt="logo"  className={classes.logoImgWhiteDesktopBlanco} /> 
              </div>            
                <div className={classes.DivContainer}>  
                  <div align="center" className={classes.DivInicio} >           
                    <div>
                      <Link variant="button" className={classes.colorRosado} onClick={() => {handleCloseMenuBlanco();handleCloseTextoNegro()}} href="#/inicio" >
                        <div className={classes.typographySize}>
                          <FormattedMessage id = "Header1" defaultMessage="INICIO"></FormattedMessage>
                        </div>
                      </Link>
                    </div>
                    {textoNegro &&<div className={classes.linkInicio} >
                      <Link variant="button" color="textPrimary" onClick={() => {handleCloseMenuBlanco();handleCloseTextoNegro()}} href="#/inicio" >
                        <div className={classes.typographySize2}>
                          <FormattedMessage id = "Header1" defaultMessage="INICIO"></FormattedMessage>
                        </div>
                      </Link>
                    </div>}
                  </div>
                  <div align="center" className={classes.DivSoluciones} >
                    <Link variant="button" className={classes.colorBlanco} onClick={() => {handleOpenMenuBlanco();handleOpenTextoNegro()}} href="#/soluciones" >
                      <div className={classes.typographySize3}>
                        <FormattedMessage id = "Header2" defaultMessage="SOLUCIONES EMBLEMÁTICAS"></FormattedMessage>
                      </div>
                    </Link>
                    {textoNegro &&<div className={classes.linkInicio} >
                      <Link variant="button" color="textPrimary" onClick={() => {handleOpenMenuBlanco()}} href="#/soluciones" >
                        {solucionesD ? <div className={classes.typographySize4Rosado}>
                          <FormattedMessage id = "Header2" defaultMessage="SOLUCIONES EMBLEMÁTICAS"></FormattedMessage>
                        </div> : <div className={classes.typographySize4}>
                          <FormattedMessage id = "Header2" defaultMessage="SOLUCIONES EMBLEMÁTICAS"></FormattedMessage>
                        </div>}
                        
                      </Link>
                    </div>}
                  </div>
                  <div align="center" className={classes.DivProyectos} >
                    <Link variant="button" className={classes.colorBlanco} onClick={() => {handleOpenMenuBlanco();handleOpenTextoNegro()}} href="#/proyectos" >
                      <div className={classes.typographySize5}>
                        <FormattedMessage id = "Header3" defaultMessage="PROYECTOS"></FormattedMessage>
                      </div>
                    </Link>
                    {textoNegro &&<div className={classes.linkInicio}>
                      <Link variant="button" color="textPrimary" onClick={() => {handleOpenMenuBlanco()}} href="#/proyectos" >
                        {proyectosD ? <div className={classes.typographySize6Rosado}>
                          <FormattedMessage id = "Header3" defaultMessage="PROYECTOS"></FormattedMessage>
                        </div>: <div className={classes.typographySize6}>
                          <FormattedMessage id = "Header3" defaultMessage="PROYECTOS"></FormattedMessage>
                        </div> }
                        
                      </Link>
                    </div>}
                  </div>
                  <div align="center" className={classes.DivEquipo} >
                    <Link variant="button" className={classes.colorBlanco} onClick={() => {handleOpenMenuBlanco();;handleOpenTextoNegro()}} href="#/equipo" >
                       <div className={classes.typographySize7}>
                        <FormattedMessage id = "Header4" defaultMessage="EQUIPO"></FormattedMessage>
                      </div>                      
                    </Link>
                    {textoNegro &&<div className={classes.linkInicio}>
                      <Link variant="button" color="textPrimary" onClick={() => {handleOpenMenuBlanco()}} href="#/equipo" >
                      {equipoD ? <div className={classes.typographySize8Rosado}>
                        <FormattedMessage id = "Header4" defaultMessage="EQUIPO"></FormattedMessage>
                      </div>: <div className={classes.typographySize8}>
                        <FormattedMessage id = "Header4" defaultMessage="EQUIPO"></FormattedMessage>
                      </div>}
                      </Link>
                    </div>}
                  </div>
                </div>
                <div align="center" className={classes.SelectLanguage3} >
                      <div className={classes.SelectLanguage}>          
                        <Select
                          labelId="demo-simple-select-label"
                          disableUnderline={true}
                          id="demo-simple-select"
                          value={age}
                          label="Age"
                          onChange={handleChange}
                          MenuProps={{ classes: { paper: classes.SelectLanguage2 } }}
                          style={{backgroundColor: '#1F3251', width: '100px', borderRadius:'5px'}}
                          
                        >
                          <MenuItem style={{margin: '0 0 0 0%',backgroundColor:'#111D2F', height: '35px'}} selected value={"es"}> <img src={Espanish} style={{width:'18px', height:'18px', position: 'absolute', left: '20%', borderRadius: '50%' }} alt="logo" /><Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',fontFamily: 'proxima-nova',}}>ES</Box></MenuItem>
                          <MenuItem style={{margin: '0% 0 0 0%',backgroundColor:'#111D2F',height: '35px'}} value={"pt"}> <img src={Portuguese} style={{width:'18px',height:'18px', position: 'absolute', left: '20%',borderRadius: '50%' }} alt="logo" /><Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',fontFamily: 'proxima-nova',}}>PT</Box></MenuItem>
                          <MenuItem style={{margin: '0 0 0 0%',backgroundColor:'#111D2F',height: '35px'}} value={"en"}> <img src={USA} style={{width:'18px',height:'18px', position: 'absolute', left: '20%',borderRadius: '50%' }} alt="logo" /> <Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',fontFamily: 'proxima-nova',}}>EN</Box> </MenuItem> 
                        </Select>     
                      </div>    
                    </div>  
                <br></br>
              </nav>
            </div>
          </div>
        </div>
      );
    }

  return (
    <header>
      {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
}
