import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import puntos from'./images/puntos.png';
import imgSoluciones1 from'./images/01-min.png';
import imgSoluciones2 from'./images/02-min.png';
import imgSoluciones3 from'./images/03-min.png';
import imgSoluciones4 from'./images/04-min.png';
import imgSoluciones5 from'./images/05-min.png';
import imgSoluciones6 from'./images/06-min.png';
import signoMas from './images/+-rojo.png';
import cerrarRojo from './images/icon-cerrar.png';
import Modal from '@material-ui/core/Modal';
import Link from '@material-ui/core/Link';
import { animated } from 'react-spring';
import { useSpring } from 'react-spring';
import {FormattedMessage} from 'react-intl';
import Circulo from './images/circuloRojo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  DivEspacioMobile:{
    margin: '-0.8em 0 0 1.5em',
    padding: '0.5em',
  },
  underlineText:{
    textDecoration: 'underline',
    textUnderlineOffset: '0.3em',
    color: '#384c5b',
    fontFamily: 'proxima-nova',
  },
  underlineTextDesktop:{
    textDecoration: 'underline',
    textUnderlineOffset: '0.2em',
    color: '#384c5b',
  },
  sizePuntos:{
    width: '50%',
    marginLeft: '-100%',
  },
  sizePuntosMobile:{
    width: '4px',
  },
  ubicacionPuntos:{
    margin: '2px 0 0 -0.4%',
    position: 'absolute',
  },
  ubicacionPuntosMobile:{
    margin: '5px 0 0 15px',
    position: 'absolute',
    
  },
  letra:{
    fontWeight: 'bold',
    color: '#384c5b',
    fontFamily: 'proxima-nova',
  },
  letraTagline:{
    color: '#384c5b',
    fontFamily: 'proxima-nova',
  },
  letra2:{
    color:'white',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',
  },
  letra3:{
    color:'white',
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: 'proxima-nova',
  },
  divBox:{
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 300px)',
    gap: '1em',
    justifyContent:'center',
    marginBottom: '1em',
  },
  divBox1:{
    position: 'relative'
  },
  divBox1Mobile:{
    width: '50%',
    position: 'absolute',
    margin: '0% 0 0 0%',
    borderStyle: 'solid',borderColor: 'red',
  },
  divBox2:{

  },
  divBox2Mobile:{
    width: '50%',
    position: 'absolute',
    margin: '0em 0 0 29em',
  },
  divBox3:{
    
  },
  divBox4:{
    
  },
  divBox5:{
    
  },
  divBox6:{
    
  },

  sizeImg:{
    width: '250px',
    margin: '-7em 0 0 0em',
  },
  sizeImgMobile:{
    width: '250px',
  },
  sizeBox:{
    width: '300px',
    height: '400px',
  },
  sizeBoxMobile:{
    width:'95%',
    maxWidth: '40em',
    height: '23em',
    margin: '1em auto', 
    padding: '1%',
    position: 'relative',
    minWidth: '250px'
  },
  sizeBox2Mobile:{
    width:'95%',
    maxWidth: '40em',
    height: '25em',
    margin: '1em auto', 
    padding: '1%',
    position: 'relative',
    minWidth: '250px'

  },
  ubicacionImg1:{
    position: 'absolute',
    margin: '6em 0 0 1em',
  },
  ubicacionImg1Mobile:{
    position: 'absolute',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '0'
  },
  ubicacionImg2:{
    position: 'absolute',
    margin: '4em 0 0 0em',
  },
  ubicacionImg2Mobile:{
    position: 'absolute',
    top: '0'
  },
  ubicacionImg3:{
    position: 'absolute',
    margin: '2em 0 0 0em',
  },
  ubicacionImg3Mobile:{
    position: 'absolute',
    top:'0'
  },
  ubicacionImg4:{
    position: 'absolute',
    margin: '4em 0 0 0em',
  },
  ubicacionImg4Mobile:{
    position: 'absolute',
    margin: '4em 0 0 0em',
  },
  ubicacionImg5:{
    position: 'absolute',
    margin: '4em 0 0 0em',
  },
  ubicacionImg5Mobile:{
    position: 'absolute',
    margin: '2em 0 0 0em',
  },
  ubicacionImg6:{
    position: 'absolute',
    margin: '2em 0 0 0em',
  },
  ubicacionImg6Mobile:{
    position: 'absolute',
    margin: '3em 0 0 0em',
  },
  sizeSignoMas:{
    width: '4em',
    cursor: 'pointer'
  },
  sizeSignoMasMobile:{
    width: '50px',
  },
  ubicacionSignoMas1:{
    position: 'absolute',
    left: '0',
    right: '0',
    margin:'auto',
    top: '78%'
  },
  ubicacionSignoMas1Mobile:{
    position: 'absolute',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '80%'
  },
  ubicacionSignoMas2:{
    position: 'absolute',
    margin: '14em 0 0 7em',
  },
  ubicacionSignoMas2Mobile:{
    position: 'absolute',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '255px'
  },
  ubicacionSignoMas3:{
    position: 'absolute',
    margin: '12em 0 0 7em',
  },
  ubicacionSignoMas3Mobile:{
    position: 'absolute',
    margin: '10em 0 0 6.7em',
  },
  ubicacionSignoMas4:{
    position: 'absolute',
    margin: '15em 0 0 7em',
  },
  ubicacionSignoMas4Mobile:{
    position: 'absolute',
    margin: '12em 0 0 7em',
  },
  ubicacionSignoMas5:{
    position: 'absolute',
    margin: '15em 0 0 7em',
  },
  ubicacionSignoMas5Mobile:{
    position: 'absolute',
    margin: '10em 0 0 7em',
  },
  ubicacionSignoMas6:{
    position: 'absolute',
    margin: '13em 0 0 7em',
  },
  ubicacionSignoMas6Mobile:{
    position: 'absolute',
    margin: '11em 0 0 7em',
  },
  btnIconSignoMas:{
    width: '3em',
    height:'3em',
    backgroundColor: 'inherit',
  },
  btnIconSignoMasMobile:{
    width: '3em',
    height:'3em',
    backgroundColor: 'inherit',
  },
  btnSignoMas1:{
    position: 'absolute',
    margin: '21.5em 0 0 11em',
  },
  btnSignoMas1Mobile:{
    position: 'absolute',
    margin: '13.5em 0 0 6.5em',
  },
  btnSignoMas2:{
    position: 'absolute',
    margin: '18.5em 0 0 10em',
  },
  btnSignoMas2Mobile:{
    position: 'absolute',
    margin: '10.6em 0 0 6em',
  },
  btnSignoMas3:{
    position: 'absolute',
    margin: '17.5em 0 0 10em',
  },
  btnSignoMas3Mobile:{
    position: 'absolute',
    margin: '9.5em 0 0 6em',
  },
  btnSignoMas4:{
    position: 'absolute',
    margin: '20.5em 0 0 11em',
  },
  btnSignoMas4Mobile:{
    position: 'absolute',
    margin: '11em 0 0 6.5em',
  },
  btnSignoMas5:{
    position: 'absolute',
    margin: '19.5em 0 0 10em',
  },
  btnSignoMas5Mobile:{
    position: 'absolute',
    margin: '9.5em 0 0 6em',
  },
  btnSignoMas6:{
    position: 'absolute',
    margin: '18.5em 0 0 10em',
  },
  btnSignoMas6Mobile:{
    position: 'absolute',
    margin: '10.5em 0 0 6em',
  },
  boxModal1:{
    overflowY: 'scroll',
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#ebf0f3',
    bgcolor: 'background.paper',
    boxShadow: '24',
    p: '4',
  },
  boxModal1Mobile:{
    overflowY: 'scroll',
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#ebf0f3',
    top: '0%',
    left: '0%',
    boxShadow: '24',
    p: '4',
  },
  divBtn1:{
    margin: '0em 0 0 1em',
    backgroundColor: '#2b4e5c',
    width: '2em',
    height:'1.8em',
    borderBottomLeftRadius: '10px 10px',
  },
  divBtn1Mobile:{
    margin: '0em 0 0 1em',
    backgroundColor: '#2b4e5c',
    width: '2em',
    height:'1.8em',
    borderBottomLeftRadius: '10px 10px',
  },
  iconBtn1:{
    width: '1em',
    height:'1em',
  },
  divFondoBlanco:{
    width: '100%',
  },
  divFondoBlancoMobile:{
    width: '100%',
  },
  divFondoBlanco2:{
    width: '100%',
    backgroundColor: '#daf0fd',
    borderBottomLeftRadius: '20px 20px',
    borderTopRightRadius: '20px 20px',
  },
  divFondoBlanco2Mobile:{
    width: '100%',
    backgroundColor: '#daf0fd',
    borderBottomLeftRadius: '20px 20px',
    borderTopRightRadius: '20px 20px',
  },
  divModal:{
    width: '60%',
    
  },
  divAlignLeft:{
    margin: '0em 0 0 4em',
  },
  divAlignLeftMobile:{
    margin: '0em 0 0 1em',
  },
  divAlignLeft2:{
    width: '94%',
    margin: '0em 0 0 -3em',
  },
  divAlignLeft2Mobile:{
    width: '94%',
    margin: '0em 0 0 1em',
  },
  divAlignLeft3:{
  },
  cortarMobile:{
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  ubicacionBoxMobile:{
    margin: '1em 0 0 0em',
  },
  columnaRoja:{
    left: '0%',
    top: '440px',
    ['@media (max-width:1209px)']: {
      top: '420px',
    },
    height: '28.5em',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#df5a49',
  },
  columnaRoja2:{
    left: '98%',
    top: '855px',
    ['@media (max-width:1209px)']: {
      top: '835px',
    },
    height: '28.5em',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#df5a49',
  },
  typographyPersonajes:{
    color:'white',
    fontWeight: 'bold',
  },
  sizePuntosModales:{
  },
  ubicacionPuntosModal:{
    margin: '0.3em 0 0 -2.5em',
    padding: '1em',
    position: 'absolute',
  },
  typographyCerrar:{
    margin:'0.1em 0 0 0.7em',
    position:'absolute',
    fontSize:'25px', 
    color:'white',
    fontWeight: 'bold',
  },
  xCerrar:{
    position: 'relative',
    width: '100%'
  },
  imageSize:{
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    marginRight:'15px',
    marginTop: '15px'
  },
  typographyTitle:{
    fontSize:'57px', 
    lineHeight:'59px',
    paddingTop:'14px',
    color:'white',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',
  },
  typographyMetodologia:{
    color:'white',
    fontWeight: 'bold',
    fontSize:'18px', 
  },
  typographyMetodologia2:{
    fontSize:'19px',
    fontFamily: 'proxima-nova',
  },
  typographyMetodologia3:{
    color:'white',
    fontSize:'20px', 
    fontWeight:'100',
    fontFamily: 'proxima-nova',
  },
  letraBoxM: {
    color:'white',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',


  },
  letraBoxM2: {
    color:'white',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',
    },
  colorBlueLetra: {
    color:'#334D5C',
    fontFamily: 'proxima-nova',
  },
  boxCloseMobile: {
    right: '0',
    width:'2em', 
    height: '3em',
    position:'absolute', 
    backgroundColor: '#334D5C',
     display: 'flex', 
     justifyContent: 'center',
      alignItems: 'center',
       borderBottomLeftRadius: '10px'

  },
  xCerrarMobile:{
    position:'absolute',
    fontSize:'35px', 
     bottom: '0',
      margin: 'auto',
      color:'white',
    fontWeight: 'bold',
  }


  }));

export default function SolucionesPage() {

  console.log = console.warn = console.error = () => {};

  //modal    
  const handleOpen1Mobile = () => {
    setOpen1Mobile(true);
  };
  const handleOpen2Mobile = () => {
    setOpen2Mobile(true);
  };
  const handleOpen3Mobile = () => {
    setOpen3Mobile(true);
  };
  const handleOpen4Mobile = () => {
    setOpen4Mobile(true);
  };
  const handleOpen5Mobile = () => {
    setOpen5Mobile(true);
  };
  const handleOpen6Mobile = () => {
    setOpen6Mobile(true);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleOpen5 = () => {
    setOpen5(true);
  };
  const handleOpen6 = () => {
    setOpen6(true);
  };
  const handleClose1Mobile = () => {
    setOpen1Mobile(false);
  };
  const handleClose2Mobile = () => {
    setOpen2Mobile(false);
  };
  const handleClose3Mobile = () => {
    setOpen3Mobile(false);
  };
  const handleClose4Mobile = () => {
    setOpen4Mobile(false);
  };
  const handleClose5Mobile = () => {
    setOpen5Mobile(false);
  };
  const handleClose6Mobile = () => {
    setOpen6Mobile(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const handleClose6 = () => {
    setOpen6(false);
  };
  //
  //animaciones
  const [state2] = React.useState(true);
  const { x } = useSpring({ from: { x: 0 }, x: state2 ? 1 : 0, config: { duration: 700 } });
  //
  const [spacing] = React.useState(2);  
  const [state, setState] = React.useState({
    mobileView: false,
  });
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open1Mobile, setOpen1Mobile] = React.useState(false);
  const [open2Mobile, setOpen2Mobile] = React.useState(false);
  const [open3Mobile, setOpen3Mobile] = React.useState(false);
  const [open4Mobile, setOpen4Mobile] = React.useState(false);
  const [open5Mobile, setOpen5Mobile] = React.useState(false);
  const [open6Mobile, setOpen6Mobile] = React.useState(false);
  const { mobileView } = state;
  const classes = useStyles();

  function componentDidMount() {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  React.useEffect(()=>{
    componentDidMount();
  }, [])



  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
  setResponsiveness();
  window.addEventListener("resize", () => setResponsiveness());
  
  return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);
  
  const displayMobile = () => { 
    return (

      <div className={classes.cortarMobile} >
        <CssBaseline />
          <Container>  
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>        
                <div >
                  <Grid container justifyContent="flex-start" spacing={spacing}>
                    {[2].map((value) => (
                      <Grid key={value} item><br></br>
                        <animated.div
                          style={{
                            transition: 'all 1.5s linear',
                            opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                          }}
                        >
                          <div className={classes.ubicacionPuntosMobile}>
                            <img className={classes.sizePuntosMobile} src={puntos} alt="" />
                          </div>                      
                        </animated.div>
                        <div className={classes.DivEspacioMobile}>
                          <animated.div
                            style={{
                              transition: 'all 1.5s linear',
                              opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                            }}
                          >
                            <Typography variant="body1" variant="h6" component="h6"  className={classes.letra}> 
                              <Link className={classes.underlineText}><FormattedMessage id = "SolucionesPageMobile1" defaultMessage="SOLUCIONES"></FormattedMessage></Link> <FormattedMessage id = "SolucionesPageMobile2" defaultMessage="EMBLEMÁTICAS"></FormattedMessage>  
                            </Typography><br></br>
                          </animated.div>
                          <animated.div
                            style={{
                              transition: 'all 2s linear',
                              opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                            }}
                          >
                            <Typography variant="body2" component="h2" style={{color: '#384c5b',fontFamily: 'proxima-nova',}}> 
                              <FormattedMessage id = "SolucionesPage3" defaultMessage="Vestibulum lectus purus, pretium nec elit at, fringilla scelerisque sapien." />  
                            </Typography>
                           
                          </animated.div>
                        </div><br></br>
                      </Grid>
                    ))}
                  </Grid>
                  <div align="center" >
                    <animated.div
                      style={{
                        transition: 'all 2.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box p={2} bgcolor="#2b4e5c" className={classes.sizeBoxMobile}><br></br>
                        <Typography  component="h2" className={classes.letraBoxM}> 
                          <FormattedMessage id = "SolucionesPage5" defaultMessage="EDUCACIÓN VIRTUAL" /> 
                        </Typography>            
                        <div className={classes.ubicacionImg1Mobile}>
                          <img className={classes.sizeImgMobile} src={imgSoluciones1} alt="" />
                        </div>
                        <div  className={classes.ubicacionSignoMas1Mobile} >
                          <img onClick={handleOpen1Mobile} className={classes.sizeSignoMasMobile} src={signoMas} alt="" />
                        </div>
                        
                      </Box>
                    </animated.div>
                  </div> 
                  <div align="center" className={classes.ubicacionBoxMobile}>
                    <animated.div
                      style={{
                        transition: 'all 3s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBoxMobile}><br></br>
                        <Typography  component="h2" className={classes.letraBoxM}> 
                          <FormattedMessage id = "SolucionesPage6" defaultMessage="PLATAFORMAS WEB CON SENTIDO" /> 
                        </Typography>
                        <div className={classes.ubicacionImg1Mobile}>
                          <img className={classes.sizeImgMobile} src={imgSoluciones2} alt="" />
                        </div>
                        <div className={classes.ubicacionSignoMas1Mobile} >
                          <img className={classes.sizeSignoMasMobile} onClick={handleOpen2Mobile} src={signoMas} alt="" />
                        </div>
                        
                      </Box>
                    </animated.div>
                  </div>
                  <div align="center" className={classes.ubicacionBoxMobile}>                   
                    <animated.div
                      style={{
                        transition: 'all 3.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    > 
                      <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBox2Mobile}>
                        <Typography  component="h2" className={classes.letraBoxM2}> 
                          <FormattedMessage id = "SolucionesPage7" defaultMessage="ASESORÍA ESTRATÉGICA EN GESTIÓN DE CONOCIMIENTO E INNOVACIÓN" />  
                        </Typography>
                        <div className={classes.ubicacionImg1Mobile}>
                          <img className={classes.sizeImgMobile} src={imgSoluciones3} alt="" />
                        </div>
                        <div  className={classes.ubicacionSignoMas1Mobile} >
                          <img className={classes.sizeSignoMasMobile} onClick={handleOpen3Mobile} src={signoMas} alt="" />
                        </div>
                        
                      </Box>
                    </animated.div>
                  </div>
                  {/* <div align="center" className={classes.ubicacionBoxMobile}>
                    <animated.div
                      style={{
                        transition: 'all 4s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBoxMobile}>
                        <Typography  component="h2" className={classes.letraBoxM}> 
                          <FormattedMessage id = "SolucionesPage8" defaultMessage="INNOVACIÓN PARA EL DESARROLLO" /> 
                        </Typography>
                        <div className={classes.ubicacionImg1Mobile}>
                          <img className={classes.sizeImgMobile} src={imgSoluciones4} alt="" />
                        </div>
                        <div className={classes.ubicacionSignoMas1Mobile} >
                          <img className={classes.sizeSignoMasMobile} onClick={handleOpen4Mobile} src={signoMas} alt="" />
                    </div>
                        
                      </Box>
                    </animated.div>
                  </div>*/}
                  <div align="center" className={classes.ubicacionBoxMobile}>
                    <animated.div
                      style={{
                        transition: 'all 4.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBoxMobile}>
                        <Typography  component="h2" className={classes.letraBoxM}> 
                          <FormattedMessage id = "SolucionesPage9" defaultMessage="PRODUCCIÓN GRÁFICA Y AUDIOVISUAL" /> 
                        </Typography>
                        <div className={classes.ubicacionImg1Mobile}>
                          <img className={classes.sizeImgMobile} src={imgSoluciones5} alt="" />
                        </div>
                        <div className={classes.ubicacionSignoMas1Mobile} >
                          <img className={classes.sizeSignoMasMobile} onClick={handleOpen5Mobile} src={signoMas} alt="" />
                        </div>
                        
                      </Box>
                    </animated.div>
                  </div>
                  <div align="center" className={classes.ubicacionBoxMobile}>
                    <animated.div
                      style={{
                        transition: 'all 4.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBoxMobile}>
                        <Typography  component="h2" className={classes.letraBoxM}>
                          <FormattedMessage id = "SolucionesPage10" defaultMessage="EVALUACIÓN DE PROYECTOS Y SISTEMATIZACIÓN" />  
                        </Typography>
                        <div className={classes.ubicacionImg1Mobile}>
                          <img className={classes.sizeImgMobile} src={imgSoluciones6} alt="" />
                        </div>
                        <div className={classes.ubicacionSignoMas1Mobile} >
                          <img className={classes.sizeSignoMasMobile} onClick={handleOpen6Mobile} src={signoMas} alt="" />
                        </div>                        
                      </Box><br></br>
                    </animated.div>
                  </div>
                  <div >
                    <Modal 
                      open={open1Mobile}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box style={{backgroundColor: '#ECF0F3'}} className={classes.boxModal1Mobile} >

                      <div>
                        <div align="right" >
                          <IconButton        
                            aria-label="close"
                            onClick={handleClose1Mobile}          
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}                  
                            //
                          >  
                            <div className={classes.boxCloseMobile}>
                              <Typography className={classes.xCerrarMobile} variant="body1" component="h2"  >
                                x  
                              </Typography>                        
                            </div>
                          </IconButton>
                        </div>
                      </div>

                        <div align="center" >
                          <div className={classes.divModal}  >
                            <div align="left"><br></br>
                              <Typography style={{color:'#334D5C'}} variant="h6" component="h2" className={classes.letra} >                               
                                <FormattedMessage id = "SolucionesPage11" defaultMessage="EDUCACIÓN VIRTUAL" />
                              </Typography><br></br><br></br>
                              <Typography style={{color:'#334D5C',fontFamily: 'proxima-nova',}} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage12" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                <FormattedMessage id = "SolucionesPage30" defaultMessage="VALOR AGREGADO" />  
                              </Typography><br></br><br></br>
                            </div>
                            <div align="center" className={classes.divFondoBlancoMobile} >
                              <div className={classes.divFondoBlanco2Mobile}>
                                <div className={classes.divAlignLeftMobile}>
                                  <div align="left"><br></br>
                                    <Typography variant="body2" className={classes.colorBlueLetra} component="h2" >
                                      <FormattedMessage id = "SolucionesPage13" defaultMessage="1. Curso virtual" /> 
                                    </Typography>
                                    <Typography variant="body2" className={classes.colorBlueLetra}  component="h2" >
                                      <FormattedMessage id = "SolucionesPage14" defaultMessage="2. Curso semipresencial" />
                                    </Typography>
                                    <Typography variant="body2"  className={classes.colorBlueLetra}  component="h2" >
                                      <FormattedMessage id = "SolucionesPage15" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                    </Typography>
                                    <Typography variant="body2" className={classes.colorBlueLetra}  component="h2" >
                                      <FormattedMessage id = "SolucionesPage16" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography><br></br>
                                  </div>
                                </div>
                              </div>
                            </div><br></br><br></br>
                            <div align="left">
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage17" defaultMessage="Lo anterior mediado por una plataforma de enseñanza virtual con las últimas prestaciones gráficas y funcionales (y de accesibilidad) y recursos multimedia tales como: Objetivos virtuales de aprendizaje, podcast, animaciones, documentales educativos, cartillas interactivas y paquetes comprimidos de contenido (microlearning)" /> 
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                <FormattedMessage id = "SolucionesPage18" defaultMessage="VALOR AGREGADO" />  
                              </Typography>
                            </div><br></br><br></br>
                            <div align="left" className={classes.divAlignLeft2Mobile} >
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage19" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales." /> 
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage20" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />     
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage21" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />      
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage22" defaultMessage="• El 90% de nuestros participantes califican nuestros cursos con 9,5/10 puntos" />    
                              </Typography>
                            </div><br></br>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div >
                    <Modal 
                      open={open2Mobile} 
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box style={{backgroundColor: '#ECF0F3'}} className={classes.boxModal1Mobile} >
             
                      <div>
                        <div align="right" >
                          <IconButton        
                            aria-label="close"
                            onClick={handleClose2Mobile}          
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}                          
                            //
                          >  
                            <div className={classes.boxCloseMobile}>
                              <Typography className={classes.xCerrarMobile} variant="body1" component="h2"  >
                                x  
                              </Typography>                        
                            </div>
                          </IconButton>
                        </div>
                      </div>

                        <div align="center" >
                          <div className={classes.divModal}  >
                            <div align="left"><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="h6" component="h2" className={classes.letra} > 
                                <FormattedMessage id = "SolucionesPage23" defaultMessage="Modal 2" />                              
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage24"/>
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage241" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" /> 
                              </Typography><br></br>
                            </div>
                                <div className={classes.divAlignLeftMobile}>
                                  <div align="left"><br></br>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra} >
                                      <FormattedMessage id = "SolucionesPage25" defaultMessage="1. Curso virtual" /> 
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}  >
                                      <FormattedMessage id = "SolucionesPage26" defaultMessage="2. Curso semipresencial" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra} >
                                      <FormattedMessage id = "SolucionesPage27" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}  >
                                      <FormattedMessage id = "SolucionesPage28" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}  >
                                      <FormattedMessage id = "SolucionesPage281" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography><br></br>
                              </div>
                            </div><br></br><br></br>
                            <div align="left">
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage29" defaultMessage="Lo anterior mediado por una plataforma de enseñanza virtual con las últimas prestaciones gráficas y funcionales (y de accesibilidad) y recursos multimedia tales como: Objetivos virtuales de aprendizaje, podcast, animaciones, documentales educativos, cartillas interactivas y paquetes comprimidos de contenido (microlearning) " />  
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                <FormattedMessage id = "SolucionesPage30" defaultMessage="VALOR AGREGADO" />  
                              </Typography>
                            </div><br></br>
                            <div align="center" className={classes.divFondoBlancoMobile} >
                            <div className={classes.divFondoBlanco2Mobile}>
                            <div className={classes.divAlignLeftMobile}>
                            <div align="left"><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage31" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales." />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage32" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />    
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage33" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />     
                              </Typography><br></br>   
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage331" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />     
                              </Typography><br></br>                           
                            </div>                        
                            </div>                        
                            </div>                        
                            </div>                        
                              <div>
                                  <div align="left"><br></br>
                                  <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                    <FormattedMessage id = "SolucionesPage34" defaultMessage="VALOR AGREGADO" />  
                                  </Typography>
                                  </div>
                              </div><br></br>
                              <div align="left" className={classes.divAlignLeft2Mobile} >
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage341" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales." />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage342" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />    
                              </Typography><br></br>                                                            
                            </div>   
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div >
                    <Modal 
                      open={open3Mobile}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box style={{backgroundColor: '#ECF0F3'}} className={classes.boxModal1Mobile} >

                      <div>
                        <div align="right" >
                          <IconButton        
                            aria-label="close"
                            onClick={handleClose3Mobile}          
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}                    
                            //
                          >  
                            <div className={classes.boxCloseMobile}>
                              <Typography className={classes.xCerrarMobile} variant="body1" component="h2"  >
                                x  
                              </Typography>                        
                            </div>
                          </IconButton>
                        </div>
                      </div>

                        <div align="center" >
                          <div className={classes.divModal}  >
                            <div align="left"><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="h6" component="h2" className={classes.letra} >                               
                                <FormattedMessage id = "SolucionesPage35" defaultMessage="Modal 3" />
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage36" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage361" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold', fontSize: '1.25rem'}} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage362" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                
                              </Typography><br></br>
                            </div>
                            <div align="center" className={classes.divFondoBlancoMobile} >
                              <div className={classes.divFondoBlanco2Mobile}>
                                <div className={classes.divAlignLeftMobile}>
                                  <div align="left"><br></br>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}  >
                                      <FormattedMessage id = "SolucionesPage37" defaultMessage="1. Curso virtual" /> 
                                    </Typography>
                                    <Typography variant="body2" component="h2"className={classes.colorBlueLetra}  >
                                      <FormattedMessage id = "SolucionesPage38" defaultMessage="2. Curso semipresencial" />
                                    </Typography>
                                    <Typography variant="body2" component="h2"className={classes.colorBlueLetra} >
                                      <FormattedMessage id = "SolucionesPage39" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2"className={classes.colorBlueLetra} >
                                      <FormattedMessage id = "SolucionesPage40" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography><br></br>
                                  </div>
                                </div>
                              </div>
                            </div><br></br>
                            <div align="left">                            
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                <FormattedMessage id = "SolucionesPage42" defaultMessage="VALOR AGREGADO" />  
                              </Typography>
                            </div><br></br>
                            <div align="left" className={classes.divAlignLeft2Mobile} >
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage43" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales. " />    
                              </Typography><br></br>                              
                            </div><br></br>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div >
                    <Modal 
                      open={open4Mobile}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box style={{backgroundColor: '#ECF0F3'}} className={classes.boxModal1Mobile} >

                      <div>
                        <div align="right" >
                          <IconButton        
                            aria-label="close"
                            onClick={handleClose4Mobile}          
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}              
                            //
                          >  
                            <div className={classes.boxCloseMobile}>
                              <Typography className={classes.xCerrarMobile} variant="body1" component="h2"  >
                                x  
                              </Typography>                        
                            </div>
                          </IconButton>
                        </div>
                      </div>

                        <div align="center" >
                          <div className={classes.divModal}  >
                            <div align="left"><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="h6" component="h2" className={classes.letra} >                               
                                <FormattedMessage id = "SolucionesPage47" defaultMessage="Modal 4" />
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage48" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" /> 
                              </Typography><br></br><br></br>
                            </div>
                            <div align="center" className={classes.divFondoBlancoMobile} >
                              <div className={classes.divFondoBlanco2Mobile}>
                                <div className={classes.divAlignLeftMobile}>
                                  <div align="left"><br></br>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage49" defaultMessage="1. Curso virtual" /> 
                                    </Typography>
                                    <Typography variant="body2" component="h2"className={classes.colorBlueLetra} >
                                      <FormattedMessage id = "SolucionesPage50" defaultMessage="2. Curso semipresencial" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage51" defaultMessage="3. Curso por celular o Tablet (mobile learning)" /> 
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage52" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography><br></br>
                                  </div>
                                </div>
                              </div>
                            </div><br></br><br></br>
                            <div align="left">
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage53" defaultMessage="Lo anterior mediado por una plataforma de enseñanza virtual con las últimas prestaciones gráficas y funcionales (y de accesibilidad) y recursos multimedia tales como: Objetivos virtuales de aprendizaje, podcast, animaciones, documentales educativos, cartillas interactivas y paquetes comprimidos de contenido (microlearning)" />
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                <FormattedMessage id = "SolucionesPage54" defaultMessage="VALOR AGREGADO" />  
                              </Typography>
                            </div><br></br><br></br>
                            <div align="left" className={classes.divAlignLeft2Mobile} >
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage55" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales. " /> 
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage56" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />    
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage57" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />      
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage58" defaultMessage="• El 90% de nuestros participantes califican nuestros cursos con 9,5/10 puntos" />    
                              </Typography>
                            </div><br></br>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div >
                    <Modal 
                      open={open5Mobile}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box style={{backgroundColor: '#ECF0F3'}} className={classes.boxModal1Mobile} >
                      
                      <div>
                        <div align="right" >
                          <IconButton        
                            aria-label="close"
                            onClick={handleClose5Mobile}          
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}                  
                            //
                          >  
                            <div className={classes.boxCloseMobile}>
                              <Typography className={classes.xCerrarMobile} variant="body1" component="h2"  >
                                x  
                              </Typography>                        
                            </div>
                          </IconButton>
                        </div>
                      </div>

                        <div align="center" >
                          <div className={classes.divModal}  >
                            <div align="left"><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="h6" component="h2" className={classes.letra} >                               
                                <FormattedMessage id = "SolucionesPage59" defaultMessage="Modal 5" />
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage60" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage601" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage6011" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold', fontSize: '1.25rem'}} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage602" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra} style={{marginLeft: '15px'}}  variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage603" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />  
                              </Typography><br></br>
                              <Typography className={classes.colorBlueLetra}  style={{fontWeight: 'bold', fontSize: '1.25rem'}} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage604" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />  
                              </Typography><br></br>
                            </div>
                            <div align="center" className={classes.divFondoBlancoMobile} >
                              <div className={classes.divFondoBlanco2Mobile}>
                                <div className={classes.divAlignLeftMobile}>
                                  <div align="left"><br></br>
                                    <Typography variant="body2" component="h2"className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage61" defaultMessage="1. Curso virtual" /> 
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage62" defaultMessage="2. Curso semipresencial" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage63" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage64" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage641" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage642" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage643" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography><br></br>
                                  </div>
                                </div>
                              </div>
                            </div><br></br><br></br>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div >
                    <Modal 
                      open={open6Mobile}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                    <Box style={{backgroundColor: '#ECF0F3'}} className={classes.boxModal1Mobile} >
                      
                      <div>
                        <div align="right" >
                          <IconButton        
                            aria-label="close"
                            onClick={handleClose6Mobile}          
                            sx={{
                              position: 'absolute',
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}                  
                            //
                          >  
                            <div className={classes.boxCloseMobile}>
                              <Typography className={classes.xCerrarMobile} variant="body1" component="h2"  >
                                x  
                              </Typography>                        
                            </div>
                          </IconButton>
                        </div>
                      </div>

                        <div align="center" >
                          <div className={classes.divModal}  >
                            <div align="left"><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="h6" component="h2" className={classes.letra} >                               
                                <FormattedMessage id = "SolucionesPage71" defaultMessage="Modal 6" />
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage72" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />   
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontStyle:"italic"}} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage720" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />   
                              </Typography><br></br><br></br>
                              <Typography className={classes.colorBlueLetra} style={{fontWeight:'bold', fontSize: '1.25rem'}} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage721" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />   
                              </Typography><br></br><br></br>
                            </div>
                            <div align="center" className={classes.divFondoBlancoMobile} >
                              <div className={classes.divFondoBlanco2Mobile}>
                                <div className={classes.divAlignLeftMobile}>
                                  <div align="left"><br></br>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage73" defaultMessage="1. Curso virtual" /> 
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage74" defaultMessage="2. Curso semipresencial" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage75" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                    </Typography>
                                    <Typography variant="body2" component="h2" className={classes.colorBlueLetra}>
                                      <FormattedMessage id = "SolucionesPage76" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                    </Typography><br></br>
                                  </div>
                                </div>
                              </div>
                            </div><br></br><br></br>
                            <div align="left">
                              <Typography className={classes.colorBlueLetra} style={{fontWeight: 'bold'}} variant="h6" component="h2" >
                                <FormattedMessage id = "SolucionesPage78" defaultMessage="VALOR AGREGADO" />  
                              </Typography>
                            </div><br></br><br></br>
                            <div align="left" className={classes.divAlignLeft2Mobile} >
                              <Typography className={classes.colorBlueLetra} variant="body2" component="h2" >
                                <FormattedMessage id = "SolucionesPage79" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales. " /> 
                              </Typography><br></br>                              
                            </div><br></br>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>  
                </div>
              </Grid>
            </Grid>
          </Container>             
        </div>
      );
    }
  
  const displayDesktop = () => {
  
    return (
      <div style={{backgroundColor: 'white'}}>
        <CssBaseline />
          <Container >           
                        <animated.div
                          style={{
                            transition: 'all 0s linear',
                            opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                          }}
                        >
                          <Box p={1} className={classes.columnaRoja} >
                          </Box>
                        </animated.div>
                        <animated.div
                          style={{
                            transition: 'all 0.3s linear',
                            opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                          }}
                        >
                          <Box p={1} className={classes.columnaRoja2} >
                          </Box>
                        </animated.div>
                          <animated.div
                            style={{
                              transition: 'all 0.2s linear',
                              opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                            }}
                          >
                            <br></br><br></br><br></br><br></br>
                            <Typography variant="h4" component="h2" style={{marginTop: '50px', marginLeft: '-14px', width: '930px', margin: 'auto'}} className={classes.letra}>
                            <div className={classes.ubicacionPuntos}>
                              <img className={classes.sizePuntos} src={puntos} alt="" />
                            </div>  
                              <Box component="span" className={classes.underlineTextDesktop}><FormattedMessage id = "SolucionesPage1" defaultMessage="SOLUCI"></FormattedMessage></Box><FormattedMessage id = "SolucionesPage2" defaultMessage="ONES EMBLEMÁTICAS"></FormattedMessage> 
                            </Typography>
                            <br></br>
                          </animated.div>
                          <animated.div
                            style={{
                              transition: 'all 0.7s linear',
                              opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                            }}
                          >
                            <div style={{ width: '930px', margin: 'auto'}}>
                            <Typography variant="h5" component="h2" className={classes.letraTagline}>
                              <FormattedMessage id = "SolucionesPage3" defaultMessage="Vestibulum lectus purus, pretium nec elit at, fringilla scelerisque sapien." />                             
                            </Typography>                            
                            </div>
                          </animated.div>
                       <br></br><br></br><br></br><br></br><br></br><br></br>
                  <div className={classes.divAlignLeft3} >
                    <div className={classes.divBox} > 
                      <animated.div
                        style={{
                          transition: 'all 0.1s linear',
                          opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                        }}
                      >
                        <div align="center" className={classes.divBox1} >
                          <Box p={2} bgcolor="#2b4e5c" className={classes.sizeBox}><br></br>
                            <Typography variant="h6" component="h2" className={classes.letra2}>         
                              <FormattedMessage id = "SolucionesPage5" defaultMessage="EDUCACIÓN VIRTUAL" /> 
                            </Typography>
                            <div className={classes.ubicacionImg1}>
                              <img className={classes.sizeImg} src={imgSoluciones1} alt="" />
                            </div>
                            <div  className={classes.ubicacionSignoMas1} >
                              <img className={classes.sizeSignoMas} onClick={handleOpen1} src={signoMas} alt="" />
                            </div>
                            <div className={classes.btnSignoMas1} >
                              
                            </div>
                          </Box>
                        </div>
                      </animated.div>
                      <animated.div
                        style={{
                          transition: 'all 0.3s linear',
                          opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                        }}
                      >
                        <div align="center" className={classes.divBox1} >
                          <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBox}><br></br>
                            <Typography variant="h6" component="h2" className={classes.letra2}> 
                              <FormattedMessage id = "SolucionesPage6" defaultMessage="PLATAFORMAS WEB CON SENTIDO" />
                            </Typography>
                            <div className={classes.ubicacionImg2}>
                              <img className={classes.sizeImg} src={imgSoluciones2} alt="" />
                            </div>
                            <div  className={classes.ubicacionSignoMas1} >
                              <img className={classes.sizeSignoMas}     onClick={handleOpen2} src={signoMas} alt="" />
                            </div>
                            <div className={classes.btnSignoMas2} >
                              
                            </div>
                          </Box>
                        </div>
                      </animated.div>
                      <animated.div
                        style={{
                          transition: 'all 0.5s linear',
                          opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                        }}
                      >
                        <div align="center" className={classes.divBox1} >
                          <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBox}>
                            <Typography  className={classes.letra3}> 
                              <FormattedMessage id = "SolucionesPage7" defaultMessage="ASESORÍA ESTRATÉGICA EN GESTIÓN DE CONOCIMIENTO E INNOVACIÓN" /> 
                            </Typography>
                            <div className={classes.ubicacionImg3}>
                              <img className={classes.sizeImg} src={imgSoluciones3} alt="" />
                            </div>
                            <div  className={classes.ubicacionSignoMas1} >
                              <img className={classes.sizeSignoMas} onClick={handleOpen3} src={signoMas} alt="" />
                            </div>
                            <div className={classes.btnSignoMas3} >
                              
                            </div>
                          </Box>
                        </div>
                      </animated.div>
                      {/*<animated.div
                        style={{
                          transition: 'all 0.7s linear',
                          opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                        }}
                      >
                        <div align="center" className={classes.divBox1} >
                          <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBox}>
                            <Typography variant="h6" component="h2" className={classes.letra2}> 
                              <FormattedMessage id = "SolucionesPage8" defaultMessage="INNOVACIÓN PARA EL DESARROLLO" /> 
                            </Typography>
                            <div className={classes.ubicacionImg4}>
                              <img className={classes.sizeImg} src={imgSoluciones4} alt="" />
                            </div>
                            <div className={classes.ubicacionSignoMas1} >
                              <img className={classes.sizeSignoMas} onClick={handleOpen4} src={signoMas} alt="" />
                            </div>
                            <div className={classes.btnSignoMas4} >
                           
                          </div>
                        </Box>
                      </div>
                    </animated.div>*/}
                    <animated.div
                      style={{
                        transition: 'all 0.9s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <div align="center" className={classes.divBox1} >
                        <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBox}>
                          <Typography variant="h6" component="h2" className={classes.letra2}> 
                            <FormattedMessage id = "SolucionesPage9" defaultMessage="PRODUCCIÓN GRÁFICA Y AUDIOVISUAL" />
                          </Typography>
                          <div className={classes.ubicacionImg5}>
                            <img className={classes.sizeImg}  src={imgSoluciones5} alt="" />
                          </div>
                          <div className={classes.ubicacionSignoMas1} >
                            <img className={classes.sizeSignoMas} onClick={handleOpen5} src={signoMas} alt="" />
                          </div>
                          <div className={classes.btnSignoMas5} >
                            
                          </div>
                        </Box>
                      </div>
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 1.1s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <div align="center" className={classes.divBox1} >
                        <Box p={3} bgcolor="#2b4e5c" className={classes.sizeBox}>
                          <Typography variant="h6" component="h2" className={classes.letra2}> 
                            <FormattedMessage id = "SolucionesPage10" defaultMessage="EVALUACIÓN DE PROYECTOS Y SISTEMATIZACIÓN" />       
                          </Typography>
                          <div className={classes.ubicacionImg6}>
                            <img className={classes.sizeImg} src={imgSoluciones6} alt="" />
                          </div>
                          <div className={classes.ubicacionSignoMas1} >
                            <img className={classes.sizeSignoMas} onClick={handleOpen6} src={signoMas} alt=""/>
                          </div>
                          <div className={classes.btnSignoMas6} >
                          
                        </div>
                      </Box>
                    </div>
                  </animated.div>
                </div>
              </div>
              <div >
                <Modal 
                  open={open1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  hideBackdrop
                >
                  <Box style={{backgroundColor: '#111D2F'}} className={classes.boxModal1} >

                    <div>
                      <div align="right" >                        
                          <div className={classes.xCerrar}>                                                
                            <img className={classes.imageSize} src={cerrarRojo} onClick={handleClose1}  alt="Rojo4" />
                          </div>
                      </div>
                    </div>

                    <div align="center" >
                      <div className={classes.divModal}  >
                        <div align="left">
                          <div className={classes.ubicacionPuntosModal} >
                            <img className={classes.sizePuntosModales} src={puntos} alt="" />
                          </div>                                        
                          <Typography className={classes.typographyTitle} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage11" defaultMessage="Educación virtual" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage12" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />
                          </Typography><br></br><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage30" defaultMessage="Valor agregado" />
                          </Typography><br></br><br></br><br></br>
                        </div>
                        <div align="center" className={classes.divFondoBlanco} >
                          <div className={classes.divFondoBlanco2}>
                            <div className={classes.divAlignLeft}>
                              <div align="left"><br></br>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage13" defaultMessage="1. Curso virtual" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage14" defaultMessage="2. Curso semipresencial" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage15" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />                                  
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage16" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                              </div>
                            </div>
                          </div>
                        </div><br></br><br></br><br></br>
                        <div align="left">
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage17" defaultMessage="Lo anterior mediado por una plataforma de enseñanza virtual con las últimas prestaciones gráficas y funcionales (y de accesibilidad) y recursos multimedia tales como: Objetivos virtuales de aprendizaje, podcast, animaciones, documentales educativos, cartillas interactivas y paquetes comprimidos de contenido (microlearning)" />                           
                          </Typography><br></br><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage18" defaultMessage="VALOR AGREGADO" />
                          </Typography>
                        </div><br></br><br></br>
                        <div align="left" className={classes.divAlignLeft2} >
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage19" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales." />
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage20" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />                            
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage21" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />                                 
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage22" defaultMessage="• El 90% de nuestros participantes califican nuestros cursos con 9,5/10 puntos" />
                          </Typography><br></br>
                        </div><br></br>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div >
                <Modal 
                  open={open2}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  hideBackdrop
                >
                  <Box style={{backgroundColor: '#111D2F'}} className={classes.boxModal1} >

                    <div>
                    <div align="right" >                        
                          <div className={classes.xCerrar}>                                                
                            <img className={classes.imageSize} src={cerrarRojo} onClick={handleClose2}  alt="Rojo4" />
                          </div>
                      </div>
                    </div>

                    <div align="center" >
                      <div className={classes.divModal}  >
                        <div align="left">
                          <div className={classes.ubicacionPuntosModal} >
                            <img className={classes.sizePuntosModales} src={puntos} alt="" />
                          </div>                                        
                          <Typography className={classes.typographyTitle} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage23" defaultMessage="Modal 2" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage24" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage241" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />
                          </Typography><br></br>
                          <br></br><br></br>
                        
                          <div className={classes.divAlignLeft}>
                                <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage25" defaultMessage="1. Curso virtual" />
                                </Typography><br></br>
                                <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage26" defaultMessage="2. Curso semipresencial" />
                                </Typography><br></br>
                                <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage27" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                </Typography><br></br>
                                <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage28" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                                <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage281" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                             <br></br><br></br>
                             </div>
                             </div>
                        <div align="left">
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage29" defaultMessage="Lo anterior mediado por una plataforma de enseñanza virtual con las últimas prestaciones gráficas y funcionales (y de accesibilidad) y recursos multimedia tales como: Objetivos virtuales de aprendizaje, podcast, animaciones, documentales educativos, cartillas interactivas y paquetes comprimidos de contenido (microlearning) " />    
                          </Typography><br></br><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage30" defaultMessage="Valor agregado" />
                          </Typography>
                        </div><br></br><br></br><br></br>
                        <div className={classes.divFondoBlanco2}>
                            <div className={classes.divAlignLeft}>
                              <div align="left"><br></br>
                          <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage31" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales." />                             
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage32" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />                                
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage33" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage331" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />
                          </Typography><br></br>
                          </div>
                          </div>
                        </div><br></br><br></br><br></br>
                        <div align="left">
                        <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage34" defaultMessage="• El 90% de nuestros participantes califican nuestros cursos con 9,5/10 puntos" />                                
                          </Typography><br></br><br></br>                       
                        </div>
                        <div align="left" className={classes.divAlignLeft2} >
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage341" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales." />                             
                          </Typography><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage342" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />                                
                          </Typography><br></br><br></br>                         
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div >
                <Modal 
                  open={open3}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  hideBackdrop
                >
                  <Box style={{backgroundColor: '#111D2F'}} className={classes.boxModal1} >
                    
                    <div>
                    <div align="right" >                        
                          <div className={classes.xCerrar}>                                                
                            <img className={classes.imageSize} src={cerrarRojo} onClick={handleClose3}  alt="Rojo4" />
                          </div>
                      </div>
                    </div>

                    <div align="center" >
                      <div className={classes.divModal}  >
                        <div align="left">
                          <div className={classes.ubicacionPuntosModal} >
                            <img className={classes.sizePuntosModales} src={puntos} alt="" />
                          </div>                                        
                          <Typography className={classes.typographyTitle} variant="h5" component="h2" > 
                            <FormattedMessage id = "SolucionesPage35" defaultMessage="Modal 3" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="h5" component="h2" > 
                            <FormattedMessage id = "SolucionesPage36" defaultMessage="Modal 3" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage361" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                            
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage362" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                            
                          </Typography><br></br><br></br><br></br>
                        </div>
                        <div align="center" className={classes.divFondoBlanco} >
                          <div className={classes.divFondoBlanco2}>
                            <div className={classes.divAlignLeft}>
                              <div align="left"><br></br>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage37" defaultMessage="1. Curso virtual" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage38" defaultMessage="2. Curso semipresencial" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage39" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />                                 
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage40" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                              </div>
                            </div>
                          </div>
                        </div><br></br><br></br><br></br>
                        <div align="left">                          
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage42" defaultMessage="Valor agregado" />
                          </Typography>
                        </div><br></br><br></br>
                        <div align="left" className={classes.divAlignLeft2} >
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage43" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales. " />                            
                          </Typography><br></br><br></br>
                      
                        </div><br></br>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div >
                <Modal 
                  open={open4}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  hideBackdrop
                >
                  <Box style={{backgroundColor: '#111D2F'}} className={classes.boxModal1} >

                    <div>
                    <div align="right" >                        
                          <div className={classes.xCerrar}>                                                
                            <img className={classes.imageSize} src={cerrarRojo} onClick={handleClose4}  alt="Rojo4" />
                          </div>
                      </div>
                    </div>

                    <div align="center" >
                      <div className={classes.divModal}  >
                        <div align="left">
                          <div className={classes.ubicacionPuntosModal} >
                            <img className={classes.sizePuntosModales} src={puntos} alt="" />
                          </div>                                        
                          <Typography className={classes.typographyTitle} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage47" defaultMessage="Modal 4" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage48" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                            
                          </Typography><br></br><br></br><br></br>
                        </div>
                        <div align="center" className={classes.divFondoBlanco} >
                          <div className={classes.divFondoBlanco2}>
                            <div className={classes.divAlignLeft}>
                              <div align="left"><br></br>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage49" defaultMessage="1. Curso virtual" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage50" defaultMessage="2. Curso semipresencial" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage51" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />                                  
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage52" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                              </div>
                            </div>
                          </div>
                        </div><br></br><br></br><br></br>
                        <div align="left">
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage53" defaultMessage="Lo anterior mediado por una plataforma de enseñanza virtual con las últimas prestaciones gráficas y funcionales (y de accesibilidad) y recursos multimedia tales como: Objetivos virtuales de aprendizaje, podcast, animaciones, documentales educativos, cartillas interactivas y paquetes comprimidos de contenido (microlearning)" />
                          </Typography><br></br><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage54" defaultMessage="Valor agregado" />
                          </Typography>
                        </div><br></br><br></br><br></br>
                        <div align="left" className={classes.divAlignLeft2} >
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage55" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales. " />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage56" defaultMessage="• Planteamos una educación para la acción. Proponemos cursos aplicados, prácticos, con vocación de caja de herramientas. ¡Cursos que solucionan!" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage57" defaultMessage="• Nuestros cursos funcionan: nuestras tasas de retención y de aprobación son del 80% (muy superior que la media latinoamericana de educación virtual y/o presencial)" />                                 
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage58" defaultMessage="• El 90% de nuestros participantes califican nuestros cursos con 9,5/10 puntos" />
                          </Typography>
                        </div><br></br>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div >
                <Modal 
                  open={open5}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  hideBackdrop
                >
                  <Box style={{backgroundColor: '#111D2F'}} className={classes.boxModal1} >

                    <div>
                    <div align="right" >                        
                          <div className={classes.xCerrar}>                                                
                            <img className={classes.imageSize} src={cerrarRojo} onClick={handleClose5}  alt="Rojo4" />
                          </div>
                      </div>
                    </div>

                    <div align="center" >
                      <div className={classes.divModal}  >
                        <div align="left">
                          <div className={classes.ubicacionPuntosModal} >
                            <img className={classes.sizePuntosModales} src={puntos} alt="" />
                          </div>                                        
                          <Typography className={classes.typographyTitle} variant="h5" component="h2" > 
                            <FormattedMessage id = "SolucionesPage59" defaultMessage="Modal 5" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage60" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />      
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage601" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />      
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage6011" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />      
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage602" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />      
                          </Typography><br></br><br></br>
                          <div className={classes.divAlignLeft}>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage603" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />      
                          </Typography>
                          </div><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage604" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />      
                          </Typography><br></br><br></br><br></br>
                        </div>
                        <div align="center" className={classes.divFondoBlanco} >
                          <div className={classes.divFondoBlanco2}>
                            <div className={classes.divAlignLeft}>
                              <div align="left"><br></br>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage61" defaultMessage="1. Curso virtual" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage62" defaultMessage="2. Curso semipresencial" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage63" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage64" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage641" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage642" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage643" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                              </div>
                            </div>
                          </div>
                        </div><br></br><br></br><br></br>                
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div >
                <Modal 
                  open={open6}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  hideBackdrop
                >
                  <Box style={{backgroundColor: '#111D2F'}} className={classes.boxModal1} >

                    <div>
                    <div align="right" >                        
                          <div className={classes.xCerrar}>                                                
                            <img className={classes.imageSize} src={cerrarRojo} onClick={handleClose6}  alt="Rojo4" />
                          </div>
                      </div>
                    </div>

                    <div align="center" >
                      <div className={classes.divModal}  >
                        <div align="left">
                          <div className={classes.ubicacionPuntosModal} >
                            <img className={classes.sizePuntosModales} src={puntos} alt="" />
                          </div>                                        
                          <Typography className={classes.typographyTitle} variant="h5" component="h2" > 
                            <FormattedMessage id = "SolucionesPage71" defaultMessage="Modal 6" />
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage72" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                            
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontStyle:"italic"}} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage720" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                            
                          </Typography><br></br><br></br>
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight: 'bold'}} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage721" defaultMessage="Acompañamos a nuestros clientes para que puedan fortalecer las capacidades técnicas y funcionales de su personal, a fin de mejorar sus prácticas. Luego de hacer un análisis de los objetivos de aprendizaje y de las características del público objetivo, InnovaHub asesora para dar forma a una iniciativa de formación virtual que puede tomar forma de:" />                            
                          </Typography><br></br><br></br><br></br>
                        </div>
                        <div align="center" className={classes.divFondoBlanco} >
                          <div className={classes.divFondoBlanco2}>
                            <div className={classes.divAlignLeft}>
                              <div align="left"><br></br>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage73" defaultMessage="1. Curso virtual" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage74" defaultMessage="2. Curso semipresencial" />                                  
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage75" defaultMessage="3. Curso por celular o Tablet (mobile learning)" />
                                </Typography>
                                <Typography className={classes.typographyMetodologia2} variant="body1" component="h2" >
                                  <FormattedMessage id = "SolucionesPage76" defaultMessage="4. Juego digital para el aprendizaje (juego serio)" />
                                </Typography><br></br>
                              </div>
                            </div>
                          </div>
                        </div><br></br><br></br><br></br>
                        <div align="left">
                       
                          <Typography className={classes.typographyMetodologia3} style={{fontWeight:'bold'}} variant="h5" component="h2" >
                            <FormattedMessage id = "SolucionesPage78" defaultMessage="Valor agregado" />
                          </Typography>
                        </div><br></br><br></br>
                        <div align="left" className={classes.divAlignLeft2} >
                          <Typography className={classes.typographyMetodologia3} variant="body1" component="h2" >
                            <FormattedMessage id = "SolucionesPage79" defaultMessage="• Superamos la 'moda' de la educación virtual y nos preocupamos por la calidad. No es suficiente el acceso, si no la calidad integral de las iniciativas virtuales. " />
                          </Typography><br></br><br></br>
                        </div><br></br>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>

      </Container>                       
    </div>
  );
}

  return (
    <header>
        {mobileView ? displayMobile() : displayDesktop()}
    </header>
  );
  }