import React from 'react';
import {  useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import facebook  from './images/logo-facebook.png';
import linkedin  from './images/logo-linkedin.png';
import instagram  from './images/logo-instagram.png';
import WhatsApp  from './images/logo-whatsapp.png';
import v9Edit from './images/v9-edit-min.png';
import puntos from'./images/puntos.png';
import personaje1 from'./images/personajes-icono-1.png';
import personaje2 from'./images/personajes-icono-2.png';
import personaje3 from'./images/personajes-icono-3.png';
import personaje4 from'./images/personajes-icono-4.png';
import personaje5 from'./images/personajes-icono-5.png';
import personaje6 from'./images/personajes-icono-6.png';
import tierra from './images/render-2-min.png';
import ComponentE1 from './images/Componente 1 – 1.png';
import ComponentE2 from './images/Componente 2 – 1.png'; 
import ComponentE3 from './images/Componente 3 – 1.png';
import figuraModal from './images/20_5-min.png';
import figuraModal2 from './images/20_6-min.png';
import figuraModal3 from './images/20_4-min.png';
import figuraModal4 from './images/20_3-min.png';
import figuraModal5 from './images/20_2-min.png';
import figuraModal6 from './images/20_1-min.png';
import Rojo1 from './images/1-rojo.png';
import Rojo2 from './images/2-rojo.png';
import Rojo3 from './images/3-rojo.png';
import Rojo4 from './images/4-rojo.png';
import Rojo5 from './images/5-rojo.png';
import Rojo6 from './images/6-rojo.png';
import flechaAbajo from './images/icon-bajar.png';
import Circulo from './images/circuloRojo.png';
import Amarillo1 from './images/1-amarillo.png';
import Amarillo2 from './images/2-amarillo.png';
import Amarillo3 from './images/3-amarillo.png';
import Amarillo4 from './images/4-amarillo.png';
import Amarillo5 from './images/5-amarillo.png';
import Amarillo6 from './images/6-amarillo.png';
import IconButton from '@material-ui/core/IconButton';
import cerrarRojo from './images/icon-cerrar.png';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';  
import { animated } from 'react-spring';
import { useSpring } from 'react-spring';
import lineaDorada from './images/linea-dorada.png';
import lineaDoradaMobile from './images/lineaDoradaMobile.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Espanish from './images/Espanish.png';
import Portuguese from './images/Portuguese.png';
import USA from './images/USA.png';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import { ArrowDownward, LocalSeeOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fondo1Desktop:{
    backgroundImage:'url(./fondo/home-min.png)',
    backgroundSize: '100vw 125vh',
    marginTop:'-15em',
    position: 'relative',
    height: '118vh',
    minHeight: '800px',
    backgroundColor:'#121D2F',
    backgroundPosition:'',
    ['@media (max-height:620px)']: {
      backgroundSize: '100vw 900px',
      backgroundPosition: '-10%'
    },
    ['@media (min-height:1050px)']: {
      backgroundSize: '100vw 100%',
      backgroundPosition: '-10%'
    },
    ['@media (max-width:1100px)']: {
      backgroundSize: '1200px 100%',
      backgroundPosition: '60%'

    },
    ['@media (max-width:1200px)']: {
      backgroundSize: '1300px 100%',
      backgroundPosition: '60%'

    },

  },
  fondo2Desktop:{
    backgroundImage:'url(./fondo/home2.png)',
    backgroundSize: 'cover',
    position: 'relative',
    maxWidth: '100%',
    height: '85vw',
    minHeight: '1700px',
    maxHeight: '2000px'
    
  },
  fondo3Desktop:{
    maxWidth: '100%',
    position: 'relative',
    backgroundColor:'#111D2F'
  },
  lineaAmarilla:{
    borderStyle: 'solid',
    borderColor: '#EFC94C',
  },
  fondo1Mobile:{
    backgroundImage:'url(./fondo/homeMobile8-min.png)',
    backgroundSize: '150% 100%',
    ['@media (max-width:650px)']: {
      backgroundSize: '300% 100%',
      backgroundPosition: '26%'
    },
    backgroundColor:'#121D2F',
    height: '100vh',
    minHeight: '670px',
    width: '100%',
    position: 'relative',
  },
  fondo2Mobile:{
    backgroundImage:'url(./fondo/home2.png)',
    position: 'relative',
    maxWidth: '100%',
    height: '55em',

  },
  fondo3Mobile:{
    maxWidth: '100%',
    height: '90em',
    position: 'relative',
    backgroundColor:'#111D2F'
  },
  div1Desktop:{
    width: '100%',
    margin: '31% 0',
  },

  div1Mobile:{
    position: 'relative',
    width: '23em',
    marginTop: '15px'
  },

  div1Mobile2:{
    top: '65%',
    position: 'absolute',
    left: '0',
    right: '0',
    margin: 'auto',
  },
  div2Desktop:{
    width: '40%',
    //marginTop:'-15%',
    marginTop:'-30vmin',
    marginLeft:'52em',
    
  },
  div2Mobile:{
    margin: '-25em 0',
  },
  typography:{
    fontWeight: 'bold',
    color:'white',
    fontSize:'50px', 
    fontFamily: 'proxima-nova',
  },
  typographyHome:{
    fontWeight: 'bold',
    color:'white',
    fontSize:'50px',
    fontFamily: 'proxima-nova',
    ['@media (max-width:1209px)']: {
      fontSize:'40px', 
      marginLeft: '100px',
      fontFamily: 'proxima-nova',
    }, 
  },
  spanDesktop:{
    color: '#df5a49',    
  },
  typographyMetodologia:{
    color:'white',
    fontSize:'16px', 
    fontFamily: 'proxima-nova',
  },
  typographyMetodologiaMD:{
    color:'white',
    fontSize:'18px', 
    fontWeight:'100',
    fontFamily: 'proxima-nova',
    ['@media (max-width:1400px)']: {
      width: '64vw',
      fontFamily: 'proxima-nova',
    },
  },
  typographyMetodologiaM1:{
    color:'white',
    fontSize:'21px', 
    fontWeight:'100',
    width: '55%',
    fontFamily: 'proxima-nova',
  },
  typographyMetodologiaM2:{
    color:'white',
    fontSize:'21px', 
    fontWeight:'100', 
    fontFamily: 'proxima-nova',
    ['@media (max-width:1260px)']: {
      fontSize:'14px', 
      fontFamily: 'proxima-nova',
    },
  },
  typographyMetodologiaM3:{
    color:'white',
    fontSize:'21px', 
    fontWeight:'100',
    fontFamily: 'proxima-nova',
    ['@media (max-width:1260px)']: {
      fontSize:'14px', 
      width: '600px',
      fontFamily: 'proxima-nova',
    },
  },
  typographyMetodologiaA:{
    color:'white',
    fontSize:'40px', 
    fontFamily: 'proxima-nova',
    ['@media (max-width:650px)']: {
      fontSize:'22px', 
      fontFamily: 'proxima-nova',
    },
    fontWeight: 'bold'
  },
  typographyMetodologiaMP:{
    color:'white',
    fontSize:'15px', 
    fontFamily: 'proxima-nova',
  },
  typographyMetodologiaM:{
    color:'white',
    fontSize:'28px', 
    fontWeight: 'bold',
    display: 'inline-block',
    fontFamily: 'proxima-nova',

  },
  typographyMetodologiaMobile:{
    color:'white',
    fontFamily: 'proxima-nova',
  },
  divMetodologia:{
    margin: '6% 0',
  },
  sizev9Edit:{
    //margin:'0% 0 0 -23%',
    //width: '140%',
    margin:'0% 0 0 0%',
    width: '40vw',
    minWidth: '550px',
    maxWidth: '1000px',
    //height: '87em',
  },
  sizev9EditMobile:{
    width: '31em',
    height: '54em',
  },
  sizePuntos:{
    width: '8px',
    marginTop: '4px'
  },
  sizePuntos2:{
    width: '8px',
  },
  sizePuntosMobile:{
    width: '6px',
  },
  sizePersonajes:{
    //width: '55%',
    width: '160px',
    ['@media (max-width:1260px)']: {
      width: '130px'
    },
  },
  ubicacionPuntos:{
    margin: '0.5% 0 0 -1.2%',
    position: 'absolute',
  },
  ubicacionPuntosMobile:{
    margin: '-2.5% 0 0 -30px',
    position: 'relative',
    padding: '1em',
  },
  ubicacionPuntos2:{
    margin: '-10px 0 0 -10px',
    position: 'absolute',
    ['@media (max-width:1260px)']: {
      margin: '-1px 0 0 -10px',
    },
  },
  ubicacionPuntos2Mobile:{
    margin: '-2px 0 0 -30px',
    ['@media (max-width:650px)']: {
      margin: '-14px 0 0 -30px',
    },
    position: 'absolute',
    padding: '1em',
  },
  ubicacionPuntos3:{
    margin: '7px 0 0 -25px',
    position: 'absolute',
  },
  ubicacionPuntos3Mobile:{
    margin: '0% 0 0 -15px',
    position: 'absolute',
  },
  ubicacionPuntosModal:{
    margin: '0.2em 0 0 -2.5em',
    padding: '1em',
    position: 'absolute',
  },
  ubicacionPuntosModal2:{
    margin: '0.1em 0 0 -2.5em',
    padding: '1em',
    position: 'absolute',
  },
  ubicacionPersonaje1:{
    //margin: '4% 0 0 -12%',
    margin: '5.5% 0 0 -12%',
    position: 'absolute', 
  },
  ubicacionPersonaje2:{
    //margin: '7% 0 0 -3%',
    margin: '0% 0 0 85%',
    position: 'absolute', 
  },
  ubicacionPersonaje3:{
    margin: '54% 0 0 -10%',
    position: 'absolute',
  },
  ubicacionPersonaje4:{
    margin: '55% 0 0 91%',
    position: 'absolute',
  },
  ubicacionPersonaje5:{
    top: '64%',
    position: 'absolute', 
  },
  ubicacionPersonaje6:{
    top: '68%',
    left: '77%',
    position: 'absolute', 
  },
  typographyPersonajes:{
    color:'white',
    fontWeight: 'bold',
    fontSize:'18px',
    fontStyle: 'italic', 
    fontFamily: 'proxima-nova',
  },
  divtypography1:{
    //margin: '23% 0 0 -70%',
    margin: '3.5em 0 0 -10.5em',
    position: 'absolute', 
  },
  divtypography2:{
    width:'200px',
    margin: '3.5em 0 0 10em',
    position: 'absolute', 
  },
  divtypography3:{
    margin: '-3.5em 0 0 -12em',
    position: 'absolute', 
  },
  divtypography4:{
    //margin: '27% 0 0 100%',
    margin: '12em 0 0 12em',
    position: 'absolute', 
    width: '210px',
    ['@media (max-width:1260px)']: {
      margin: '9em 0 0 10em',
    },
    ['@media (max-width:1100px)']: {
      width: '120px',
    },
  },
  divtypography5:{
    //margin: '-12% 0 0 -45%',
    margin: '-2.5em 0 0 -8.5em',
    position: 'absolute', 
  },
  divtypography6:{
    //margin: '-43% 0 0 61%',
    width: '200px',
    margin: '-10em 0 0 180px',
    position: 'absolute',
    ['@media (max-width:1260px)']: {
      margin: '-10em 0 0 150px',
    }, 
  },
  divImagenes:{
    position: 'relative', 
  },
  divImagenesDesktop:{
    position: 'relative',
    marginTop:'0%',
  },
  sizeTierra:{
    width: '50%',  
    margin: '-200px 0 0 25%',
    ['@media (max-width:1600px)']: {
      margin: '-300px 0 0 25%',
    },
  },
  sizeTierraMobile:{
    width: '560px', 
    margin: '-55px 0 0 40%',
  },

  divProyectos:{
    position: 'absolute', 
    margin: '-20% 0 0 0%',
    ['@media (max-width:1600px)']: {
      margin: '-30% 0 0 0',
    },
    ['@media (max-width:1260px)']: {
      margin: '-25% 0 0 0',
      width: '250px',
    },
  },
  divProyectosMobile:{
    position: 'absolute',
    top: '5%',
    left: '8%',     
  },
  ubicacionComponentE1:{
    margin: '10px 0 0 17px',
    position: 'relative',
  },
  ubicacionComponentE1Mobile:{
    top: '108%',
    left: '5%',
    position: 'absolute',
  },
  ubicacionComponentE2:{
    margin: '20px 0 0 17px',
    position: 'relative',
  },
  ubicacionComponentE2Mobile:{
    top: '120%',
    left: '5%',
    position: 'absolute',
  },

  ubicacionComponentE3:{
    margin: '20px 0 0 17px',
    position: 'relative',
  },
  ubicacionComponentE3Mobile:{
    top: '132%',
    left: '5%',
    position: 'absolute',
  },
  sizeComponent:{
    width: '50px',
  },
  sizeComponentMobile:{
    width: '50%',
  },
  ubicacionRedes1:{
    marginTop:'28vh',
    position:'absolute',
    width:'43.5em', 
  },
  ubicacionRedes2:{
    position:'relative',
    margin:'1% 0 0 0',
  },
  ubicacionRedes3:{
    position:'relative',
    width: '4%',
    height:'3.5em',
  },
  ubicacionRedes4:{
    position:'relative',
    width: '4%',
    height:'3.5em',
  },
  ubicacionRedes5:{
    position:'relative',
    width: '4%',
    height:'3.5em',
  },
  divRedesMobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '85em',
    position: 'relative', 
  },
  ubicacionRedesMobile:{    
    
  },
  sizeFacebook:{
    width: '0.8em',
  },
  sizeFacebookMobile:{
    width: '1em',
  },
  sizeRedes:{
    width: '8%',
  },
  sizeRedesMobile:{
    width: '',
  },
  sizeRedesMobile2:{
    width: '1.7em',
  },
  divFondo3:{
    position: 'relative',
  },
  btnVerMas:{
    border:'2px solid #a3b4c3',
    color:'white',
    width:'165px',
  },
  btnVerMasMobile:{
    border:'2px solid #FFFFFF',
    color:'white',
    width: '150px',
    fontFamily: 'proxima-nova',
    ['@media (max-width:650px)']: {
      width: '100px',
      fontFamily: 'proxima-nova',
    },
  },
  divForm:{
    width:'350px',
    borderRadius: '15px',
    top: '460px',
    left: '55%',
    backgroundColor:'#FFFFFF',
    position: 'absolute',
    ['@media (max-width:1600px)']: {
      top: '360px'
    },
    ['@media (max-width:1260px)']: {
      top: '21vw'
    },
  },
  divFormMobile:{
    width:'90%',
    borderRadius: '15px',
    top: '150%',
    left: '5%',
    backgroundColor:'#FFFFFF',
    position: 'absolute',
  },
  divEnviar:{
    width: '90%',
  },
  btnEnviar:{
    backgroundColor:'#EFC94C',
    padding: '0.5em 2em',
    textTransform:'capitalize',
    color: '#334D5C',
    fontFamily: 'proxima-nova',
  },
  boxModal1:{
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#111D2F',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '24',p: '4',  
  },
  
  divModal:{
    top: '20%',
    left:'10%',
    position: 'absolute',
  },
  divFigura:{
    position: 'absolute',
    width: '44%',
    margin:'1.3% 0 0 72.5%',   
    ['@media (max-width:1209px)']: {
      margin:'14% 0 0 72.5%',   
    },
  },

  figuraModal1:{
    width: '67.6%',
  },
  divBtnRojo:{
    width: '100%',
    position: 'absolute',
    top: '85%',

  }, 
  divBtnRojoMobile:{
    width: '90%',
    maxWidth: '450px',
    top: '85%',
    position: 'absolute',
    height: '70px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '10px',
    left: '0',
    right: '0',
    margin: 'auto',
    backdropFilter: 'blur(8px)'
  },
  divBtnRojo2:{
    width: '34em',
    height: '2em',
    left: '0',
    right: '0',
    margin: 'auto'
  },

  divBtnRojo3:{
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo3Mobile:{
    position: 'absolute',
  },
  divBtnRojo4:{
    position: 'absolute',
  },

  BtnRojo1:{
    width: '3em',
  },
  BtnRojo1R:{
    width: '2.5em',
    marginLeft: '5px',
    marginTop: '5px'
  },
  BtnRojo1Mobile:{
    width: '3em',
    height:'3em',
  },
  iconBtn:{
    width: '1em',
    height:'1em',
  },
  iconBtn2:{
    
  },
  iconBtn2Desktop:{
    width: '4.5em',
    height:'4.5em',  
    marginTop: '-6px',
    cursor: 'pointer',
    borderRadius: '50%',
    ['@media (max-width:1260px)']: {
      width: '3.5em',
      height:'3.5em',
    },
  },

  btn1Desktop:{
    position: 'absolute',
    top: '75%',
    margin: 'auto',
    left: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
  },
  btn1Desktop2:{
    position: 'absolute',
    margin: '-5.3em 0 0 3.8em',
  },
  btn1Desktop3:{
    position: 'absolute',
    margin: '-5.3em 0 0 3.1em',
  },
  btn1Desktop4:{
    position: 'absolute',
    margin: '-5.3em 0 0 3.5em',
  },
  btn1Desktop5:{
    position: 'absolute',
    margin: '-5.3em 0 0 3.2em',
  },
  btn1Desktop6:{
    position: 'absolute',
    margin: '-5.3em 0 0 3.2em',
  },
  divBtnRojo5:{
    margin: '0em 0 0 6em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo5Mobile:{
    position: 'absolute',
  },
  divBtnRojo6:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  divBtnRojo6Mobile:{
    position: 'absolute',
  },
  BtnRojo2:{
    width: '3em',
    cursor: 'pointer'
  },
  BtnRojo2Mobile:{
    width: '2.3em',
    height: '2.3em'
  },
  iconBtn3:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -0.2em',
  },
  divBtnRojo7:{
    margin: '0em 0 0 12em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo7Mobile:{
    margin: '0em 0 0 8em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo8:{
    margin: '0% 0 0 0em',
    position: 'absolute',
  },
  divBtnRojo8Mobile:{
    margin: '0% 0 0 0em',
    position: 'absolute',
  },
  BtnRojo3:{
    width: '3em',
  },
  iconBtn4:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -0.2em',
  },
  divBtnRojo9:{
    margin: '0em 0 0 18em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo9Mobile:{
    margin: '0em 0 0 12em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo10:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  divBtnRojo10Mobile:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  BtnRojo4:{
    width: '3em',
  },
  iconBtn5:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -0.2em',
  },
  divBtnRojo11:{
    margin: '0em 0 0 24em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo11Mobile:{
    margin: '0em 0 0 16em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo12:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  divBtnRojo12Mobile:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  BtnRojo5:{
    width: '3em',
  },
  iconBtn6:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -0.2em',
  },
  divBtnRojo13:{
    margin: '0em 0 0 30em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo13Mobile:{
    margin: '0em 0 0 20em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo14:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  divBtnRojo14Mobile:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  BtnRojo6:{
    width: '3em',
  },
  iconBtn7:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -0.2em',
  },
  boxModal2:{
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#111D2F',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: '4',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  boxModal2Mobile:{
    width: '93%',
    height: '50%',
    position: 'absolute',
    backgroundColor: '#182434',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: 'auto',
    p: '4',
    borderTopLeftRadius: '20px 20px',
    borderBottomRightRadius: '20px 20px',
    borderBottomLeftRadius: '20px 20px',
    overflowY: 'scroll',
    overflowX: 'hidden',

  },
  divBtnRojo15:{
    margin: '0em 0 0 1em',
    backgroundColor: 'White',
    width: '2em',
    height:'1.8em',
    borderBottomLeftRadius: '10px 10px',
  },
  divBtnRojo15Mobile:{
    margin: '0em 0 0 1em',
    backgroundColor: 'White',
    width: '2em',
    height:'1.8em',
    borderBottomLeftRadius: '10px 10px',
  },
  iconBtn8:{
    width: '1em',
    height:'1em',
  },
  divBtnRojo16:{
    top: '25%',
    left:'50%',
    position: 'absolute',
    width: '45%',
    ['@media (max-width:1400px)']: {
      left: '39%'
    },
  },
  divBtnRojoMD:{
    top: '25%',
    left:'50%',
    position: 'absolute',
    width: '45%',
    ['@media (max-width:1200px)']: {
      left: '39%'
    },
  },
  divBtnRojo16Mobile:{
    margin: '5% 0 0 12%',
    width: '75%',
    height:'20em',
  },
  divBtnRojo17:{
    position: 'absolute',
    margin:'-2% 0 0 0%',
    left: '-5vw',
    ['@media (max-width:1260px)']: {
      left: '-15vw'
    },
    ['@media (max-width:1160px)']: {
      left: '-30vw'
    },
  },
  divBtnRojo18:{
  },
  imagenModal3:{
    width: '110%',
    ['@media (max-width:1260px)']: {
      width: '65%',
    },
  },
  divBtnRojo19:{
    width: '100%',
    position: 'absolute',
    top: '85%',
    ['@media (max-width:1400px)']: {
      top: '90%'
    },
  },
  divBtnRojo20:{
    width: '34em',
  },
  divBtnRojo21:{
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo22:{
    position: 'absolute',
  },
  iconBtn9:{
    width: '3em',
    cursor:'pointer'
  },
  iconBtn10:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -1.7em',
  },
  divBtnRojo23:{
    margin: '0em 0 0 6em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo24:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  iconBtn11:{
    width: '3em',
  },
  iconBtn12:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -1.6em',
  },
  divBtnRojo25:{
    margin: '0em 0 0 12em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo26:{
    margin: '0% 0 0 0em',
    position: 'absolute',
  },
  iconBtn13:{
    width: '3em',
  },
  iconBtn14:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -1.7em',
  },
  divBtnRojo27:{
    margin: '0em 0 0 18em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo28:{
    margin: '0em 0 0 0em',
    position: 'absolute',   
  },
  iconBtn15:{
    width: '3em',
  },
  iconBtn16:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -1.7em',
  },
  divBtnRojo29:{
    margin: '0em 0 0 24em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo30:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  iconBtn17:{
    width: '3em',
  },
  iconBtn18:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -1.7em',
  },
  divBtnRojo31:{
    margin: '0em 0 0 30em',
    float:'left',
    position: 'absolute',
    width: '5%',
  },
  divBtnRojo32:{
    margin: '0em 0 0 0em',
    position: 'absolute',
  },
  iconBtn19:{
    width: '3em',
  },
  iconBtn20:{
    width: '2.3em',
    height:'2.3em',
    backgroundColor: 'inherit',
    margin:'-0.3em 0 0 -1.7em',
  },
  divBtnRojo33:{
    width:'45%',
    borderRadius: '15px',
    margin: '0% 0 0 45%',
    backgroundColor:'#FFFFFF',
    position: 'absolute',
  },
  divBtnRojo34:{
    width: '90%',
  },
  btnEnviar2:{
    backgroundColor:'#DBA901',
    textTransform:'capitalize',
    fontFamily: 'proxima-nova',
  },
  boxModal3:{
    width: '97%',
    height: '97%',
    position: 'absolute',
    backgroundColor: '#071019',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: '24',
    p: '4',
  },
  divBtnRojo35:{
    margin: '0em 0 0 1em',
    backgroundColor: 'White',
    width: '2em',height:'1.8em',
    borderBottomLeftRadius: '10px 10px',
  },
  divBtnRojo36:{
    margin: '4% 0 0 3%',
  },
  iconBtn21:{
    width: '1em',
    height:'1em',
  },
  divBtnRojo37:{
    position: 'absolute',
  },
  divBtnRojo38:{
    width: '40%',
    margin: '-19% 0 0 60%',
  },
  divBtnRojo39:{
    margin: '1.5em 0 0 4.5em',
    position: 'absolute',
    width: '13em',
  },
  divBtnRojo39Mobile:{
    margin: '0 0 0 3.5em',
    position: 'absolute',
    width: '10em',
  },
  divBtnRojo40:{
    margin: '1em 0 0 4.5em',
    position: 'absolute',
    width: '17em',
  },
  divBtnRojo40Mobile:{
    margin: '0.5em 0 0 3.5em',
    position: 'absolute',
    width: '13em',
  },
  divBtnRojo41:{
    margin: '0em 0 0 4.5em',
    position: 'absolute',
    width: '400px',
  },
  divBtnRojo41Mobile:{
    margin: '-5px 0 0 3.5em',
    position: 'absolute',
    width: '80vw',
  },

  cortarMobile:{
    overflow: 'hidden',
  },
  divEspacioMobile:{
    padding: '5%',
    margin: '-7% 0 0 3%',
  },
  divEspacioMobile2:{
    padding: '1%',
    position: 'absolute',
  },
  DivEspacioMobile3:{
    position: 'absolute',
    top: '95%',
    left: '5.5%',
    padding: '1%',
    width: '30%',
  },
  DivEspacioDesktop:{ 
    marginTop:'-4vh',
    marginLeft:'0.5em',
    padding: '1em',
    width:'87em',
  },
  DivEspacioHablemos:{
    margin: '5em 0 0 0em',
    padding: '1em',
    width:'87em',
  },
  DivEspacioDesktop2:{
    width:'100%',
    margin: '-4.8% 0 0 2%',
    padding: '1em',
  },
  BotonAmarillo1:{
    top: '-0.6em',
    left: '-0.5em',
    position: 'absolute',
  },
  BotonAmarillo2:{
    top: '-0.6em',
    left: '-0.5em',
    position: 'absolute',
  },
  BotonAmarillo3:{
    top: '-0.6em',
    left: '-0.5em',
    position: 'absolute',
  },
  BotonAmarillo4:{
    top: '-0.6em',
    left: '-0.5em',
    position: 'absolute',
  },
  BotonAmarillo5:{
    top: '-0.6em',
    left: '-0.5em',
    position: 'absolute',
  },
  BotonAmarillo6:{
    top: '-0.6em',
    left: '-0.5em',
    position: 'absolute',
  },

  animacionTexto1:{
    position: 'absolute',
    marginLeft:'71em',
    //top: '42%',
    top: '44vh',
    ['@media (max-height:620px)']: {
      top: '260px',
    },
  },
  animacionTexto2:{
    position: 'absolute',
    width: '44%',
    left:'44%',
    top: '49%',
  },
  animacionTexto3:{
    width: '75%',
    position: 'absolute',
    left:'44%',
    top: '49%', 
  },
  lineaDorada2:{
    left: '-0.5em',
    top: '14.8em',
    height:'0.1em',
    position: 'absolute',
    width: '13em',
    ['@media (max-width:1209px)']: {
      top: '12em',
      width: '10em',
      left: '3em'

    },
  },
  lineaDorada3:{
    height:'2em',
    width: '8em',
  },
  lineaDoradaMobile:{
    width: '80%',
    position: 'relative',
    top: '87%',
    marginBottom: '1em'
  },
  SelectLanguage:{

  },
  SelectLanguage2:{
    border: '1px solid #1f3251',
    backgroundColor:'#111D2F',
    "& ul": {
    padding: 0,
    backgroundColor:'#111D2F'
    },

  },

  SelectLanguageMobile:{ 
	  right: '50px',
    position:'absolute',
    top: '25px',

  },
  SelectLanguage2Mobile:{
    border: '1px solid #1f3251',
    backgroundColor: '#1f3251',
    width: '100px',
    borderRadius: '5px',
  },
  LabelComponent:{
    left:'10%',
    position:'absolute',
    fontSize: '14px',
    color: '#6f828c',
    fontFamily: 'proxima-nova',
  },
  cortarDesktop:{
    overflow: 'hidden', 
  },
  ubicacionTypography:{
    marginTop:'-0.5em',
    marginLeft:'13.3em', 
  },
  sizeTypography:{
    fontSize:'19px',
    color:'white', 
    marginTop: '-1em',
    fontFamily: 'proxima-nova',
  },
  espacioRedes:{
    
  },
  sizeTypography2:{
    fontSize:'18px',
    color:'white', 
    fontFamily: 'proxima-nova',
    ['@media (max-width:1230px)']: {
      width: '90vw',
      fontSize: '16px',
      fontFamily: 'proxima-nova',
    },
  },
  sizePersonaje2:{
    width: '160px',
    ['@media (max-width:1260px)']: {
      width: '130px'
    },
  },
  xCerrar:{
    margin:'0.7% 0 0 20.2%',
    width:'100%',
  },
  typographyCerrar:{
    margin:'0.1em 0 0 0.7em',
    position:'absolute',
    fontSize:'25px', 
    color:'white',
    fontWeight: 'bold',
  },
  typographyTitle:{
    fontSize:'64px',
    color:'white',
    fontWeight: 'bold',
    fontFamily: 'proxima-nova',
  },
  imageSize:{
    width: '50px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer'
  },
  ubicacionAnimated:{
    margin: '-4em 0 0 -5em',
    padding: '1em',
    width:'50em',
  },
  ubicacionAnimated2:{
    width:'50em',
    top: '17.5%',
    left:'10%',
    position: 'absolute',
  },
  ubicacionAnimated3:{
    width:'100%',
    margin: '-2% 0 0 2%',
    padding: '1em',
  },
  ubicacionAnimated4:{
    margin: '-4em 0 0 -5em',
    padding: '1em',
    width:'50em', 
  },
  ubicacionFiguraModal6:{
    margin:'-3% 0 0 0%',
  },
  sizeFigura6:{
    width: '30%', 
  },
  ubicacionAnimated5:{
    margin:'-2% 0 0 0%',
    top: '20%',
    left:'10%',
    position: 'absolute',
  },
  ubicacionAnimated6:{
    width:'50em',
    margin: '-0% 0 0 2%',
    padding: '1em',
  },
  ubicacionAnimated7:{
    margin:'-1.6% 0 0 170%',
    width:'100%',
  },
  ubicacionAnimated8:{
    width:'100%', 
  },
  sizeFigura4:{
    margin:'-3% 0 0 1%',
    width: '34.8%',
    ['@media (max-width:1209px)']: {     
      width: '28%',
      marginLeft: '10vw'
    },
  },
  ubicacionAnimated9:{
    width:'100%',
    top: '13%',
    left:'11%',
    position: 'absolute',
 
  },
  ubicacionAnimated10:{
    margin: '0% 0 0 34%',
    padding: '1em',
    width:'50em',
    ['@media (max-width:1260px)']: {     
      margin: '0% 0 0 20%',
    },
  },
  ubicacionAnimated11:{
    margin:'-1.5% 0 0 -3%',
    width:'100%', 
  },
  ubicacionAnimated12:{
    width:'100%', 
  },
  ubicacionTypography2:{
    marginLeft:'3%',
    marginTop:'-5%',
    width:'100%',
  },
  sizeVerMas:{
    fontSize:'20px',
    fontFamily: 'proxima-nova',
  },
  SelectLanguage3:{
    position: 'absolute',
    top: '25px',
    right: '50px',
    ['@media (max-width:1209px)']: {     
    },
  },
  borderRedes:{
    cursor: 'pointer',
    width: '35px'


  },
  borderRedesM:{
    cursor: 'pointer',
    width: '35px'


  },
  borderRedesMF:{
    cursor: 'pointer',
    width: '35px'


  },
  borderRedesAbajo:{
    cursor: 'pointer',
    width: '35px'


  },
  borderRedesAbajoF:{
    cursor: 'pointer',
    width: '35px'


  },
  borderRedesF:{
    cursor: 'pointer',
    width: '35px'
  },
  centrarDiv: {
    top: '7em',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    height: '5%',
  },
  divExtio: {
    backgroundColor: '#D3E9D0',
    height: '7em',
    width: '30%',
    position: 'absolute',
    top: '87%',
    left: '23%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'

  },
  divExtioMobile: {
    backgroundColor: '#D3E9D0',
    height: '5em',
    width: '90%',
    position: 'absolute',
    top: '81em',
    left: '5%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'

  },
  divError: {
    backgroundColor: '#ff9090',
    height: '7em',
    width: '30%',
    position: 'absolute',
    top: '87%',
    left: '23%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'

  },
  divErrorMobile: {
    backgroundColor: '#ff9090',
    height: '5em',
    width: '90%',
    position: 'absolute',
    top: '81em',
    left: '5%',
    border: '3px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center'

  },
  error: {
    color: 'red',
    position: 'absolute',
    fontFamily: 'proxima-nova',
  },
  exito: {
    color: 'green',
    position: 'relative',
    marginLeft:'0.5em',
    fontFamily: 'proxima-nova',
  },


}));

export default function InicioPage() {
  console.log = console.warn = console.error = () => {};


  const correoRef = React.useRef();
  const nombreRef = React.useRef();
  const mensajeRef = React.useRef();
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  const [errorFormulario, cambiarErrorFormulario] = useState(false);
  const [errorMessage, setError] = useState([]); 


  function refreshPage() {
    window.location.reload(false);
  }


  function enviarUser(){
    console.log(nombreRef.current.value)
    const data = {  
      contacto:
      {        
          nombre: nombreRef.current.value,                 
          correo: correoRef.current.value,          
          mensaje: mensajeRef.current.value,        
      }               
    };
    axios.post('https://back.innovahub.org/main/contacto', data)
    .then((response) => {
      console.log(response.data);
      cambiarFormularioEnviado(true);
      setTimeout(() => refreshPage(), 5000);
      
    })    
    .catch((error) => {
      console.log(error);
      setError(error.response.data.message)
      cambiarErrorFormulario(true);
      setTimeout(() => cambiarErrorFormulario(false), 5000);
    });
  }

  
  let lang;  
  const [age, setAge] = React.useState(localStorage.getItem("lang"));
  const handleChange = (event) => {
    setAge(event.target.value);
    localStorage.setItem("lang",event.target.value)
    window.location.reload()
  };

  //modal    
  const handleOpen1Mobile = () => {
    setOpen1Mobile(true);
  };
  const handleOpen2Mobile = () => {
    setOpen2Mobile(true);
  };
  const handleOpen3Mobile = () => {
    setOpen3Mobile(true);
  };
  const handleOpen4Mobile = () => {
    setOpen4Mobile(true);
  };
  const handleOpen5Mobile = () => {
    setOpen5Mobile(true);
  };
  const handleOpen6Mobile = () => {
    setOpen6Mobile(true);
  };
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleOpen5 = () => {
    setOpen5(true);
  };
  const handleOpen6 = () => {
    setOpen6(true);
  };
  const handleClose1Mobile = () => {
    setOpen1Mobile(false);
  };
  const handleClose2Mobile = () => {
    setOpen2Mobile(false);
  };
  const handleClose3Mobile = () => {
    setOpen3Mobile(false);
  };
  const handleClose4Mobile = () => {
    setOpen4Mobile(false);
  };
  const handleClose5Mobile = () => {
    setOpen5Mobile(false);
  };
  const handleClose6Mobile = () => {
    setOpen6Mobile(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const handleClose6 = () => {
    setOpen6(false);
  };
  //
  //animaciones
  const [state2] = React.useState(true);  
  const { x } = useSpring({ from: { x: 0 }, x: state2 ? 1 : 0, config: { duration: 700 } });
  const [state3, toggle2] = React.useState(false);
  const { y } = useSpring({ from: { y: 0 }, y: state3 ? 1 : 0, config: { duration: 700 } });
  const [state4, toggle4] = React.useState(false);
  const { a } = useSpring({ from: { a: 0 }, a: state4 ? 1 : 0, config: { duration: 700 } });
  const [state5, toggle5] = React.useState(false);
  const { b } = useSpring({ from: { b: 0 }, b: state5 ? 1 : 0, config: { duration: 700 } });
  const [state6, toggle6] = React.useState(false);
  const { c } = useSpring({ from: { c: 0 }, c: state6 ? 1 : 0, config: { duration: 700 } });
  const [state7, toggle7] = React.useState(false);
  const { d } = useSpring({ from: { d: 0 }, d: state7 ? 1 : 0, config: { duration: 700 } });
  const [state8, toggle8] = React.useState(false);
  const { e } = useSpring({ from: { e: 0 }, e: state8 ? 1 : 0, config: { duration: 700 } });

  const animacion = () => {
    toggle2(true);
  };
  const closeAnimacion = () => {
    toggle2(false);
  };
  const animacion4 = () => {
    toggle4(true);
  };
  const closeAnimacion4 = () => {
    toggle4(false);
  };
  const animacion5 = () => {
    toggle5(true);
  };
  const closeAnimacion5 = () => {
    toggle5(false);
  };
  const animacion6 = () => {
    toggle6(true);
  };
  const closeAnimacion6 = () => {
    toggle6(false);
  };
  const animacion7 = () => {
    toggle7(true);
  };
  const closeAnimacion7 = () => {
    toggle7(false);
  };
  const animacion8 = () => {
    toggle8(true);
  };
  const closeAnimacion8 = () => {
    toggle8(false);
  };
  //

  const [spacing] = React.useState(2);
  const [state, setState] = React.useState({
    mobileView: false,
  });
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);
  const [open1Mobile, setOpen1Mobile] = React.useState(false);
  const [open2Mobile, setOpen2Mobile] = React.useState(false);
  const [open3Mobile, setOpen3Mobile] = React.useState(false);
  const [open4Mobile, setOpen4Mobile] = React.useState(false);
  const [open5Mobile, setOpen5Mobile] = React.useState(false);
  const [open6Mobile, setOpen6Mobile] = React.useState(false);
  const { mobileView } = state;
  const classes = useStyles();

  

  function componentDidMount() {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
      if (localStorage.getItem('lang') == undefined){
        localStorage.setItem('lang', 'es')
      }
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  React.useEffect(()=>{
    componentDidMount();
  }, [])

  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const isMobile = window.screen.width < 1314

  const displayMobile = () => { 

    return (
      <div className={classes.cortarMobile} >
        <CssBaseline />  
           
         <div className={classes.fondo1Mobile} >
         
         <div className={classes.centrarDiv}>                    
            <animated.div
              style={{
                transition: 'all .5s linear',    
                scale: x.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                transform: x
                .interpolate({
                  range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                  output: [0.97, 0.9, 1.1, 1.03],
                })
              }}
            >              
              <div className={classes.div1Mobile}>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography style={{fontSize:'25px',color:'white', fontWeight: 'bold',fontFamily: 'proxima-nova',}} variant="h5" component="h2"  >
                  <FormattedMessage id = "InicioPage1" defaultMessage="Transformamos"></FormattedMessage><Box component="span" className={classes.spanDesktop}> <FormattedMessage id = "InicioPage2" defaultMessage="buenas"></FormattedMessage></Box> 
                </Typography>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography style={{fontSize:'25px',color:'white',fontWeight: 'bold',fontFamily: 'proxima-nova',}} variant="h5" component="h2"  >
                  <Box component="span" className={classes.spanDesktop}><FormattedMessage id = "InicioPage3" defaultMessage="ideas"></FormattedMessage></Box> <FormattedMessage id = "InicioPage4" defaultMessage="en buenas prácticas."></FormattedMessage>
                </Typography>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Typography style={{fontSize:'25px',color:'white',fontWeight: 'bold',fontFamily: 'proxima-nova',}} variant="h5" component="h2"  >
                  <FormattedMessage id = "InicioPage5" defaultMessage="Te contamos cómo..." />
                </Typography>
              </div>
              </div>
              
            </animated.div>     
            </div>    
            <div>
                  <Grid container justifyContent="center" spacing={spacing}>
                    {[2].map((value) => (
                      <Grid key={value} item>                               
                        <animated.div
                          style={{
                            transitionDelay: '2s',
                            opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                          }}
                        >
                          <div align="center" className={classes.div1Mobile2}>
                            <img src={lineaDoradaMobile} className={classes.lineaDoradaMobile} alt="logo" />
                            <Typography variant="body2" component="h2" style={{fontSize: '12px',fontFamily: 'proxima-nova', marginBottom: '30px'}} className={classes.typographyMetodologiaMobile}>
                              <FormattedMessage id = "InicioPage6" defaultMessage="Consultoría en gestión de conocimiento e innovación" />
                            </Typography> 
                            <img style={{position:'relative', left: '0', right: '0', margin: 'auto', width: '40px'  }} src={flechaAbajo}></img><br></br><br></br>    
                          </div>
                        </animated.div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
          </div>
                      
          <CssBaseline />
            <div className={classes.fondo2Mobile} >
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.divMetodologia}>
                    <Grid container justifyContent="stretch" spacing={spacing}>
                      {[2].map((value) => (
                        <Grid key={value} item>                       
                          <div className={classes.divEspacioMobile}>
                            <animated.div
                              style={{
                                transition: 'all 5s linear',
                                opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                              }}
                            >
                              <img  style={{position: 'absolute', width:'6px', left: '3%', marginTop: '16px'}} src={puntos} alt="" />
                              <div className={classes.ubicacionPuntosMobile}>
                              <Typography variant="h4" component="h2" style={{marginLeft: '15px'}} className={classes.typographyMetodologiaM} >
                                <FormattedMessage id = "InicioPage7" defaultMessage="Metodología" /> 
                              </Typography><br></br>                              
                              <Typography variant="h4" component="h2" style={{marginLeft: '15px'}} className={classes.typographyMetodologiaM} > 
                                <FormattedMessage id = "InicioPage8" defaultMessage="InnovaHub" />
                              </Typography>
                              </div>
                            </animated.div>
                            <animated.div
                              style={{
                                transition: 'all 5.3s linear',
                                opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                              }}
                            >
                              <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMP} >         
                                <FormattedMessage id = "InicioPage9" defaultMessage="En seis pasos comprendemos a las personas, sus contextos, diseñamos una intervención, (juego, curso, campamento, etc) la implementamos, monitoreamos y evaluamos de manera que se logren cambios a nivel institucional/organizacional." />
                              </Typography>
                            </animated.div>
                          </div>
                        </Grid>
                      ))}
                      <Grid container justifyContent="center" spacing={spacing}>          
                        <animated.div
                          style={{
                            transition: 'all 5.6s linear',
                            opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                          }}
                        >
                          <div className={classes.divImagenes} >
                            <img className={classes.sizev9EditMobile} src={v9Edit} alt="" />
                          </div> 
                        </animated.div>
                        <div >
                          <div align="center" className={classes.divBtnRojoMobile} >                              
                                <div >
                                  <img src={Rojo1} alt="Rojo1" className={classes.BtnRojo1Mobile} onClick={handleOpen1Mobile} />
                                </div>                              
                                <div  >
                                  <img src={Rojo2}  alt="Rojo2" className={classes.BtnRojo1Mobile } onClick={handleOpen2Mobile} />
                                </div>                                
                                <div  >
                                  <img src={Rojo3}  alt="Rojo3" className={classes.BtnRojo1Mobile} onClick={handleOpen3Mobile}/>
                                </div>                                
                                <div  >
                                  <img src={Rojo4}  alt="Rojo4" className={classes.BtnRojo1Mobile} onClick={handleOpen4Mobile} />
                                </div>                                
                                <div>
                                  <img src={Rojo5}  alt="Rojo5" className={classes.BtnRojo1Mobile} onClick={handleOpen5Mobile}  />
                                </div>                              
                                <div >
                                  <img src={Rojo6}  alt="Rojo6" className={classes.BtnRojo1Mobile} onClick={handleOpen6Mobile}  />
                                </div>
                               
                          </div>
                          </div>
                        <div >
                          <Modal 
                            open={open6Mobile}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            hideBackdrop
                          >
                            <Box  className={classes.boxModal2Mobile} >
                              <div>
                              <div align="right" >
                                <img style={{width: '35px', right: '8px', top:'8px', position: 'absolute'}} onClick={handleClose6Mobile} src={cerrarRojo}  alt="Rojo4" />
                                </div>
                              </div>
                              <div>
                                <div className={classes.divBtnRojo16Mobile} >
                                  <Typography variant="h5" style={{fontWeight: 'bold'}} component="h2" className={classes.typographyMetodologia}>
                                    <FormattedMessage id = "InicioPage38" defaultMessage="modal 6" />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage39" defaultMessage="Partimos de un ejercicio de análisis prospectivo, para responder con claridad a la pregunta ¿para que?. Aquí, analizamos las necesidades reales de la organización, gobierno o entidad y construimos de manera colectiva los puertos de llegada de la intervención que se va a realizar. De esta manera hacemos un diagnóstico, que permite analizar en dónde estamos hoy y que habilidades las personas deben desarrollar para el futuro planteado." />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" style={{fontStyle:'italic',}}   className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage40" defaultMessage="'En esta etapa emerge la búsqueda real.. las aspiraciones y problemas que deben solucionarse'" />
                                  </Typography><br></br><br></br>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                        <div >
                          <Modal 
                            open={open5Mobile}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            hideBackdrop
                          >
                            <Box className={classes.boxModal2Mobile} >

                              <div>
                              <div align="right" >
                                <img style={{width: '35px', right: '8px', top:'8px', position: 'absolute'}} onClick={handleClose5Mobile} src={cerrarRojo}  alt="Rojo4" />
                                </div>
                              </div>
                              <div>
                                <div className={classes.divBtnRojo16Mobile} >
                                  <Typography variant="h5"  style={{fontWeight: 'bold'}} component="h2" className={classes.typographyMetodologia}> 
                                    <FormattedMessage id = "InicioPage35" defaultMessage="modal 5" />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage36" defaultMessage="Partimos de un ejercicio de análisis prospectivo, para responder con claridad a la pregunta ¿para que?. Aquí, analizamos las necesidades reales de la organización, gobierno o entidad y construimos de manera colectiva los puertos de llegada de la intervención que se va a realizar. De esta manera hacemos un diagnóstico, que permite analizar en dónde estamos hoy y que habilidades las personas deben desarrollar para el futuro planteado." />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage37" defaultMessage="'En esta etapa emerge la búsqueda real.. las aspiraciones y problemas que deben solucionarse'" />                                    
                                  </Typography><br></br><br></br>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                        <div >
                          <Modal 
                            open={open4Mobile}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            hideBackdrop
                          >
                            <Box className={classes.boxModal2Mobile} >

                              <div>
                              <div align="right" >
                                <img style={{width: '35px', right: '8px', top:'8px', position: 'absolute'}} onClick={handleClose4Mobile} src={cerrarRojo}  alt="Rojo4" />
                                </div>
                              </div>
                              <div>
                                <div className={classes.divBtnRojo16Mobile} >
                                  <Typography variant="h5" component="h2" style={{fontWeight: 'bold'}} className={classes.typographyMetodologia}>
                                    <FormattedMessage id = "InicioPage32" defaultMessage="modal 4" />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage33" defaultMessage="Partimos de un ejercicio de análisis prospectivo, para responder con claridad a la pregunta ¿para que?. Aquí, analizamos las necesidades reales de la organización, gobierno o entidad y construimos de manera colectiva los puertos de llegada de la intervención que se va a realizar. De esta manera hacemos un diagnóstico, que permite analizar en dónde estamos hoy y que habilidades las personas deben desarrollar para el futuro planteado." />                                   
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage34" defaultMessage="'En esta etapa emerge la búsqueda real.. las aspiraciones y problemas que deben solucionarse'" />
                                  </Typography><br></br><br></br>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                        <div >
                          <Modal 
                            open={open3Mobile}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            hideBackdrop
                          >
                            <Box className={classes.boxModal2Mobile} >

                              <div>
                              <div align="right" >
                                <img style={{width: '35px', right: '8px', top:'8px', position: 'absolute'}} onClick={handleClose3Mobile} src={cerrarRojo}  alt="Rojo4" />
                                </div>
                              </div>
                              <div>
                                <div className={classes.divBtnRojo16Mobile} >
                                  <Typography variant="h5"  style={{fontWeight: 'bold'}} component="h2" className={classes.typographyMetodologia}> 
                                    <FormattedMessage  id = "InicioPage29" defaultMessage="modal 3" />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage30" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas." />                                    
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage31" defaultMessage="''diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />
                                  </Typography><br></br><br></br>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>                  
                        <div >
                          <Modal 
                            open={open2Mobile}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            hideBackdrop
                          >
                            <Box className={classes.boxModal2Mobile} >

                              <div>
                              <div align="right" >
                                <img style={{width: '35px', right: '8px', top:'8px', position: 'absolute'}} onClick={handleClose2Mobile} src={cerrarRojo}  alt="Rojo4" />
                                </div>
                              </div>
                              <div>
                                <div className={classes.divBtnRojo16Mobile} >
                                  <Typography variant="h5" style={{fontWeight: 'bold'}} component="h2" className={classes.typographyMetodologia}> 
                                    <FormattedMessage id = "InicioPage26" defaultMessage="2. Analisis Espectral" />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage27" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas." />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage28" defaultMessage="'diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />                                    
                                  </Typography><br></br><br></br>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                        <div >
                          <Modal 
                            open={open1Mobile}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            hideBackdrop
                          >
                            <Box className={classes.boxModal2Mobile} >

                              <div>
                                <div align="right" >
                                <img style={{width: '35px', right: '8px', top:'8px', position: 'absolute'}} onClick={handleClose1Mobile} src={cerrarRojo}  alt="Rojo4" />
                                </div>
                              </div>
                              <div>
                                <div className={classes.divBtnRojo16Mobile} >
                                  <Typography variant="h5" component="h2" style={{fontWeight: 'bold'}} className={classes.typographyMetodologia}> 
                                    <FormattedMessage id = "InicioPage16" defaultMessage="1. Estudio De Futuros" />
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage17" defaultMessage="Partimos de un ejercicio de análisis prospectivo, para responder con claridad a la pregunta ¿para que?. Aquí, analizamos las necesidades reales de la organización, gobierno o entidad y construimos de manera colectiva los puertos de llegada de la intervención que se va a realizar. De esta manera hacemos un diagnóstico, que permite analizar en dónde estamos hoy y que habilidades las personas deben desarrollar para el futuro planteado." />                                    
                                  </Typography><br></br>
                                  <Typography variant="body2" component="h2" style={{fontStyle:'italic',}} className={classes.typographyMetodologiaMobile}>
                                    <FormattedMessage id = "InicioPage18" defaultMessage="'En esta etapa emerge la búsqueda real.. las aspiraciones y problemas que deben solucionarse'" />
                                  </Typography><br></br><br></br>
                                </div>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
            
            <CssBaseline /> 
              <div className={classes.fondo3Mobile}>
                <div className={classes.lineaAmarilla} ></div>
                <div className={classes.divRedesMobile}> 
                              <div className={classes.ubicacionRedesMobile}>                                
                                <Box component="span"  ><a href="https://www.facebook.com/InnovaHubCol" target="_blank"><img src={facebook}  alt="facebook" className={classes.borderRedesMF}/></a>&nbsp;</Box>&nbsp;&nbsp;
                                <Box component="span"  >&nbsp;<a href=" https://www.linkedin.com/company/innovahubcol" target="_blank"><img src={linkedin} alt="linkedin"  className={classes.borderRedesM}/></a>&nbsp;</Box>&nbsp;&nbsp;
                                <Box component="span"  >&nbsp;<a href="https://www.instagram.com/innovahubcol/" target="_blank"><img src={instagram} alt="instagram" className={classes.borderRedesM}/></a>&nbsp;</Box>&nbsp;&nbsp;
                                <Box component="span"  >&nbsp;<a href="https://api.whatsapp.com/message/23YCTFPYVWDYD1" target="_blank"><img src={WhatsApp} alt="WhatsApp" className={classes.borderRedesM}/></a>&nbsp;</Box>
                              </div>
                              </div>        
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                      <div className={classes.divFondo3} >
                        <Grid container justifyContent="center" spacing={spacing}>
                          {[2].map((value) => (
                            <Grid key={value} item>
                              <div className={classes.divProyectosMobile}> 
                                <div className={classes.divEspacioMobile2} >
                                  <div className={classes.ubicacionPuntos2Mobile} >
                                    <img className={classes.sizePuntosMobile} src={puntos} alt="" />
                                  </div>
                                  <Typography variant="h5" component="h2" className={classes.typographyMetodologiaA} > 
                                    <FormattedMessage id = "InicioPageMobile19" defaultMessage="Conoce" />
                                  </Typography>
                                  <Typography variant="h5" component="h2" className={classes.typographyMetodologiaA} >
                                    <FormattedMessage id = "InicioPageMobile20" defaultMessage="nuestros" />
                                  </Typography>                              
                                  <Typography variant="h5" component="h2" className={classes.typographyMetodologiaA} > 
                                    <FormattedMessage id = "InicioPageMobile21" defaultMessage="proyectos" />
                                  </Typography><br></br>
                                  <Button variant="outlined" href="#/proyectos" className={classes.btnVerMasMobile} ><FormattedMessage id = "InicioPage43" defaultMessage="VER MÁS"></FormattedMessage></Button>
                                </div>
                              </div><br></br> 
                              <img className={classes.sizeTierraMobile} src={tierra} alt="" />    
                              <div className={classes.DivEspacioMobile3}>
                                <div className={classes.ubicacionPuntos3Mobile} >
                                  <img className={classes.sizePuntosMobile} src={puntos} alt="" />
                                </div> 
                                <Typography variant="h5" component="h2" style={{fontWeight: 'bold', color:'white',fontSize:'22px', fontFamily: 'proxima-nova',}}  > 
                                  <FormattedMessage id = "InicioPage44" defaultMessage="Hablemos" />
                                </Typography>
                              </div>


                              <div className={classes.ubicacionComponentE1Mobile}>
                                <div className={classes.divBtnRojo39Mobile} >
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}> 
                                  +57 321 410 1120 
                                  </Typography>    
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}> 
                                  +1 650 843 9153
                                  </Typography>                      
                                </div>
                                <img className={classes.sizeComponentMobile} src={ComponentE1} alt="" />
                              </div>


                              <div className={classes.ubicacionComponentE2Mobile} >
                                <div className={classes.divBtnRojo40Mobile} >
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}> 
                                  info@innovahub.org 
                                  </Typography>
                                </div>
                                <img className={classes.sizeComponentMobile} src={ComponentE2} alt="" />
                              </div>  


                              <div className={classes.ubicacionComponentE3Mobile} >
                                <div className={classes.divBtnRojo41Mobile } >
                                  <Typography variant="body2" component="h2" className={classes.typographyMetodologiaMobile}> 
                                    <FormattedMessage id = "InicioPage49" defaultMessage=" Bogotá/ Colombia Cra 65 # 169a 50" />
                                  </Typography>
                                
                                </div>                      
                                <img className={classes.sizeComponentMobile} src={ComponentE3} alt="" />
                              </div>                              
                              <div className={classes.divFormMobile}  >
                                <Box
                                  component="form"
                                  sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                ><br></br>
                                  <div align="center">
                                    <div align="left" className={classes.LabelComponent}><label><FormattedMessage id = "InicioPage45" defaultMessage="Nombre *"></FormattedMessage></label></div><br></br>
                                      <TextField style={{width: '80%'}} 
                                        inputRef={nombreRef}
                                        size="small" 
                                        required
                                        id="Nombre"
                                        variant="outlined"
                                      />
                                  </div><br></br>
                                  <div align="center">
                                    <div align="left" className={classes.LabelComponent}><label><FormattedMessage id = "InicioPage46" defaultMessage="Correo electrónico *"></FormattedMessage></label></div><br></br>
                                      <TextField style={{width: '80%'}}
                                        size="small"
                                        required
                                        inputRef={correoRef}
                                        id="email"
                                        variant="outlined"                                        
                                      />
                                  </div><br></br>
                                  <div align="center">
                                    <div align="left" className={classes.LabelComponent}><label><FormattedMessage id = "InicioPage47" defaultMessage="Mensaje *"></FormattedMessage></label></div><br></br>
                                      <TextField style={{width: '80%'}}
                                        required
                                        inputRef={mensajeRef}
                                        id="outlined-multiline-static"
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                      />
                                  </div><br></br>
                                  <div align="right" className={classes.divEnviar} >
                                    <Button size="medium" href="" className={classes.btnEnviar} onClick={enviarUser} ><FormattedMessage id = "InicioPage48" defaultMessage="Enviar Mensaje"></FormattedMessage></Button>
                                  </div><br></br>
                                </Box>                           
                              </div>
                              {formularioEnviado &&<div className={classes.divExtioMobile}> <Typography className={classes.exito}>Nuestro equipo te estará contactando para más información.</Typography> </div>}
                              { errorFormulario &&<div className={classes.divErrorMobile}><Typography className={classes.error}> {errorMessage} </Typography> </div> }

                              
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>       
                </div>          
              </div>       
            );
          }

const displayDesktop = () => {

  return (    
    <div className={classes.cortarDesktop}>
      <div align="center" className={classes.SelectLanguage3} >
                      <div className={classes.SelectLanguage} >          
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Age"
                          onChange={handleChange}
                          MenuProps={{ classes: { paper: classes.SelectLanguage2 } }}
                          style={{backgroundColor: '#1F3251', width: '100px', borderRadius:'5px'}}
                          
                        >
                          <MenuItem style={{margin: '0 0 0 0%',backgroundColor:'#111D2F', height: '35px'}} selected value={"es"}> <img src={Espanish} style={{width:'18px', height:'18px', position: 'absolute', left: '20%', borderRadius: '50%' }} alt="logo" /><Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',fontFamily: 'proxima-nova',}}>ES</Box></MenuItem>
                          <MenuItem style={{margin: '0% 0 0 0%',backgroundColor:'#111D2F',height: '35px'}} value={"pt"}> <img src={Portuguese} style={{width:'18px',height:'18px', position: 'absolute', left: '20%',borderRadius: '50%' }} alt="logo" /><Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',fontFamily: 'proxima-nova',}}>PT</Box></MenuItem>
                          <MenuItem style={{margin: '0 0 0 0%',backgroundColor:'#111D2F',height: '35px'}} value={"en"}> <img src={USA} style={{width:'18px',height:'18px', position: 'absolute', left: '20%',borderRadius: '50%' }} alt="logo" /> <Box component="span" style={{fontSize: '0.9em',color:'white', position: 'absolute', left: '50%',fontFamily: 'proxima-nova',}}>EN</Box> </MenuItem> 
                        </Select>     
                      </div>    
                    </div>      
      <div className={classes.fondo1Desktop}>     
        <CssBaseline />        
          <Container> 
            <Grid container className={classes.root} spacing={2}>           
              <Grid item xs={12}>
                <div className={classes.div1Desktop} >
                  <Grid container justifyContent="flex-end" spacing={spacing}>
                    {[2].map((value) => (
                      <Grid key={value} item>
                        <div className={classes.animacionTexto1} >
                          <animated.div
                            style={{
                              transition: 'all 0.3s linear',          
                              width: '50em',
                              marginLeft:'-45em',
                              translateX:'-71em',
                              

                              scale: x.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                              transform: x
                              .interpolate({
                                range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                output: [0.97, 0.9, 1.1, 1.03],
                              })
                            }}
                          >
                            <Typography variant="h4" component="h2" className={classes.typographyHome} >
                              <FormattedMessage id = "InicioPage1" defaultMessage="Transformamos"></FormattedMessage><Box component="span" className={classes.spanDesktop}> <FormattedMessage id = "InicioPage2" defaultMessage="buenas"></FormattedMessage></Box> 
                            </Typography>
                            <Typography variant="h4" component="h2" className={classes.typographyHome} > 
                              <Box component="span" className={classes.spanDesktop}><FormattedMessage id = "InicioPage3" defaultMessage="ideas"></FormattedMessage></Box> <FormattedMessage id = "InicioPage4" defaultMessage="en buenas prácticas."></FormattedMessage>
                            </Typography>
                            <Typography variant="h4" component="h2" className={classes.typographyHome}> 
                              <FormattedMessage id = "InicioPage5" defaultMessage="Te contamos cómo..." />                                               
                            </Typography>
                            
                            <animated.div
                              style={{
                                transitionDelay: '0.5s',
                                opacity: x.interpolate({ range: [0, 900], output: [0, 900] }),
                              }}
                            >
                              <div align="center" className={classes.lineaDorada3} >
                                <img src={lineaDorada} className={classes.lineaDorada2} alt="logo" />
                              </div>
                              <div className={classes.ubicacionTypography}>
                              <Typography component="h2" className={classes.sizeTypography}>
                                <FormattedMessage id = "InicioPage6" defaultMessage="Consultoría en gestión de conocimiento e innovación" />
                              </Typography>
                              </div>
                            </animated.div>
                            <animated.div
                              style={{
                                transitionDelay: '0.5s',
                                opacity: x.interpolate({ range: [0, 900], output: [0, 900] }),
                              }}
                            >
                              <div className={classes.ubicacionRedes1} align="right">
                                <Box component="span" ><a href="https://www.facebook.com/InnovaHubCol" target="_blank"><img src={facebook}  alt="facebook" className={classes.borderRedesF} /></a>&nbsp;</Box>
                                <Box className={classes.espacioRedes} component="span">&nbsp;<a href="https://www.linkedin.com/company/innovahubcol" target="_blank"><img src={linkedin} className={classes.borderRedes} alt="linkedin" /></a>&nbsp;</Box>
                                <Box className={classes.espacioRedes} component="span">&nbsp;<a href="https://www.instagram.com/innovahubcol/" target="_blank"><img src={instagram} className={classes.borderRedes} alt="instagram" /></a>&nbsp;</Box>                                                  
                                <Box className={classes.espacioRedes} component="span">&nbsp;<a href="https://api.whatsapp.com/message/23YCTFPYVWDYD1" target="_blank"><img src={WhatsApp}className={classes.borderRedes}  alt="WhatsApp" /></a></Box>  
                              </div>
                              <img style={{position:'absolute', left: '0%', marginTop: '28.2vh', width: '40px'  }} src={flechaAbajo}></img>
                            </animated.div>
                          </animated.div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
          </div>  

          <div className={classes.fondo2Desktop} >
            <CssBaseline />
              <Container> 
                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.divMetodologia}>
                      <Grid container justifyContent="stretch" spacing={spacing}>
                        {[2].map((value) => (
                          <Grid key={value} item>
                            <div className={classes.DivEspacioDesktop} >
                              <animated.div
                                style={{
                                  transition: 'all 2.5s linear',
                                  opacity: x.interpolate({ range: [0, 900], output: [0, 900] }),
                                }}
                              >
                                <div style={{margin: '10px 0 0 -20px', position: 'absolute',}} >
                                  <img className={classes.sizePuntos2} src={puntos} alt="" />
                                </div>
                                <Typography variant="h3" component="h2" className={classes.typography} > 
                                  <FormattedMessage id = "InicioPage7" defaultMessage="Metodología" />
                                </Typography>
                                <Typography variant="h3" component="h2" className={classes.typography} > 
                                  <FormattedMessage id = "InicioPage8" defaultMessage="InnovaHub" />
                                </Typography>
                              </animated.div><br></br>
                              <animated.div
                              style={{
                                transition: 'all 2.5s linear',
                                opacity: x.interpolate({ range: [0, 900], output: [0, 900] }),
                              }}
                              >
                                <Typography className={classes.sizeTypography2} variant="body1" component="h2" > 
                                  <FormattedMessage id = "InicioPage9" defaultMessage="En seis pasos comprendemos a las personas, sus contextos, diseñamos una intervención, (juego, curso, campamento, etc) la implementamos, monitoreamos y evaluamos de manera que se logren cambios a nivel institucional/organizacional." />
                                </Typography>
                              </animated.div>
                            </div>
                          </Grid>
                        ))}
                        <Grid container justifyContent="center" spacing={spacing}>
                          <div className={classes.divImagenesDesktop} >
                            <div className={classes.ubicacionPersonaje1} >
                            <div
                                    aria-label="close"
                                    onClick={() => {handleOpen1();animacion()}}
                                    sx={{
                                      position: 'absolute',
                                      right: 8,
                                      color: (theme) => theme.palette.grey[500],
                                    }}
                                    className={classes.iconBtn2Desktop}                
                                  > 
                              <animated.div
                                style={{
                                  transition: 'all 1s linear',
                                  opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                                }}
                              >
                                <div className={classes.divtypography1} >
                                  <Typography variant="body1" component="h2" className={classes.typographyPersonajes} >
                                    <FormattedMessage id = "InicioPage10" defaultMessage="Estudio de futuros" /> 
                                  </Typography>
                                </div>                                         
                                <img className={classes.sizePersonajes} src={personaje1} alt="" />
                                <div className={classes.btn1Desktop} >
                               </div>
                              </animated.div>
                            </div>
                            </div>
                            <div className={classes.ubicacionPersonaje2} align="right" >
                            <div
                                      aria-label="close"
                                      onClick={() => {handleOpen2();animacion4();}}
                                      sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                      }}
                                      className={classes.iconBtn2Desktop}                
                                    >    
                              <animated.div
                                style={{
                                  transition: 'all 1s linear',
                                  opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                                }}
                                >
                                  <div align="left" className={classes.divtypography2} >
                                    <Typography variant="body1" component="h2" className={classes.typographyPersonajes} > 
                                      <FormattedMessage id = "InicioPage11" defaultMessage="Análisis espectral" />
                                    </Typography>                        
                                  </div>                 
                                  <img className={classes.sizePersonaje2} src={personaje2} alt="" />  
                                  <div className={classes.btn1Desktop} >
                                 </div> 
                                </animated.div>
                              </div>
                                    </div>
                              <div className={classes.ubicacionPersonaje3} >
                              <div
                                      aria-label="close"
                                      onClick={() => {handleOpen3();animacion5()}}
                                      sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                      }}
                                      className={classes.iconBtn2Desktop}                
                                    >    
                                <animated.div
                                  style={{
                                    transition: 'all 1s linear',
                                    opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                                  }}
                                >
                                  <img className={classes.sizePersonajes} src={personaje3} alt="" />  
                                  <div className={classes.btn1Desktop} >
                                  
                                  </div> 
                                  <div className={classes.divtypography3} >
                                    <Typography variant="body1" component="h2" className={classes.typographyPersonajes} >
                                      <FormattedMessage id = "InicioPage12" defaultMessage="pasteurización" /> 
                                    </Typography>                                         
                                  </div>
                                </animated.div>
                              </div>
                                    </div>
                              <div className={classes.ubicacionPersonaje4} align="right">
                              <div
                                      aria-label="close"
                                      onClick={() => {handleOpen4();animacion6()}}
                                      sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                      }}
                                      className={classes.iconBtn2Desktop}                
                                    >    
                                <animated.div
                                  style={{
                                    transition: 'all 1s linear',
                                    opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                                  }}
                                >
                                  <div className={classes.divtypography4} >
                                    <Typography variant="body1" component="h2" className={classes.typographyPersonajes} > 
                                      <FormattedMessage id = "InicioPage13" defaultMessage="Cubismo" />
                                    </Typography>                        
                                  </div>
                                  <img className={classes.sizePersonajes} src={personaje4} alt="" /> 
                                  <div className={classes.btn1Desktop} >
                                   </div> 
                                </animated.div>
                              </div>
                                    </div>
                              <animated.div
                                style={{
                                  transition: 'all 0s linear',
                                  opacity: x.interpolate({ range: [0, 10], output: [0, 15] }),
                                }}
                              >                                
                                  <img className={classes.sizev9Edit} src={v9Edit} alt="" /> 
                              </animated.div>
                              <div className={classes.ubicacionPersonaje5} >
                              <div
                                      aria-label="close"
                                      onClick={() => {handleOpen5();animacion7()}}
                                      sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                      }}
                                      className={classes.iconBtn2Desktop}                
                                    >    
                                <animated.div
                                  style={{
                                    transition: 'all 1s linear',
                                    opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                                  }}
                                >
                                  <img className={classes.sizePersonajes} src={personaje5} alt="" />  
                                  <div className={classes.btn1Desktop} >
                                  </div>
                                  <div className={classes.divtypography5}>
                                    <Typography variant="body1" component="h2" className={classes.typographyPersonajes} > 
                                      <FormattedMessage id = "InicioPage14" defaultMessage="Experimentación" />
                                    </Typography>                        
                                  </div>
                                </animated.div>
                              </div>
                                    </div>
                              <div className={classes.ubicacionPersonaje6} >
                              <div
                                      aria-label="close"
                                      onClick={() => {handleOpen6();animacion8()}}
                                      sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                      }}
                                      className={classes.iconBtn2Desktop}                
                                    >    
                                <animated.div
                                  style={{
                                    transition: 'all 1s linear',
                                    opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                                  }}
                                >
                                  <img className={classes.sizePersonajes} src={personaje6} alt="" />  
                                  <div className={classes.btn1Desktop} >
                                  </div>
                                  <div className={classes.divtypography6} >
                                    <Typography variant="body1" component="h2" className={classes.typographyPersonajes} >
                                      <FormattedMessage id = "InicioPage15" defaultMessage="Inteligencia de datos" />
                                    </Typography>                        
                                  </div>
                                </animated.div>
                              </div>
                                    </div>
                            </div>
                            <div>
                              <Modal 
                                open={open1,state3}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className={classes.boxModal1} >
                                  <div >
                                    <div align="right" >                                     
                                          <img onClick={()=>{handleClose1();closeAnimacion()}} className={classes.imageSize} src={cerrarRojo}  alt="Rojo4" />
                                    </div>
                                  </div>
                                  <div>
                                    <div className={classes.divModal} >
                                      <div className={classes.DivEspacioDesktop2} >
                                        <animated.div
                                          style={{
                                            transition: 'all 0.5s linear',
                                            opacity: y.interpolate({ range: [0, 700], output: [0, 700] }),
                                                        
                                          }}
                                        >
                                          <div className={classes.ubicacionPuntosModal2} >
                                            <img className={classes.sizePuntos} src={puntos} alt="" />
                                          </div>                                        
                                          <Typography variant="h3" component="h2" className={classes.typographyTitle}>
                                            <FormattedMessage id = "InicioPage16" defaultMessage="1. Estudio De Futuros" />
                                          </Typography><br></br><br></br><br></br>
                                        </animated.div>
                                        <animated.div
                                          style={{
                                            transitionDelay: '0.2s',
                                            opacity: y.interpolate({ range: [0, 2000], output: [0, 2000] }),
                                          }}
                                        >
                                          <Typography variant="body1" component="h2" className={classes.typographyMetodologiaM1}>
                                            <FormattedMessage id = "InicioPage17" defaultMessage="Partimos de un ejercicio de análisis prospectivo, para responder con" />       
                                          </Typography><br></br><br></br>      
                                          <Typography variant="body1" component="h2" style={{fontStyle:'italic',}} className={classes.typographyMetodologiaM1}>
                                            <FormattedMessage id = "InicioPage18" defaultMessage="Partimos de un ejercicio de análisis prospectivo, para responder con" />       
                                          </Typography>                                      
                                        </animated.div>
                                      </div>
                                    </div>
                                  </div>
                                  <animated.div
                                    style={{
                                      transition: 'all 0.8s linear',          
                                      position: 'absolute',
                                      width: '120%',
                                      left:'100%',
                                      top: '6%',
                                      scale: y.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                                      translateX:'-100%',
                                      transform: y
                                      .interpolate({
                                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                        output: [0.97, 0.9, 1.1, 1.03],
                                      })
                                    }}
                                  >
                                    <div className={classes.divFigura} >
                                      <img src={figuraModal}  alt="figuraModal" className={classes.figuraModal1}  />
                                    </div>           
                                  </animated.div>
                                  <animated.div
                                    style={{                  
                                      transitionDelay: '0.4s',
                                      opacity: y.interpolate({ range: [0, 700], output: [0, 700] }),
                                    }}
                                  >
                                    <div align="center" className={classes.divBtnRojo} >
                                      <div align="center" className={classes.divBtnRojo2} >
                                        <div className={classes.divBtnRojo3} >
                                          <div className={classes.divBtnRojo4} >
                                            <img src={Rojo1} alt="Rojo1" className={classes.BtnRojo1R}  />
                                            <div className={classes.BotonAmarillo1} >
                                              {open1 && <Box bgcolor="inherit" p={1} >
                                                <img src={Amarillo1} className={classes.BtnRojo1} alt="" /> 
                                              </Box>}
                                            </div>
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo5} >
                                          <div className={classes.divBtnRojo6} >
                                            <img src={Rojo2}  alt="Rojo2" className={classes.BtnRojo2}  onClick={()=>{handleOpen2();handleClose1();handleClose3();handleClose4();handleClose5();handleClose6();animacion4();closeAnimacion();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>                             
                                         </div>
                                        <div className={classes.divBtnRojo7} >
                                          <div className={classes.divBtnRojo8} >
                                            <img src={Rojo3}  alt="Rojo3" className={classes.BtnRojo2} onClick={()=>{handleOpen3();handleClose1();handleClose2();handleClose4();handleClose5();handleClose6();animacion5();closeAnimacion();closeAnimacion4();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo9} >
                                          <div className={classes.divBtnRojo10} >
                                            <img src={Rojo4}  alt="Rojo4" className={classes.BtnRojo2}   onClick={()=>{handleOpen4();handleClose1();handleClose2();handleClose3();handleClose5();handleClose6();animacion6();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion7();closeAnimacion8()}} />
                                          </div>                                          
                                        </div>
                                        <div className={classes.divBtnRojo11} >
                                          <div className={classes.divBtnRojo12} >
                                            <img src={Rojo5}  alt="Rojo5" className={classes.BtnRojo2} onClick={()=>{handleOpen5();handleClose1();handleClose2();handleClose3();handleClose4();handleClose6();animacion7();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion8()}}  />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo13} >
                                          <div className={classes.divBtnRojo14} >
                                            <img src={Rojo6}  alt="Rojo6" className={classes.BtnRojo2} onClick={()=>{handleOpen6();handleClose1();handleClose2();handleClose3();handleClose4();handleClose5();animacion8();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7()}}  />
                                          </div>                                        
                                        </div>
                                      </div>
                                    </div>
                                  </animated.div>
                                </Box>
                              </Modal>
                            </div>
                            <div >
                              <Modal 
                                open={open2,state4}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className={classes.boxModal2} >
                                  <div >
                                    <div align="right" >                                     
                                          <img onClick={()=>{handleClose2();closeAnimacion4()}} className={classes.imageSize} src={cerrarRojo}  alt="Rojo4" />
                                    </div>
                                  </div>
                                  <div  >
                                    <div className={classes.divBtnRojoMD} >
                                      <div className={classes.ubicacionAnimated} >
                                        <animated.div
                                          style={{
                                            transition: 'all 0.5s linear',
                                            opacity: a.interpolate({ range: [0, 700], output: [0, 700] }),                  
                                          }}
                                          >                                   
                                            <div className={classes.ubicacionPuntosModal} >
                                              <img className={classes.sizePuntos} src={puntos} alt="" />
                                            </div>
                                            <Typography variant="h3" style={{width: '50vw'}} component="h2" className={classes.typographyTitle}> 
                                              <FormattedMessage id = "InicioPage26" defaultMessage="2. Analisis Espectral" />                                              
                                            </Typography><br></br>
                                          </animated.div>
                                          <animated.div
                                            style={{
                                              transitionDelay: '0.2s',
                                              opacity: a.interpolate({ range: [0, 2000], output: [0, 2000] }),
                                            }}
                                          >
                                            <Typography variant="body1" component="h2" className={classes.typographyMetodologiaM2}>
                                              <FormattedMessage id = "InicioPage27" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas." /> 
                                            </Typography><br></br>
                                            <Typography variant="body1" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaM2}>
                                              <FormattedMessage id = "InicioPage28" defaultMessage="'diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />                                              
                                            </Typography>
                                          </animated.div>
                                        </div>
                                      </div>
                                    </div>
                                    <animated.div
                                      style={{
                                        transition: 'all 0.5s linear',          
                                        position: 'absolute',
                                        width: '105%',
                                        left:'-38%',
                                        top: '20%',
                                        scale: a.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                                        translateX:'34%',
                                        transform: a
                                        .interpolate({
                                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                          output: [0.97, 0.9, 1.1, 1.03],
                                        })
                                      }}
                                    >
                                      <div className={classes.divBtnRojo17} >
                                        <div align="right" className={classes.divBtnRojo18} >
                                          <img src={figuraModal2}  alt="figuraModal2" className={classes.imagenModal3}  />
                                        </div>
                                      </div>
                                    </animated.div>
                                    <animated.div
                                      style={{        
                                        transitionDelay: '0.4s',
                                        opacity: a.interpolate({ range: [0, 700], output: [0, 700] }),
                                      }}
                                    >
                                      <div align="center" className={classes.divBtnRojo19} >
                                        <div align="center" className={classes.divBtnRojo20} >
                                          <div className={classes.divBtnRojo21} >
                                            <div className={classes.divBtnRojo22} >
                                              <img src={Rojo1}  alt="Rojo1" className={classes.iconBtn9} onClick={()=>{handleOpen1();handleClose2();handleClose3();handleClose4();handleClose5();handleClose6();animacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo23} >
                                            <div className={classes.divBtnRojo24} >
                                              <img src={Rojo2}  alt="Rojo2" style={{width: '2.5em', marginTop: '3px', marginLeft: '3px'}} />
                                              <div className={classes.BotonAmarillo2} >
                                                {open2 && <Box bgcolor="inherit" p={1} >
                                                  <img src={Amarillo2} className={classes.BtnRojo1} alt=""/> 
                                                </Box>}
                                              </div>
                                            </div>                                            
                                          </div>
                                          <div className={classes.divBtnRojo25} >
                                            <div className={classes.divBtnRojo26} >
                                              <img src={Rojo3}  alt="Rojo3" className={classes.iconBtn9} onClick={()=>{handleOpen3();handleClose1();handleClose2();handleClose4();handleClose5();handleClose6();animacion5();closeAnimacion();closeAnimacion4();closeAnimacion6();closeAnimacion7();closeAnimacion8()}}  />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo27} >
                                            <div className={classes.divBtnRojo28} >
                                              <img src={Rojo4}  alt="Rojo4" className={classes.iconBtn9}  onClick={()=>{handleOpen4();handleClose1();handleClose2();handleClose3();handleClose5();handleClose6();animacion6();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion7();closeAnimacion8()}}  />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo29} >
                                            <div className={classes.divBtnRojo30} >
                                              <img src={Rojo5}  alt="Rojo5" className={classes.iconBtn9} onClick={()=>{handleOpen5();handleClose1();handleClose2();handleClose3();handleClose4();handleClose6();animacion7();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion8()}}  />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo31} >
                                            <div className={classes.divBtnRojo32} >
                                              <img src={Rojo6}  alt="Rojo6" className={classes.iconBtn9}  onClick={()=>{handleOpen6();handleClose1();handleClose2();handleClose3();handleClose4();handleClose5();animacion8();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7()}} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </animated.div>
                                  </Box>
                                </Modal>
                              </div>
                              <div >
                                <Modal 
                                  open={open3,state5}    
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className={classes.boxModal2} >
                                    <div>
                                    <div align="right" >                                     
                                          <img onClick={()=>{handleClose3();closeAnimacion5()}} className={classes.imageSize} src={cerrarRojo}  alt="Rojo4" />
                                    </div>
                                    </div>
                                    <div>
                                      <div className={classes.ubicacionAnimated2} >
                                        <div className={classes.ubicacionAnimated3} >
                                          <animated.div
                                            style={{
                                              transition: 'all 0.5s linear',
                                              opacity: b.interpolate({ range: [0, 700], output: [0, 700] }),                
                                            }}
                                          >
                                            <div className={classes.ubicacionPuntosModal} >
                                              <img className={classes.sizePuntos} src={puntos} alt="" />
                                            </div>
                                            <Typography style={{fontSize:'64px', width: '50vw'}} variant="h3" component="h2" className={classes.typographyTitle}>
                                              <FormattedMessage id = "InicioPage29" defaultMessage="3. Tercer modal" />
                                            </Typography><br></br><br></br>
                                          </animated.div>
                                          <animated.div
                                            style={{
                                              transitionDelay: '0.2s',
                                              opacity: b.interpolate({ range: [0, 2000], output: [0, 2000] }),
                                            }}
                                          >
                                            <Typography  variant="body1" component="h2" className={classes.typographyMetodologiaM3}>
                                              <FormattedMessage id = "InicioPage30" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas. " />                                              
                                            </Typography><br></br>
                                            <Typography variant="body1" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaM3}>
                                              <FormattedMessage id = "InicioPage31" defaultMessage="'diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />                                              
                                            </Typography>
                                          </animated.div>
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      <animated.div
                                        style={{
                                          transition: 'all 0.5s linear',          
                                          position: 'absolute',
                                          width: '105%',
                                          left:'-38%',
                                          top: '20%',
                                          scale: b.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                                          translateX:'-69%',
                                          transform: b
                                          .interpolate({
                                            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                            output: [0.97, 0.9, 1.1, 1.03],
                                          })
                                        }}
                                      >
                                        <div align="left" style={{position: 'absolute',margin:'0.3% 0 0 170%',width:'100%'}} >
                                          <div style={{width: '50%',margin:'-3% 0 0 0%'}} align="left" >
                                            <img style={{margin:'-3% 0 0 0%',width: '66.5%'}} src={figuraModal3} alt="figuraModal2" />
                                          </div>
                                        </div>
                                      </animated.div>
                                    </div>

                                    <animated.div
                                      style={{        
                                        transitionDelay: '0.4s',
                                        opacity: b.interpolate({ range: [0, 700], output: [0, 700] }),
                                      }}
                                    >
                                      <div align="center" className={classes.divBtnRojo19} >
                                        <div align="center" className={classes.divBtnRojo20} >
                                          <div className={classes.divBtnRojo21} >
                                            <div className={classes.divBtnRojo22} >
                                              <img src={Rojo1}  alt="Rojo1" className={classes.iconBtn9} onClick={()=>{handleOpen1();handleClose2();handleClose3();handleClose4();handleClose5();handleClose6();animacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo23} >
                                            <div className={classes.divBtnRojo24} >
                                              <img src={Rojo2}  alt="Rojo2" className={classes.iconBtn9} onClick={()=>{handleOpen2();handleClose1();handleClose3();handleClose4();handleClose5();handleClose6();animacion4();closeAnimacion();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo25} >
                                            <div className={classes.divBtnRojo26} >
                                              <img src={Rojo3}  alt="Rojo3" style={{width: '2.5em', marginTop: '3px', marginLeft: '3px'}}  />
                                              <div className={classes.BotonAmarillo3} >
                                                {open3 && <Box bgcolor="inherit" p={1} >
                                                  <img src={Amarillo3} className={classes.BtnRojo1} alt="" /> 
                                                </Box>}
                                              </div>
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo27} >
                                            <div className={classes.divBtnRojo28} >
                                              <img src={Rojo4}  alt="Rojo4" className={classes.iconBtn9} onClick={()=>{handleOpen4();handleClose1();handleClose2();handleClose3();handleClose5();handleClose6();animacion6();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion7();closeAnimacion8()}}  />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo29} >
                                            <div className={classes.divBtnRojo30} >
                                              <img src={Rojo5}  alt="Rojo5" className={classes.iconBtn9}  onClick={()=>{handleOpen5();handleClose1();handleClose2();handleClose3();handleClose4();handleClose6();animacion7();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion8()}} />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo31} >
                                            <div className={classes.divBtnRojo32} >
                                              <img src={Rojo6}  alt="Rojo6" className={classes.iconBtn9} onClick={()=>{handleOpen6();handleClose1();handleClose2();handleClose3();handleClose4();handleClose5();animacion8();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7()}} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </animated.div>
                                  </Box>
                                </Modal>
                              </div>
                              <div >
                                <Modal 
                                  open={open4,state6}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box className={classes.boxModal2} >
                                    <div>
                                    <div align="right" >                                     
                                          <img onClick={()=>{handleClose4();closeAnimacion6()}} className={classes.imageSize} src={cerrarRojo}  alt="Rojo4" />
                                    </div>
                                    </div>
                                    <div>
                                      <div className={classes.divBtnRojo16} >
                                        <div className={classes.ubicacionAnimated4} >
                                            <animated.div
                                              style={{
                                                transition: 'all 0.5s linear',
                                                opacity: c.interpolate({ range: [0, 700], output: [0, 700] }),
                                                            
                                              }}
                                            >
                                              <div className={classes.ubicacionPuntosModal} >
                                                <img className={classes.sizePuntos} src={puntos} alt="" />
                                              </div>
                                              <Typography className={classes.typographyTitle} variant="h3" component="h2" >
                                                <FormattedMessage id = "InicioPage32" defaultMessage="Cuarto Modal" />
                                              </Typography><br></br>
                                            </animated.div>
                                            <animated.div
                                              style={{
                                                transitionDelay: '0.2s',
                                                opacity: c.interpolate({ range: [0, 2000], output: [0, 2000] }),
                                              }}
                                            >
                                              <Typography variant="body1" component="h2" className={classes.typographyMetodologiaMD}>
                                                <FormattedMessage id = "InicioPage33" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas." />
                                              </Typography><br></br>
                                              <Typography variant="body1" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaMD}>
                                                <FormattedMessage id = "InicioPage34" defaultMessage="'diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />                                              
                                              </Typography>
                                            </animated.div>
                                          </div>
                                        </div>
                                      </div>
                                    <animated.div
                                      style={{
                                        transition: 'all 0.5s linear',          
                                        position: 'absolute',
                                        width: '105%',
                                        left:'-38%',
                                        top: '20%',
                                        scale: c.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                                        translateX:'34%',
                                        transform: c
                                        .interpolate({
                                          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                          output: [0.97, 0.9, 1.1, 1.03],
                                        })
                                      }}
                                    >
                                      <div className={classes.ubicacionFiguraModal6} >
                                        <div >
                                          <img className={classes.sizeFigura6} src={figuraModal6}  alt="" />
                                        </div>
                                      </div>
                                    </animated.div>
                                    <animated.div
                                      style={{        
                                        transitionDelay: '0.4s',
                                        opacity: c.interpolate({ range: [0, 700], output: [0, 700] }),
                                      }}
                                    >
                                      <div align="center" className={classes.divBtnRojo19} >
                                        <div align="center" className={classes.divBtnRojo20} >
                                          <div className={classes.divBtnRojo21} >
                                            <div className={classes.divBtnRojo22} >
                                              <img src={Rojo1}  alt="Rojo1" className={classes.iconBtn9} onClick={()=>{handleOpen1();handleClose2();handleClose3();handleClose4();handleClose5();handleClose6();animacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}}  />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo23} >
                                            <div className={classes.divBtnRojo24} >
                                              <img src={Rojo2}  alt="Rojo2" className={classes.iconBtn9} onClick={()=>{handleOpen2();handleClose1();handleClose3();handleClose4();handleClose5();handleClose6();animacion4();closeAnimacion();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}}  />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo25} >
                                            <div className={classes.divBtnRojo26} >
                                              <img src={Rojo3}  alt="Rojo3" className={classes.iconBtn9} onClick={()=>{handleOpen3();handleClose1();handleClose2();handleClose4();handleClose5();handleClose6();animacion5();closeAnimacion();closeAnimacion4();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo27} >
                                            <div className={classes.divBtnRojo28} >
                                              <img src={Rojo4} style={{width: '2.5em', marginTop: '3px', marginLeft: '3px'}} alt="" />
                                              <div className={classes.BotonAmarillo4} >
                                                {open4 && <Box bgcolor="inherit" p={1} >
                                                  <img src={Amarillo4} className={classes.BtnRojo1} alt=""/> 
                                                </Box>}
                                              </div>
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo29} >
                                            <div className={classes.divBtnRojo30} >
                                              <img src={Rojo5}  alt="Rojo5" className={classes.iconBtn9} onClick={()=>{handleOpen5();handleClose1();handleClose2();handleClose3();handleClose4();handleClose6();animacion7();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion8()}} />
                                            </div>
                                          </div>
                                          <div className={classes.divBtnRojo31} >
                                            <div className={classes.divBtnRojo32} >
                                              <img src={Rojo6}  alt="Rojo6" className={classes.iconBtn9} onClick={()=>{handleOpen6();handleClose1();handleClose2();handleClose3();handleClose4();handleClose5();animacion8();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7()}} />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </animated.div>
                                  </Box>
                                </Modal>
                              </div>
                            <div >
                              <Modal 
                                open={open5,state7}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className={classes.boxModal2} >
                                  <div>
                                  <div align="right" >                                     
                                          <img onClick={()=>{handleClose5();closeAnimacion7()}} className={classes.imageSize} src={cerrarRojo}  alt="Rojo4" />
                                    </div>
                                  </div>


                                  <div>
                                    <div className={classes.ubicacionAnimated5} >
                                      <div className={classes.ubicacionAnimated6} >
                                        <animated.div
                                          style={{
                                            transition: 'all 0.5s linear',
                                            opacity: d.interpolate({ range: [0, 700], output: [0, 700] }),                
                                          }}
                                        >
                                          <div className={classes.ubicacionPuntosModal} >
                                            <img className={classes.sizePuntos} src={puntos} alt="" />
                                          </div>
                                          <Typography className={classes.typographyTitle} variant="h3" component="h2" > 
                                            <FormattedMessage id = "InicioPage35" defaultMessage="Quinto modal" />
                                          </Typography><br></br>
                                        </animated.div>
                                        <animated.div
                                          style={{
                                            transitionDelay: '0.2s',
                                            opacity: d.interpolate({ range: [0, 2000], output: [0, 2000] }),
                                          }}
                                        >
                                          <Typography variant="body1" component="h2" className={classes.typographyMetodologiaM3}>
                                            <FormattedMessage id = "InicioPage36" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas." /> 
                                          </Typography><br></br>
                                          <Typography variant="body1" component="h2" style={{fontStyle:'italic',}}  className={classes.typographyMetodologiaM3}>
                                            <FormattedMessage id = "InicioPage37" defaultMessage="'diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />
                                          </Typography>
                                        </animated.div>
                                      </div>
                                    </div>
                                  </div>
                                  <animated.div
                                    style={{
                                      transition: 'all 0.5s linear',          
                                      position: 'absolute',
                                      width: '105%',
                                      left:'-38%',
                                      top: '20%',
                                      scale: d.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                                      translateX:'-73%',
                                      transform: d
                                      .interpolate({
                                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                        output: [0.97, 0.9, 1.1, 1.03],
                                      })
                                    }}
                                  >

                                    <div className={classes.ubicacionAnimated7} align="left" >
                                      <div className={classes.ubicacionAnimated8} align="left">
                                        <img className={classes.sizeFigura4} src={figuraModal4} alt="" />
                                      </div>
                                    </div>
                                  </animated.div>
                                  <animated.div
                                    style={{        
                                      transitionDelay: '0.4s',
                                      opacity: d.interpolate({ range: [0, 700], output: [0, 700] }),
                                    }}
                                  >
                                    <div align="center" className={classes.divBtnRojo19} >
                                      <div align="center" className={classes.divBtnRojo20} >
                                        <div className={classes.divBtnRojo21} >
                                          <div className={classes.divBtnRojo22} >
                                            <img src={Rojo1}  alt="Rojo1" className={classes.iconBtn9} onClick={()=>{handleOpen1();handleClose2();handleClose3();handleClose4();handleClose5();handleClose6();animacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo23} >
                                          <div className={classes.divBtnRojo24} >
                                            <img src={Rojo2}  alt="Rojo2" className={classes.iconBtn9} onClick={()=>{handleOpen2();handleClose1();handleClose3();handleClose4();handleClose5();handleClose6();animacion4();closeAnimacion();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo25} >
                                          <div className={classes.divBtnRojo26} >
                                            <img src={Rojo3}  alt="Rojo3" className={classes.iconBtn9} onClick={()=>{handleOpen3();handleClose1();handleClose2();handleClose4();handleClose5();handleClose6();animacion5();closeAnimacion();closeAnimacion4();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo27} >
                                          <div className={classes.divBtnRojo28} >
                                            <img src={Rojo4}  alt="Rojo4" className={classes.iconBtn9} onClick={()=>{handleOpen4();handleClose1();handleClose2();handleClose3();handleClose5();handleClose6();animacion6();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo29} >
                                          <div className={classes.divBtnRojo30} >
                                            <img src={Rojo5}  alt="Rojo5" style={{width: '2.5em', marginTop: '3px', marginLeft: '3px'}}  />
                                            <div className={classes.BotonAmarillo5} >
                                              {open5 && <Box bgcolor="inherit" p={1} >
                                                <img src={Amarillo5} className={classes.BtnRojo1} alt=""/> 
                                              </Box>}
                                            </div>
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo31} >
                                          <div className={classes.divBtnRojo32} >
                                            <img src={Rojo6}  alt="Rojo6" className={classes.iconBtn9} onClick={()=>{handleOpen6();handleClose1();handleClose2();handleClose3();handleClose4();handleClose5();animacion8();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7()}} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </animated.div>
                                </Box>
                              </Modal>
                            </div>
                            <div >
                              <Modal 
                                open={open6,state8}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className={classes.boxModal2} >
                                  <div>
                                  <div align="right" >                                     
                                          <img onClick={()=>{handleClose6();closeAnimacion8()}} className={classes.imageSize} src={cerrarRojo}  alt="Rojo4" />
                                    </div>
                                  </div>
                                  <div>
                                    <div className={classes.ubicacionAnimated9} >
                                      <div className={classes.ubicacionAnimated10} >
                                        <animated.div
                                          style={{
                                            transition: 'all 0.5s linear',
                                            opacity: e.interpolate({ range: [0, 700], output: [0, 700] }),                    
                                          }}
                                        >
                                          <div className={classes.ubicacionPuntosModal} >
                                            <img className={classes.sizePuntos} src={puntos} alt="" />
                                          </div>
                                          <Typography style={{width: '50vw'}} className={classes.typographyTitle} variant="h3" component="h2" > 
                                            <FormattedMessage id = "InicioPage38" defaultMessage="Sexto modal" />
                                          </Typography><br></br>
                                        </animated.div>
                                        <animated.div
                                          style={{
                                            transitionDelay: '0.2s',
                                            opacity: e.interpolate({ range: [0, 2000], output: [0, 2000] }),
                                          }}
                                        >
                                          <Typography variant="body1" component="h2" className={classes.typographyMetodologiaM2}>
                                            <FormattedMessage id = "InicioPage39" defaultMessage="Siempre se dice 'pongamos a las personas en el centro' sin embargo, muy pocas veces realmente se hace. En innovaHub es primordial comprender las características de las personas, sus ideales, sus motivaciones, sus limitaciones y su contexto, no de manera superflua y meramente consultiva, sino llegar a comprender lo que las personas son y valoran ser. Por ello, analizamos todo el 'espectro' como conectividad, alfabetización digital, condiciones laborales, conocimientos previos, entre otros, para plantear estrategias holísticas y acordes a la realidad de las personas." />                                            
                                          </Typography><br></br>
                                          <Typography variant="body1" component="h2" style={{fontStyle:'italic',}}   className={classes.typographyMetodologiaM2}>
                                            <FormattedMessage id = "InicioPage40" defaultMessage="'diseñamos para alguien, con ese alguien. De lo contrario ¿qué sentido tendría?" />
                                          </Typography>
                                        </animated.div>
                                      </div>
                                    </div>
                                  </div>
                                  <animated.div
                                    style={{
                                      transition: 'all 0.5s linear',          
                                      position: 'absolute',
                                      width: '105%',
                                      left:'-38%',
                                      top: '20%',
                                      scale: e.interpolate({ range: [0.9, 1], output: [0.9, 1]}),
                                      translateX:'34%',
                                      transform: e
                                      .interpolate({
                                        range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75],
                                        output: [0.97, 0.9, 1.1, 1.03],
                                      })
                                    }}
                                  >

                                    <div className={classes.ubicacionAnimated11} align="left" >
                                      <div className={classes.ubicacionAnimated12} align="left">
                                        <img style={{margin:'-3% 0 0 1%',width: '32%'}} src={figuraModal5}  alt="" />
                                      </div>
                                    </div>
                                  </animated.div>
                                  <animated.div
                                    style={{        
                                      transitionDelay: '0.4s',
                                      opacity: e.interpolate({ range: [0, 700], output: [0, 700] }),
                                    }}
                                  >
                                    <div align="center" className={classes.divBtnRojo19} >
                                      <div align="center" className={classes.divBtnRojo20} >
                                        <div className={classes.divBtnRojo21} >
                                          <div className={classes.divBtnRojo22} >
                                            <img src={Rojo1}  alt="Rojo1" className={classes.iconBtn9} onClick={()=>{handleOpen1();handleClose2();handleClose3();handleClose4();handleClose5();handleClose6();animacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo23} >
                                          <div className={classes.divBtnRojo24} >
                                            <img src={Rojo2}  alt="Rojo2" className={classes.iconBtn9} onClick={()=>{handleOpen2();handleClose1();handleClose3();handleClose4();handleClose5();handleClose6();animacion4();closeAnimacion();closeAnimacion5();closeAnimacion6();closeAnimacion7();closeAnimacion8()}}  />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo25} >
                                          <div className={classes.divBtnRojo26} >
                                            <img src={Rojo3}  alt="Rojo3" className={classes.iconBtn9}  onClick={()=>{handleOpen3();handleClose1();handleClose2();handleClose4();handleClose5();handleClose6();animacion5();closeAnimacion();closeAnimacion4();closeAnimacion6();closeAnimacion7();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo27} >
                                          <div className={classes.divBtnRojo28} >
                                            <img src={Rojo4}  alt="Rojo4" className={classes.iconBtn9} onClick={()=>{handleOpen4();handleClose1();handleClose2();handleClose3();handleClose5();handleClose6();animacion6();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion7();closeAnimacion8()}}  />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo29} >
                                          <div className={classes.divBtnRojo30} >
                                            <img src={Rojo5}  alt="Rojo5" className={classes.iconBtn9} onClick={()=>{handleOpen5();handleClose1();handleClose2();handleClose3();handleClose4();handleClose6();animacion7();closeAnimacion();closeAnimacion4();closeAnimacion5();closeAnimacion6();closeAnimacion8()}} />
                                          </div>
                                        </div>
                                        <div className={classes.divBtnRojo31} >
                                          <div className={classes.divBtnRojo32} >
                                            <img src={Rojo6}  alt="Rojo6" style={{width: '2.5em', marginTop: '3px', marginLeft: '3px'}}  />
                                            <div className={classes.BotonAmarillo6} >
                                              {open6 && <Box bgcolor="inherit" p={1} >
                                                <img src={Amarillo6} className={classes.BtnRojo1} alt=""/> 
                                              </Box>}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </animated.div>
                                </Box>
                              </Modal>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Container>      
              </div>

              <div className={classes.fondo3Desktop}>
                <div className={classes.lineaAmarilla} ></div>
                  <CssBaseline />
                    <Container> 
                          <div className={classes.divFondo3} >                              
                                  <div className={classes.divProyectos}>
                                    <div className={classes.ubicacionPuntos2} >
                                      <img className={classes.sizePuntos2} src={puntos} alt="" />
                                    </div>
                                    <div className={classes.ubicacionTypography2} >
                                      <Typography className={classes.typography} variant="h3" component="h2"  > 
                                        <FormattedMessage id = "InicioPage41" defaultMessage="Conoce nuestros" />
                                      </Typography>
                                      <Typography className={classes.typography} variant="h3" component="h2"  > 
                                        <FormattedMessage id = "InicioPage42" defaultMessage="proyectos" />
                                      </Typography><br></br>
                                      <Button variant="outlined" href="#/proyectos"  className={classes.btnVerMas} ><div className={classes.sizeVerMas} ><FormattedMessage id = "InicioPage43" defaultMessage="VER MÁS"></FormattedMessage></div></Button>
                                    </div>
                                  </div> 
                                  <img className={classes.sizeTierra} src={tierra} alt="" />
                                  
                                  <div className={classes.DivEspacioHablemos}>
                                  <div className={classes.ubicacionPuntos3} >
                                    <img className={classes.sizePuntos} src={puntos} alt="" />
                                  </div>                      
                                    <Typography style={{fontSize:'52px', fontWeight: 'bold'}} variant="h3" component="h2" className={classes.typographyMetodologia} > 
                                      <FormattedMessage id = "InicioPage44" defaultMessage="Hablemos" />
                                    </Typography>
                                  </div>
                                  <div className={classes.ubicacionComponentE1}>
                                    <div className={classes.divBtnRojo39} >
                                      <Typography variant="body1" component="h2" style={{marginTop: '-18px'}} className={classes.typographyMetodologia}> 
                                        <div style={{fontSize:'16px'}} >
                                        +57 321 410 1120 
                                        </div>
                                        <div style={{fontSize:'16px'}} >
                                        +1 650 843 9153
                                        </div>
                                      </Typography>                        
                                    </div>
                                    <img className={classes.sizeComponent} src={ComponentE1} alt="" />
                                  </div>                      
                                  <div className={classes.ubicacionComponentE2} >
                                    <div className={classes.divBtnRojo40} >
                                      <Typography variant="body1" component="h2" className={classes.typographyMetodologia}> 
                                        <div style={{fontSize:' 16px'}}>
                                        info@innovahub.org 
                                        </div>
                                      </Typography>
                                    </div>
                                    <img className={classes.sizeComponent} src={ComponentE2} alt="" />
                                  </div>                      
                                  <div className={classes.ubicacionComponentE3} >
                                    <div className={classes.divBtnRojo41} >
                                      <Typography variant="body1" component="h2" className={classes.typographyMetodologia}>
                                        <div style={{fontSize:'16px'}}>
                                          <FormattedMessage id = "InicioPage49" defaultMessage="Cra 65 # 169a 50" />
                                        </div>
                                      </Typography>
                                      <Typography variant="body2" component="h2" className={classes.typographyMetodologia}> 
                                      Bogotá/ Colombia
                                      </Typography>
                                    </div>                      
                                    <img className={classes.sizeComponent} src={ComponentE3} alt="" />
                                  </div>                      
                                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '170px', marginTop: '30px', marginBottom: '80px', marginLeft: '17px'}}>
                                    <div className={classes.ubicacionRedes2} >
                                      <Box component="span" ><a href="https://www.facebook.com/InnovaHubCol" target="_blank"><img src={facebook}   alt="facebook" className={classes.borderRedesAbajoF} /></a>&nbsp;</Box>
                                    </div>
                                    <div className={classes.ubicacionRedes2} >
                                      <Box component="span" >&nbsp;<a href="https://www.linkedin.com/company/innovahubcol" target="_blank"><img src={linkedin} alt="linkedin" style={{}} className={classes.borderRedesAbajo} /></a>&nbsp;</Box>
                                    </div>
                                    <div className={classes.ubicacionRedes2} >
                                      <Box component="span" >&nbsp;<a href="https://www.instagram.com/innovahubcol/" target="_blank"><img src={instagram} alt="instagram" style={{}} className={classes.borderRedesAbajo} /></a>&nbsp;</Box>
                                    </div>
                                    <div className={classes.ubicacionRedes2} >
                                      <Box component="span" >&nbsp;<a href="https://api.whatsapp.com/message/23YCTFPYVWDYD1" target="_blank"><img src={WhatsApp} alt="WhatsApp" style={{}} className={classes.borderRedesAbajo} /></a></Box>
                                    </div>
                                  </div>

                                  <div className={classes.divForm}  >
                                    <Box
                                      component="form"
                                      sx={{
                                      '& .MuiTextField-root': { m: 1, width: '25ch' },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    ><br></br>
                                      <div align="center">
                                        <div align="left" className={classes.LabelComponent}><label><FormattedMessage id = "InicioPage45" defaultMessage="Nombre"></FormattedMessage></label></div><br></br>
                                        <TextField style={{width: '80%'}}  
                                          required
                                          id="Nombre"
                                          size="small"
                                          inputRef={nombreRef}
                                          variant="outlined"                                         
                                        />
                                      </div><br></br>
                                      <div align="center">
                                        <div align="left" className={classes.LabelComponent}><label><FormattedMessage id = "InicioPage46" defaultMessage="Correo electrónico"></FormattedMessage></label></div><br></br>
                                          <TextField style={{width: '80%'}}
                                            required
                                            size="small"
                                            id="email"
                                            inputRef={correoRef}
                                            variant="outlined"
                                          />
                                      </div><br></br>
                                      <div align="center">
                                        <div align="left" className={classes.LabelComponent}><label><FormattedMessage id = "InicioPage47" defaultMessage="Mensaje"></FormattedMessage></label></div><br></br>
                                          <TextField style={{width: '80%'}}
                                            required
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            inputRef={mensajeRef}
                                            multiline
                                            rows={3}
                                          />
                                      </div><br></br>
                                      <div align="right" className={classes.divEnviar}  >
                                        <Button  onClick={enviarUser} size="medium" href="" className={classes.btnEnviar}  ><FormattedMessage id = "InicioPage48" defaultMessage="Enviar Mensaje"></FormattedMessage></Button>
                                      </div><br></br>                                     
                                    </Box>
                                  </div>  
                                  {formularioEnviado && <div className={classes.divExtio}> <Typography className={classes.exito}>Nuestro equipo te estará contactando para más información.</Typography> </div>}
                                  { errorFormulario &&<div className={classes.divError}><Typography className={classes.error}> {errorMessage} </Typography> </div> }

                          </div>
                    </Container> 
                    <br></br>

                  </div>
                </div>
              );
            }

return (
  <header>
      {mobileView ? displayMobile() : displayDesktop()}
  </header>
);
}
