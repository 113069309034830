import React from 'react';
import { animated } from 'react-spring';
import { useSpring } from 'react-spring';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import puntos from './images/puntos.png';
import prueba from './images/3d-innovahub-06.png';
import imagenPopup from './images/3d-innovahub-02.png';
import imagenPopup2 from './images/3d-innovahub-03.png';
import imagenPopup3 from './images/3d-innovahub-02b.png';
import imagenPopup4  from './images/3d-innovahub-05.png';
import imagenPopup5  from './images/3d-innovahub-07.png';
import imagenPopup6  from './images/3d-innovahub-04.png';
import imagenPopup7  from './images/3d-innovahub-09.png';
import masRojo from './images/+-rojo.png';
import cerrarRojo from './images/icon-cerrar.png';
import bolaAzul from './images/20_6-min.png';
import tornadoAzul from './images/20_4-min.png';
import circuloAzul from './images/20_2-min.png';
import banderaColombia from './images/bandera-colombia.png';
import banderaGuinea from './images/bandera-guinea.png';
import banderaVenezuela from './images/bandera-venezuela.png';
import banderaEcuador from './images/bandera-ecuador.png';
import banderaGuatemala from './images/bandera-guatemala.png';
import banderaUsa from './images/bandera-usa.png';
import banderaCostaRica from './images/bandera-costa-rica.png';
import banderaHonduras from './images/bandera-honduras.png';
import banderaMexico from './images/bandera-mexico.png';
import banderaPanama from './images/bandera-panama.png';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import {FormattedMessage} from 'react-intl';
import Circulo from './images/circuloRojo.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

  },
  proyectos: {
    color: '#384c5b',
    textDecoration: 'underline',
    textUnderlineOffset: '0.2em',
    fontWeight: 'bold',
    marginTop: '40px',
    width: '930px',
    margin: 'auto',
    fontFamily: 'proxima-nova',
  },
  proyectosMobile: {
    color: '#384c5b',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',    
    fontWeight: 'bold',
    marginBottom: '3%',    
    marginLeft: '20px',
    fontFamily: 'proxima-nova',
  },
  ubicacionPuntos:{
    margin: '0px 0 0 -0.4%',
    position: 'absolute',
  },
  ubicacionPuntosMobile:{
    margin: '0.6% 0 0 -10px',
    position: 'absolute',
  },
  sizePuntos:{
    width: '50%',
    marginLeft: '-100%',
  },
  sizePuntosMobile:{
    width: '4px',
    marginLeft: '-100%',
    marginTop: '5px'
  },
  tagline: {
    color: '#384c5b',
    marginBottom: '30px',
    width: '930px',
    margin: 'auto',
    marginBottom: '120px',
    fontFamily: 'proxima-nova',
  },
  taglineMobile: {
    color: '#384c5b',    
    marginBottom: '8%',
    marginLeft: '20px',
    fontSize: '100%',
    fontFamily: 'proxima-nova',
  },
  box: {
    overflow: 'hidden',
    height: '500px',
    width: '300px',
    position: 'relative'
    
  },
  boxIcono: {
    overflow: 'hidden', 
    maxHeight: '500px',
    width: '300px'
  }, 
  boxIconoMobile: {
    width: '95%',
    maxWidth: '45em',
    height: '20em',  
    overflow: 'hidden',
    margin: '1em auto' ,
    position: 'relative'
  }, 
  titleImage: {
    color: 'white',
    fontWeight: 'bold',
    margin: '5% 1% 1% 10%',
    fontSize: '28px',  
    overflow: 'hidden',
    fontFamily: 'proxima-nova',
  },
  titleImageColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    margin: '5% 1% 1% 10%',
    fontSize: '28px',
    overflow: 'hidden',
    fontFamily: 'proxima-nova',
  },
  titleImagePopup: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '32px',
    float: 'left',
    fontFamily: 'proxima-nova',

   
  },
  titleImagePopupColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    fontSize: '32px',
    float: 'left',
    fontFamily: 'proxima-nova',
  },
  titleImagePopupMobile: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '21px',
    float:'left',
    fontFamily: 'proxima-nova',
  },
  titleImagePopupMobileGE: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '21px',
    float:'left',
    ['@media (max-width:400px)']: {
      width: '100px',
    },
  },
  titleImagePopupMobileColorUSA: {
    color: '#314c5d',
    fontWeight: 'bold',
    fontSize: '21px',
    float:'left',
    fontFamily: 'proxima-nova',
    ['@media (max-width:400px)']: {
      width: '100px',
      fontFamily: 'proxima-nova',
    },
  },
  titleImagePopupMobileColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    fontSize: '21px',
    float: 'left',
    fontFamily: 'proxima-nova',
  },
  titleImageMobile: {
    color: 'white',
    fontWeight: 'bold',
    marginTop: '0.5em',
    marginLeft: '0.5em',
    fontFamily: 'proxima-nova',
  },  
  titleImageMobileColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    marginTop: '0.5em',
    marginLeft: '0.5em',
    fontFamily: 'proxima-nova',
  },  
  imagenBandera: {
    width: '80px',
    margin: '1% 1% 10% 10%',
  },
  imagenBanderaPopup: {
    width: '50px',
  },
  imagenBanderaPopup2: {
    width: '80px',
    marginLeft: '5px',
    marginTop: '5px',

  },
  imagenBanderaPopup3: {
    width: '15%',
    margin: '-2.8em 0 0 15em ',
    position: 'absolute'
  },
  imagenBanderaPopupMobile: {
    width: '30px',
    float:'right',
    marginLeft: '5px',
    marginTop: '5px'
  
  },  
  imagenBanderaPopupMobile2: {
    width: '2.5em',
    margin: '-2em 0 0 13em',
    position: 'absolute'
  },  
  descriptionImage: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    marginLeft: '10%',
    marginBottom: '3%',
  },
  descriptionImageColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    fontSize: '18px',
    marginLeft: '10%',
    marginBottom: '3%',
  },
  descriptionImagePopup: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '20px',
    marginLeft: '9%',
    marginBottom: '3%',
    width: '85%',
    fontFamily: 'proxima-nova',
  },
  descriptionImagePopup2: {
    color: 'white',
    fontSize: '14px',
    marginLeft: '10%',
    marginBottom: '3%',
    width: '51.5%',
    fontFamily: 'proxima-nova',
  },
  descriptionImagePopup2Color: {
    color: '#314c5d',
    fontSize: '14px',
    marginLeft: '10%',
    marginBottom: '3%',
    width: '51.5%',
    fontFamily: 'proxima-nova',
  },
  descriptionImagePopupColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    fontSize: '20px',
    marginLeft: '9%',
    marginBottom: '3%',
    width: '85%',
    fontFamily: 'proxima-nova',
  },
  descriptionImagePopupMobile: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '12px',
    marginLeft: '10%',
    marginTop: '12px',
    width: '82%',
    fontFamily: 'proxima-nova',
  },
  descriptionImagePopupMobile2: {
    color: 'white',
    fontSize: '12px',
    marginLeft: '10%',
    marginBottom: '3%',
    marginTop: '7px',
    width: '82%',
    fontFamily: 'proxima-nova',
  },
  descriptionImagePopupMobileColor: {
    color: '#314c5d',
    fontSize: '12px',
    marginLeft: '10%',
    marginBottom: '3%',
    marginTop: '12px',
    width: '85%',
    fontFamily: 'proxima-nova',
  },
  divDescriptionPopup: {
    margin: '15% 0 0 -7%',
    overflow: 'hidden',
    textAlign: 'justify'
  },

  divDescriptionPopupMobile: {
    margin: '50px 0 0 -5%',
  },
  divDescriptionPopupMobileGE: {
    margin: '50px 0 0 -5%',
    ['@media (max-width:400px)']: {
      top: '20px',
      position: 'absolute'
    },
  },
  descriptionImageMobile: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16.8px',
    marginLeft:'1em',
    marginBottom: '0.5em',
    minHeight: '40px',
    lineHeight: '1',
    marginTop: '6px'
  },
  textImage: {
    color: 'white',
    fontSize: '17px',
    lineHeight: '21px',
    marginLeft: '10%',
    width: '85%',
    fontFamily: 'proxima-nova',

  },
  textImageColor: {
    color: '#314c5d',
    fontSize: '17px',
    lineHeight: '21px',
    marginLeft: '10%',
    width: '85%',
    fontFamily: 'proxima-nova',
  },
  textImageMobileColor: {
    color: '#314c5d',
    fontSize: '100%',
    marginLeft: '1em',
    width: '90%',
    fontFamily: 'proxima-nova',
  },
  textImageMobile: {
    color: 'white',
    fontSize: '100%',
    marginLeft: '1em',
    width: '90%',
    fontFamily: 'proxima-nova',
  },

  imagenMas: {
    width: '70px',
    cursor: 'pointer',
    display: 'block',
    margin: 'auto',
    marginTop:'1em',
    position: 'absolute',
  },  
  imagenMas2: {
    width: '56px',
    height: '56px',
    cursor: 'pointer',
    backgroundColor: '#DF5A49',
    borderRadius: '50%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    right: '0',
    margin: 'auto',
    top: '80%'
  },  
  imagenIconoCirculo: {
    width: '130%',
    height: '100%',
    float: 'left',
    marginLeft: '-37%',
    marginTop: '30%',
  },
  imagenIconoTornado: {
    width: '130%',
    height: '110%',
    float: 'left',
    marginLeft: '-12%',
    marginTop: '45%',
  },
  imagenIconoTornadoMobile: {
    width: '20em',
    float: 'left',
    position: 'absolute',
    top: '1em',
    margin: 'auto',
    left: '0',
    right: '0'

  },
  imagenBoxMobile: {
    width: '4em', 
    float: 'left',
    marginLeft: '1em',
    marginRight: '0.5em',
    marginTop: '0.5em'
    
    }, 
  boxPopup: {
    width:'928px',    
    overflow: 'hidden',
    height: '500px',
    marginLeft: '11px'

  },  
  boxPopupMobile: {

  }, 
  boxPopupMobileColombia:{
    width:'95%',
    height: '27em',
    margin: '-1% 0 0 -3%',
  }, 
  boxPopupMobileHonduras:{
    width:'95%',
    height: '23.5em',
    margin: '-1% 0 0 -3%',
  },
  boxPopupMobileCostaRica:{
    width:'95%',
    height: '23.5em',
    margin: '-1% 0 0 -3%',
  },
  boxPopupMobilePanama:{
    width:'95%',
    height: '23.5em',
    margin: '-1% 0 0 -3%',
  },
  divBtn1:{ 
    margin: '0.1em 0 0 97.7%', 
    backgroundColor: 'white',
    width: '2em',   
    height:'1.8em', 
    borderBottomLeftRadius: '10px 10px',  
   },
   iconBtn1:{
    width: '1em', 
    height:'1em',   
  },
  divTituloPopup: {
    margin: '1% 0 0 2.5%',
    position: 'absolute',
  },
  divTituloPopup2: {
    margin: '1% 0 0 2%',
    position: 'absolute',
    width: '35%'
  },
  
  moreInformationClose: {
    overflowX: 'hidden',
    display: 'none',
  },
  moreInformationCloseMobile: {
    overflowX: 'hidden',
    display: 'none',
  },

  flechaDerecha: {
    fontSize: '700%',
    top: '40%',
    right: '-20px',
    color: '#df5a49',
    cursor: 'pointer',
    position: 'absolute'
  },
  flechaIzquierda: {
    fontSize: '700%',
    color: '#df5a49',
    cursor: 'pointer',
    position: 'absolute',
    top: '40%',
    left: '5px'

  },
  flechaIzquierdaMobile: {
    fontSize: '300%',
    color: '#df5a49',
    position: 'absolute',
    top: '45%',
    left: '-0.2em'
  },
  flechaDerechaMobile: {
    fontSize: '300%',
    position: 'absolute',
    top: '45%',
    right: '-0.2em',
    color: '#df5a49',
  },
  

  columnaVerde:{
    left: '0%',
    top: '440px',
    ['@media (max-width:1209px)']: {
      top: '420px',
    },
    height: '500px',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#48b49c',
  } ,
  columnaAmarilla:{
    left: '98%',
    top: '955px',
    ['@media (max-width:1209px)']: {
      top: '932px',
    },
    height: '500px',
    width: '2%',
    position: 'absolute',
    backgroundColor:'#efc94c',
  } ,
  columnaAzul:{
    left: '0%',
    top: '1470px',
    height: '500px',
    ['@media (max-width:1209px)']: {
      top: '1445px',
    },
    width: '2%',
    position: 'absolute',
    backgroundColor:'#99ccd9',
  } ,
  typographyPersonajes:{
    color:'white',
    fontWeight: 'bold',
  },
  xCerrar:{
    right: '10px',
    marginTop: '10px',
    position: 'absolute',
    width: '50px',
    height: '50px',
    cursor: 'pointer'

  },
  typographyCerrar:{
    position:'absolute',
    fontSize:'25px', 
    color:'white',
    fontWeight: 'bold',
  },
  imageSize:{
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center'
  },
  descriptionImageMobileColor: {
    color: '#314c5d',
    fontWeight: 'bold',
    fontSize: '16.8px',
    marginLeft:'1em',
    marginBottom: '0.5em',
    minHeight: '40px',
    lineHeight: '1',
    marginTop: '6px'

  },

  imagenMasMobile: {
    width: '3.5em',
    marginLeft: '1em',
    marginTop: '1em',
    marginBottom: '0.5em'
  },  
  imagenIconoBola: {
    width: '150%',
    height: '100%',
    float: 'left',
    marginLeft: '20%',
    marginTop: '35%',
  },
  imagenIconoBolaMobile: {
    width: '30em',
    float: 'left',
    left: '30%',
    marginTop: '5em',
    position: 'absolute',
  },
  imagenIconoCirculo: {
    width: '130%',
    height: '100%',
    float: 'left',
    marginLeft: '-37%',
    marginTop: '30%',
  },
  imagenIconoCirculoMobile: {
    width: '20em',
    float: 'left',
    position: 'absolute',
    margin: 'auto',
    left: '0',
    right: '0'

  },
  imagenIconoTornado: {
    width: '130%',
    height: '110%',
    float: 'left',
    marginLeft: '-12%',
    marginTop: '45%',
  },
  boxMobile: {
    width:'95%',
    maxWidth: '45em',
    height: '27em',
    margin: '1em auto', 
    padding: '1%',
  },  
  divBtn1:{ 
    margin: '0.1em 0 0 97.7%', 
    backgroundColor: 'white',
    width: '2em',   
    height:'1.8em', 
    borderBottomLeftRadius: '10px 10px',  
   },
   iconBtn1:{
    width: '1em', 
    height:'1em',   
  },
  divBtn1Mobile:{ 
    position: 'absolute',
    top: '5px',
    right: '5px',
    width: '2em',
    height: '2em',

   },
  divTituloPopupMobile: {
    margin: '1% 0 0 5%',
    position: 'absolute',

  },
  divTituloPopup2: {
    margin: '1% 0 0 2%',
    position: 'absolute',
    width: '35%'
  },
  
  moreInformationClose: {
    overflowX: 'hidden',
    display: 'none',
  },
  moreInformationCloseMobile: {
    overflowX: 'hidden',
    display: 'none',
  },
  moreInformation: {
    position: 'absolute',
    overflow: 'hidden',
    
  },
  moreInformationMobile: {
    position: 'absolute',
    overflow: 'hidden',
    height: '27em',
    width:'87%',
    maxWidth: '45em',
    left: "0",
    right: "0",
    margin: 'auto',
  },
  titleVerMas: {
    color: 'white',
    cursor: 'pointer',
    fontFamily: 'proxima-nova',
    fontWeight: '100'
  },


  columnaNegra:{
    height: '60%',
    width: '4%',
    margin: '0.6% 0 0 -18%',
    position: 'absolute'
  } ,
  boxLargo:{
    backgroundColor: '#9e9c9c',
    width: '928px',    
    position: 'relative',
    marginTop: '-65px',
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  flechaVerMas: {
    color: 'white',
    cursor: 'pointer',
    fontSize: '46px',
    position: 'absolute',
    top: '4px'
  },
  boxLargoMobile:{
    backgroundColor: '#9e9c9c',
    maxWidth: '45em',
    width:'95%',
    position: 'relative',
    marginTop: '1em',
    height: '4em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginBottom: '10px'
  },
  imagenDescription: {
    position: 'absolute',
    top: '50px',
    left: '61%',
    width: '38%'
  },
  imagenDescriptionMobile:{
    width: '80%',
    position: 'absolute',
    top: '30px',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
    maxHeight: '240px',
    maxWidth: '240px'
  
  },
  contenedor:{
    marginTop: '-66px'
  }
  
}));

export default function ProyectosPage() {
  console.log = console.warn = console.error = () => {};
  const [show, toggleShow] = React.useState(false);

  const [state2] = React.useState(true);
  const { x } = useSpring({ from: { x: 0 }, x: state2 ? 1 : 0, config: { duration: 700 } });

  const [spacing] = React.useState(2);
  
  const [state, setState] = React.useState({
    mobileView: false,
  });
  const [openInformation, setopenInformation] = React.useState(false);
  const [openInformationMobile, setopenInformationMobile] = React.useState(false);
  const [openInformationMobile2, setopenInformationMobile2] = React.useState(false);
  const [openInformationMobile3, setopenInformationMobile3] = React.useState(false);
  const [openInformationMobile4, setopenInformationMobile4] = React.useState(false);
  const [openInformationMobile5, setopenInformationMobile5] = React.useState(false);
  const [openInformationMobile7, setopenInformationMobile7] = React.useState(false);
  const [openInformation2, setopenInformation2] = React.useState(false);
  const [openInformation3, setopenInformation3] = React.useState(false);
  const [openInformation4, setopenInformation4] = React.useState(false);
  const [openInformation5, setopenInformation5] = React.useState(false);
  const [openInformation6, setopenInformation6] = React.useState(false);
  const [openInformationArrow, setopenInformationArrow] = React.useState(false);
  const [openInformationArrow2, setopenInformationArrow2] = React.useState(false);
  const [openInformationArrow3, setopenInformationArrow3] = React.useState(false);
  const [openInformationArrow4, setopenInformationArrow4] = React.useState(false);
  const [openInformationArrow5, setopenInformationArrow5] = React.useState(false);
  const [openInformationArrow6, setopenInformationArrow6] = React.useState(false);
  const [openInformationArrowMobile, setopenInformationArrowMobile] = React.useState(false);
  const [openInformationArrowMobile2, setopenInformationArrowMobile2] = React.useState(false);
  const [openInformationArrowMobile3, setopenInformationArrowMobile3] = React.useState(false);
  const [openInformationArrowMobile4, setopenInformationArrowMobile4] = React.useState(false);
  const [openInformationArrowMobile5, setopenInformationArrowMobile5] = React.useState(false);
  const [openInformationArrowMobile7, setopenInformationArrowMobile7] = React.useState(false);

  const handleOpen = () => {
    setopenInformation(true);    
  };

  const handleOpenMobile = () => {
    setopenInformationMobile(true);    
  }; 
  const handleOpenMobile2 = () => {
    setopenInformationMobile2(true);    
  }; 
  const handleOpenMobile3 = () => {
    setopenInformationMobile3(true);    
  }; 
  const handleOpenMobile4 = () => {
    setopenInformationMobile4(true);    
  }; 
  const handleOpenMobile5 = () => {
    setopenInformationMobile5(true);    
  }; 
  const handleOpenMobile7 = () => {
    setopenInformationMobile7(true);    
  }; 
  const handleOpen2 = () => {
    setopenInformation2(true);    
  };

  const handleOpen3 = () => {
    setopenInformation3(true);    
  };
  const handleOpen4 = () => {
    setopenInformation4(true);    
  };
  const handleOpen5 = () => {
    setopenInformation5(true);    
  };
  const handleOpen6 = () => {
    setopenInformation6(true);    
  };

  const handleOpenArrow = () => {
    setopenInformationArrow(true);    
    setopenInformation(false);
  };
  const handleOpenArrow2 = () => {
    setopenInformationArrow2(true);    
    setopenInformation2(false);
  };
  const handleOpenArrow3 = () => {
    setopenInformationArrow3(true);    
    setopenInformation3(false);
  };
  const handleOpenArrow4 = () => {
    setopenInformationArrow4(true);    
    setopenInformation4(false);
  };
  const handleOpenArrow5 = () => {
    setopenInformationArrow5(true);    
    setopenInformation5(false);
  };
  const handleOpenArrow6 = () => {
    setopenInformationArrow6(true);    
    setopenInformation6(false);
  };
  const handleOpenArrowMobile = () => {
    setopenInformationArrowMobile(true);    
    setopenInformationMobile(false);
  };
  const handleOpenArrowMobile2 = () => {
    setopenInformationArrowMobile2(true);    
    setopenInformationMobile2(false);
  };
  const handleOpenArrowMobile3 = () => {
    setopenInformationArrowMobile3(true);    
    setopenInformationMobile3(false);
  };
  const handleOpenArrowMobile4 = () => {
    setopenInformationArrowMobile4(true);    
    setopenInformationMobile4(false);
  };
  const handleOpenArrowMobile5 = () => {
    setopenInformationArrowMobile5(true);    
    setopenInformationMobile5(false);
  };
  const handleOpenArrowMobile7 = () => {
    setopenInformationArrowMobile7(true);    
    setopenInformationMobile7(false);
  };
  const handleOpenArrowLeft = () => {
    setopenInformation(true);    
    setopenInformationArrow(false);
  };
  const handleOpenArrowLeft2 = () => {
    setopenInformation2(true);    
    setopenInformationArrow2(false);
  };
  const handleOpenArrowLeft3 = () => {
    setopenInformation3(true);    
    setopenInformationArrow3(false);
  };
  const handleOpenArrowLeft4 = () => {
    setopenInformation4(true);    
    setopenInformationArrow4(false);
  };
  const handleOpenArrowLeft5 = () => {
    setopenInformation5(true);    
    setopenInformationArrow5(false);
  };
  const handleOpenArrowLeft6 = () => {
    setopenInformation6(true);    
    setopenInformationArrow6(false);
  };
  const handleOpenArrowLeftMobile = () => {
    setopenInformationMobile(true);    
    setopenInformationArrowMobile(false);
  };
  const handleOpenArrowLeftMobile2 = () => {
    setopenInformationMobile2(true);    
    setopenInformationArrowMobile2(false);
  };
  const handleOpenArrowLeftMobile3 = () => {
    setopenInformationMobile3(true);    
    setopenInformationArrowMobile3(false);
  };
  const handleOpenArrowLeftMobile4 = () => {
    setopenInformationMobile4(true);    
    setopenInformationArrowMobile4(false);
  };
  const handleOpenArrowLeftMobile5 = () => {
    setopenInformationMobile5(true);    
    setopenInformationArrowMobile5(false);
  };
  const handleOpenArrowLeftMobile7 = () => {
    setopenInformationMobile7(true);    
    setopenInformationArrowMobile7(false);
  };
  const handleClose = () => {
    setopenInformation(false);
    
  };
  const handleClose2 = () => {
    setopenInformation2(false);
    
  };
  const handleClose3 = () => {
    setopenInformation3(false);
    
  };
  const handleClose4 = () => {
    setopenInformation4(false);
    
  };
  const handleClose5 = () => {
    setopenInformation5(false);
    
  };
  const handleClose6 = () => {
    setopenInformation6(false);
    
  };
  const handleCloseArrow = () => {
    setopenInformationArrow(false);
    
  };
  const handleCloseArrow2 = () => {
    setopenInformationArrow2(false);
    
  };
  const handleCloseArrow3 = () => {
    setopenInformationArrow3(false);
    
  };
  const handleCloseArrow4 = () => {
    setopenInformationArrow4(false);
    
  };
  const handleCloseArrow5 = () => {
    setopenInformationArrow5(false);    
  };
  const handleCloseArrow6 = () => {
    setopenInformationArrow6(false);    
  };
  const handleCloseArrowMobile = () => {
    setopenInformationArrowMobile(false);    
  };
  const handleCloseArrowMobile2 = () => {
    setopenInformationArrowMobile2(false);    
  };
  const handleCloseArrowMobile3 = () => {
    setopenInformationArrowMobile3(false);    
  };
  const handleCloseArrowMobile4 = () => {
    setopenInformationArrowMobile4(false);    
  };
  const handleCloseArrowMobile5 = () => {
    setopenInformationArrowMobile5(false);    
  };
  const handleCloseArrowMobile7 = () => {
    setopenInformationArrowMobile7(false);    
  };

  const handleCloseMobile = () => {
    setopenInformationMobile(false);
    
  };
  const handleCloseMobile2 = () => {
    setopenInformationMobile2(false);
    
  };
  const handleCloseMobile3 = () => {
    setopenInformationMobile3(false);
    
  };
  const handleCloseMobile4 = () => {
    setopenInformationMobile4(false);
    
  };
  const handleCloseMobile5 = () => {
    setopenInformationMobile5(false);
    
  };
  const handleCloseMobile7 = () => {
    setopenInformationMobile7(false);
    
  };
  
  const { mobileView } = state;
  const classes = useStyles();
  function componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",})
    
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }



  React.useEffect(()=>{
    componentDidMount();
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const displayMobile = () => {

    return (
      <div style={{backgroundColor: 'white'}}>
        <CssBaseline />
          <Container >
                      <animated.div
                        style={{
                          transition: 'all 1.5s linear',
                          opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                        }}
                      >
                        <br></br>
                        <Typography variant="h6" component="h6" className={classes.proyectosMobile}>
                          <div className={classes.ubicacionPuntosMobile}>
                            <img className={classes.sizePuntosMobile} src={puntos} alt="" />
                          </div> 
                          <FormattedMessage id = "ProyectosPage1" defaultMessage="PROYECTOS" />
                        </Typography>
                      </animated.div>
                      <animated.div
                        style={{
                          transition: 'all 2s linear',
                          opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                        }}
                      >
                        <Typography variant="h6" component="h6" className={classes.taglineMobile}> 
                          <FormattedMessage id = "ProyectosPage2" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" /> 
                        </Typography>
                      </animated.div>
                      <Box bgcolor="#314c5d" className={clsx(classes.moreInformationMobile, !openInformationMobile && classes.moreInformationCloseMobile)}>                    
                        <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile} />                                               
                          <div className={classes.divTituloPopupMobile}>
                            <Typography  className={classes.titleImagePopupMobile}>
                              <FormattedMessage id = "ProyectosPage3" defaultMessage="MEXICO" />
                            </Typography>
                            <img className={classes.imagenBanderaPopupMobile} src={banderaMexico} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopupMobile} >                           
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageM2"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageM3"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageM4"  />
                            </Typography>
                          </div >                        
                      </Box>
                    <animated.div
                      style={{
                        transition: 'all 2.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxMobile} bgcolor="#314c5d" p={1} >
                        <Typography variant="h5" className={classes.titleImageMobile}>
                          <FormattedMessage id = "ProyectosPage3" defaultMessage="MEXICO" />
                        </Typography>
                        <img className={classes.imagenBoxMobile} src={banderaMexico} alt=""/>
                        <Typography className={classes.descriptionImageMobile}>
                        </Typography>
                        <Typography className={classes.textImageMobile}>
                          <FormattedMessage id = "ProyectosPage5" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />                        
                        </Typography>
                        <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile} alt=""/>
                      </Box>
                    </animated.div>
                    <animated.div
                      style={{
                        transition: 'all 3s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxIconoMobile} bgcolor="#df5a49" p={1}>            
                        <img className={classes.imagenIconoBolaMobile} src={bolaAzul} alt=""/>
                      </Box> 
                    </animated.div>   
                    <Box bgcolor="#99ccd9" className={clsx(classes.moreInformationMobile, !openInformationMobile2 && classes.moreInformationCloseMobile)}>
                    <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile2} />                                               
                        <div align="left" className={classes.divTituloPopupMobile}>
                          <Typography  className={classes.titleImagePopupMobileColor}>
                          <FormattedMessage id = "ProyectosPage48"/>
                          </Typography>
                          <img className={classes.imagenBanderaPopupMobile} src={banderaColombia} alt=""/>                               
                        </div>
                        <div className={classes.divDescriptionPopupMobile} >                          
                            <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageC2"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageC3"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageC4"  />
                            </Typography>
                          </div >
                    </Box>
                  <animated.div
                    style={{
                      transition: 'all 3s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box className={classes.boxMobile} bgcolor="#99ccd9" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobileColor}>
                      <FormattedMessage id = "ProyectosPage48"/>
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaColombia} alt=""/>
                      <Typography className={classes.descriptionImageMobileColor}>
                      </Typography>
                      <Typography className={classes.textImageMobileColor}>
                        <FormattedMessage id = "ProyectosPage8" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile2} alt=""/>
                    </Box>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 3.5s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box className={classes.boxIconoMobile} bgcolor="#efc94c" p={1}>            
                      <img className={classes.imagenIconoCirculoMobile} src={circuloAzul} alt=""/>
                    </Box> 
                  </animated.div>     
                  <Box bgcolor="#314c5d" className={clsx(classes.moreInformationMobile, !openInformationMobile3 && classes.moreInformationCloseMobile)}>
                  <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile3} />                                               

                      <div align="left" className={classes.divTituloPopupMobile}>
                        <Typography  className={classes.titleImagePopupMobile}>
                          <FormattedMessage id = "ProyectosPage17" defaultMessage="HONDURAS" />                  
                        </Typography>
                        <img className={classes.imagenBanderaPopupMobile} src={banderaHonduras} alt=""/>                               
                      </div>
                      <div className={classes.divDescriptionPopupMobile} >                          
                            
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageH2"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageH3"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageH4"  />
                            </Typography>
                          </div >
                    </Box>
                  <animated.div
                    style={{
                      transition: 'all 4s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box className={classes.boxMobile} bgcolor="#314c5d" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobile}>
                        <FormattedMessage id = "ProyectosPage17" defaultMessage="HONDURAS" />
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaHonduras} alt=""/>
                      <Typography className={classes.descriptionImageMobile}>
                      </Typography>
                      <Typography className={classes.textImageMobile}>
                        <FormattedMessage id = "ProyectosPage19" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " /> 
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile3} alt=""/>
                    </Box>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 4.5s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box className={classes.boxIconoMobile} bgcolor="#00bc9d" p={1}>            
                      <img className={classes.imagenIconoTornadoMobile} src={tornadoAzul} alt=""/>
                    </Box> 
                  </animated.div>     
                  <Box bgcolor="#99ccd9" className={clsx(classes.moreInformationMobile, !openInformationMobile4 && classes.moreInformationCloseMobile)}>
                  <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile4} />                                               

                      <div align="left" className={classes.divTituloPopupMobile}>
                        <Typography  className={classes.titleImagePopupMobileColor}>
                          <FormattedMessage id = "ProyectosPage20" defaultMessage="COSTA RICA" />
                        </Typography>
                        <img className={classes.imagenBanderaPopupMobile} src={banderaCostaRica} alt=""/>                               
                      </div>
                      <div className={classes.divDescriptionPopupMobile} >
                      <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageCR2"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageCR3"  />
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageCR4"  />
                            </Typography>
                      </div >
                    </Box>
                  <animated.div
                    style={{
                      transition: 'all 4.5s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box className={classes.boxMobile} bgcolor="#99ccd9" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobileColor}>
                        <FormattedMessage id = "ProyectosPage20" defaultMessage="COSTA RICA" />
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaCostaRica} alt=""/>
                      <Typography className={classes.descriptionImageMobileColor}>
                      </Typography>
                      <Typography className={classes.textImageMobileColor}>
                        <FormattedMessage id = "ProyectosPage22" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " /> 
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile4} alt=""/>
                    </Box>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 4.5s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                  <Box className={classes.boxIconoMobile} bgcolor="#df5a49" p={1}>            
                        <img className={classes.imagenIconoBolaMobile} src={bolaAzul} alt=""/>
                  </Box> 
                  </animated.div>     
                  <Box bgcolor="#314c5d" className={clsx(classes.moreInformationMobile, !openInformationMobile5 && classes.moreInformationCloseMobile)}>
                  <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile5} />                                               

                      <div align="left" className={classes.divTituloPopupMobile}>
                        <Typography  className={classes.titleImagePopupMobile}>
                          <FormattedMessage id = "ProyectosPage37" defaultMessage="PANAMÁ" />
                        </Typography>
                        <img className={classes.imagenBanderaPopupMobile} src={banderaPanama} alt=""/>                               
                      </div>
                      <div className={classes.divDescriptionPopupMobile} >
                        <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageP1"  />
                        </Typography>
                        <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageP2"  />
                        </Typography>
                      </div >
                    </Box>
                  <animated.div
                    style={{
                      transition: 'all 4.5s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box className={classes.boxMobile} bgcolor="#314c5d" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobile}>
                        <FormattedMessage id = "ProyectosPage31" defaultMessage="PANAMÁ" />
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaPanama} alt=""/>
                      <Typography className={classes.descriptionImageMobile}>
                      </Typography>
                      <Typography className={classes.textImageMobile}>
                        <FormattedMessage id = "ProyectosPage33" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                         
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile5} alt=""/>
                    </Box>
                  </animated.div> 
                  {show && <div>
                    <Box className={classes.boxIconoMobile} bgcolor="#efc94c" p={1}>            
                      <img className={classes.imagenIconoCirculoMobile} src={circuloAzul} alt=""/>
                    </Box> 
                    <Box bgcolor="#99ccd9" className={clsx(classes.moreInformationMobile, !openInformationMobile2 && classes.moreInformationCloseMobile)}>
                    <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile2} />                                               

                        <div align="left" className={classes.divTituloPopupMobile}>
                          <Typography  className={classes.titleImagePopupMobileColor}>
                            VENEZUELA
                          </Typography>
                          <img className={classes.imagenBanderaPopupMobile} src={banderaVenezuela} alt=""/>                               
                        </div>
                        <div className={classes.divDescriptionPopupMobile} >
                        <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageV"  />
                            </Typography>
                        </div >
                    </Box>
                    <Box className={classes.boxMobile} bgcolor="#99ccd9" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobileColor}>
                        VENEZUELA
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaVenezuela} alt=""/>
                      <Typography className={classes.descriptionImageMobileColor}>
                      </Typography>
                      <Typography className={classes.textImageMobileColor}>
                        <FormattedMessage id = "ProyectosPageV" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile2} alt=""/>
                    </Box>
                    <Box className={classes.boxIconoMobile} bgcolor="#00bc9d" p={1}>            
                      <img className={classes.imagenIconoTornadoMobile} src={tornadoAzul} alt=""/>
                    </Box> 
                    <Box bgcolor="#314c5d" className={clsx(classes.moreInformationMobile, !openInformationMobile && classes.moreInformationCloseMobile)}>                    
                    <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile} />                                               

                          <div className={classes.divTituloPopupMobile}>
                            <Typography  className={classes.titleImagePopupMobileGE}>
                            <FormattedMessage id = "ProyectosPage47"/>
                            </Typography>
                            <img className={classes.imagenBanderaPopupMobile} src={banderaGuinea} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopupMobileGE} >                            
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageGE2"/>
                            </Typography>
                            <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageGE3"/>
                            </Typography>                            
                          </div >                        
                    </Box>
                    <animated.div
                      style={{
                        transition: 'all 2.5s linear',
                        opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                      }}
                    >
                      <Box className={classes.boxMobile} bgcolor="#314c5d" p={1} >
                        <Typography variant="h5" className={classes.titleImageMobile}>
                        <FormattedMessage id = "ProyectosPage47"/>
                        </Typography>
                        <img className={classes.imagenBoxMobile} src={banderaGuinea} alt=""/>
                        <Typography className={classes.descriptionImageMobile}>
                        </Typography>
                        <Typography className={classes.textImageMobile}>
                          <FormattedMessage id = "ProyectosPage36"  />                        
                        </Typography>
                        <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile} alt=""/>
                      </Box>
                    </animated.div>
                    <Box className={classes.boxIconoMobile} bgcolor="#df5a49" p={1}>            
                        <img className={classes.imagenIconoBolaMobile} src={bolaAzul} alt=""/>
                    </Box>
                    <Box bgcolor="#99ccd9" className={clsx(classes.moreInformationMobile, !openInformationMobile2 && classes.moreInformationCloseMobile)}>
                    <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile2} />                                               

                        <div align="left" className={classes.divTituloPopupMobile}>
                          <Typography  className={classes.titleImagePopupMobileColorUSA}>
                          <FormattedMessage id = "ProyectosPage34"  />
                          </Typography>
                          <img className={classes.imagenBanderaPopupMobile} src={banderaUsa} alt=""/>                               
                        </div>
                        <div className={classes.divDescriptionPopupMobileGE} >
                         <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageUSA1"  />
                          </Typography>
                          <Typography className={classes.descriptionImagePopupMobileColor}>
                            <FormattedMessage id = "ProyectosPageUSA2"  />
                          </Typography>
                        </div >
                    </Box>
                  <Box bgcolor="#99ccd9"  className={clsx(classes.moreInformationMobile, !openInformationArrowMobile2 && classes.moreInformationClose)}>
                  <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseArrowMobile2} />                                               

                      <div align="left" className={classes.divTituloPopupMobile}>
                        <Typography  className={classes.titleImagePopupMobileColorUSA}>
                        <FormattedMessage id = "ProyectosPage34"  />
                        </Typography>
                        <img className={classes.imagenBanderaPopupMobile} src={banderaUsa} alt=""/>                               
                      </div>
                      <img className={classes.imagenDescriptionMobile} src={imagenPopup5}></img>

                      <KeyboardArrowLeftIcon  className={classes.flechaIzquierdaMobile} onClick={handleOpenArrowLeftMobile2} />                       
                    </Box>
                    <Box className={classes.boxMobile} bgcolor="#99ccd9" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobileColor}>
                      <FormattedMessage id = "ProyectosPage34"  />
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaUsa} alt=""/>
                      <Typography className={classes.descriptionImageMobileColor}>
                      </Typography>
                      <Typography className={classes.textImageMobileColor}>
                        <FormattedMessage id = "ProyectosPageUSAM" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile2} alt=""/>
                    </Box>
                    <Box className={classes.boxIconoMobile} bgcolor="#efc94c" p={1}>            
                      <img className={classes.imagenIconoCirculoMobile} src={circuloAzul} alt=""/>
                    </Box> 
                    <Box bgcolor="#314c5d" className={clsx(classes.moreInformationMobile, !openInformationMobile5 && classes.moreInformationCloseMobile)}>
                  <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile5} />
                      <div align="left" className={classes.divTituloPopupMobile}>
                        <Typography  className={classes.titleImagePopupMobile}>
                        <FormattedMessage id = "ProyectosPage49" />
                        </Typography>
                        <img className={classes.imagenBanderaPopupMobile} src={banderaEcuador} alt=""/>                               
                      </div>
                      <div className={classes.divDescriptionPopupMobile} >
                      <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageE1"  />
                        </Typography>
                        <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageE2"  />
                        </Typography>
                        <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageE3"  />
                        </Typography>
                      </div >
                    </Box>              
                    <Box className={classes.boxMobile} bgcolor="#314c5d" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobile}>
                      <FormattedMessage id = "ProyectosPage49" />                     
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaEcuador} alt=""/>
                      <Typography className={classes.descriptionImageMobile}>
                      </Typography>
                      <Typography className={classes.textImageMobile}>
                        <FormattedMessage id = "ProyectosPage46" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                         
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile5} alt=""/>
                    </Box>
                    <Box className={classes.boxIconoMobile} bgcolor="#df5a49" p={1}>            
                        <img className={classes.imagenIconoBolaMobile} src={bolaAzul} alt=""/>
                    </Box> 
                    <Box bgcolor="#314c5d" className={clsx(classes.moreInformationMobile, !openInformationMobile7 && classes.moreInformationCloseMobile)}>
                  <img className={classes.divBtn1Mobile} src={cerrarRojo} onClick={handleCloseMobile7} />
                      <div align="left" className={classes.divTituloPopupMobile}>
                        <Typography  className={classes.titleImagePopupMobile}>
                          GUATEMALA
                        </Typography>
                        <img className={classes.imagenBanderaPopupMobile} src={banderaGuatemala} alt=""/>                               
                      </div>
                      <div className={classes.divDescriptionPopupMobile} >
                      <Typography className={classes.descriptionImagePopupMobile2}>
                            <FormattedMessage id = "ProyectosPageG"  />
                        </Typography>
                      </div >
                    </Box>               
                    <Box className={classes.boxMobile} bgcolor="#314c5d" p={1} >
                      <Typography variant="h5" className={classes.titleImageMobile}>
                      GUATEMALA
                      </Typography>
                      <img className={classes.imagenBoxMobile} src={banderaGuatemala} alt=""/>
                      <Typography className={classes.descriptionImageMobile}>
                      </Typography>
                      <Typography className={classes.textImageMobile}>
                        <FormattedMessage id = "ProyectosPage7" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                         
                      </Typography>
                      <img className={classes.imagenMasMobile} src={masRojo} onClick={handleOpenMobile7} alt=""/>
                    </Box>
                      </div>} 
                  <Box className={classes.boxLargoMobile} p={1} >
                      <Typography variant="h5" style={{marginRight: '10%'}} className={classes.titleVerMas} onClick={() => toggleShow(!show)}>{show ? <div><FormattedMessage id = "InicioPage50"/><ArrowDropUpIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div> :<div> <FormattedMessage id = "InicioPage43"/><ArrowDropDownIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div>} </Typography>
                  </Box>              
      </Container>
    </div>
  );
}


const displayDesktop = () => {

return (
	<div style={{backgroundColor: 'white'}}>
    <CssBaseline  />
      <Container >             
        
                  <animated.div
                    style={{
                      transition: 'all 0s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box p={1} className={classes.columnaVerde} >
                    </Box>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 0s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box p={1} className={classes.columnaAmarilla} >
                    </Box>
                  </animated.div>
                  <animated.div
                    style={{
                      transition: 'all 0s linear',
                      opacity: x.interpolate({ range: [0, 700], output: [0, 700] }),
                    }}
                  >
                    <Box p={1} className={classes.columnaAzul} >
                    </Box>
                  </animated.div>
                  <br></br><br></br>
                  <animated.div
	                  style={{
	                    transition: 'all 0.2s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                    <Typography variant="h4" component="h2" className={classes.proyectos }>
                      <div className={classes.ubicacionPuntos}>
                        <img className={classes.sizePuntos} src={puntos} alt="" />
                      </div> 
                      <FormattedMessage id = "ProyectosPage1" defaultMessage="PROYECTOS" />                    
                    </Typography><br></br>
                  </animated.div>
                  <animated.div
	                  style={{
	                    transition: 'all 0.7s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                    <Typography variant="h5" component="h2" className={classes.tagline}> 
                      <FormattedMessage id = "ProyectosPage2" defaultMessage="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" />
                    </Typography>
                  </animated.div>
                  <animated.div
	                  style={{
	                    transition: 'all 0.7s linear',
                      opacity: x.interpolate({ range: [0, 100], output: [0, 100] }),
                    }}
                  >
                  <Box  style={{display: 'grid', width:'950px', gridTemplateColumns: "repeat(3, 300px)", gap: '1em',justifyContent: 'center', margin: 'auto', marginBottom: '80px'}}>
                    <Box className={classes.box} bgcolor="#334d5c" p={1} >
                        <Typography className={classes.titleImage}>
                          <FormattedMessage id = "ProyectosPage3" defaultMessage="MEXICO" />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaMexico} alt=""/>                        
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPage5" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />                          
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen} src={masRojo} ></img> 
                      </Box>
                      <Box className={classes.boxIcono} bgcolor="#df5a49" p={1}>            
                        <img className={classes.imagenIconoBola} src={bolaAzul} alt=""/>
                      </Box>          
                      <Box className={classes.box} bgcolor="#99ccd9" p={1}>
                        <Typography  className={classes.titleImageColor}>
                        <FormattedMessage id = "ProyectosPage48"/>
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaColombia} alt=""/>                       
                        <Typography className={classes.textImageColor}>
                          <FormattedMessage id = "ProyectosPage8" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                          
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen2} src={masRojo} ></img>        
                      </Box>                          
                      <Box  style={{ padding: '0'}} className={clsx(classes.moreInformation, !openInformation && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#334D5C">
                          
                          <div>
                            <div>                                
                                <div>
                                 <img src={cerrarRojo} onClick={handleClose}  className={classes.xCerrar}></img>                       
                                </div>
                                </div>
                          </div>

                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage9" defaultMessage="MEXICO" />
                            </Typography><br></br>
                            <img className={classes.imagenBanderaPopup2} src={banderaMexico} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >        
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageM1"  />  
                           </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageM2"  /> 
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageM3"  />   
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageM4"  />      
                            </Typography>                       
                          </div >
                          <img className={classes.imagenDescription} src={prueba} alt=""/>                    
                         {/* <KeyboardArrowRightIcon className={classes.flechaDerecha} onClick={handleOpenArrow} />  */}
                        </Box>                            
                      </Box>      
                      <Box  style={{ padding: '0', }}   className={clsx(classes.moreInformation, !openInformationArrow && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#334d5c" >
                
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow}  className={classes.xCerrar}></img>                                </div>
                            </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage11" defaultMessage="MEXICO" />  
                            </Typography><br></br>
                            <img className={classes.imagenBanderaPopup2} src={banderaMexico} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage12" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon  className={classes.flechaIzquierda} onClick={handleOpenArrowLeft} />                       
                        </Box>
                      </Box>                    
                      <Box  style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformation2 && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#99ccd9" >
                       
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose2}  className={classes.xCerrar}></img>                       

                                </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor} >
                            <FormattedMessage id = "ProyectosPage48"/>
                            </Typography><br></br>
                            <img className={classes.imagenBanderaPopup2} src={banderaColombia} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageC1" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageC2" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageC3" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageC4" />
                          </Typography>
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup} alt=""/>
                         {/* <KeyboardArrowRightIcon  className={classes.flechaDerecha} onClick={handleOpenArrow2} />  */}                     
                        </Box>
                      </Box>
                      <Box style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformationArrow2 && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#99ccd9" >
   
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow2}  className={classes.xCerrar}></img>                       

                                </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                            <FormattedMessage id = "ProyectosPage48"/>
                            </Typography><br></br>
                            <img className={classes.imagenBanderaPopup2} src={banderaColombia} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage16" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon  className={classes.flechaIzquierda} onClick={handleOpenArrowLeft2} />                       
                        </Box>
                      </Box>             
                   
                      <Box className={classes.boxIcono} bgcolor="#efc94c" p={1}>            
                        <img className={classes.imagenIconoCirculo} src={circuloAzul} alt=""/>
                      </Box>                           
                      <Box className={classes.box} bgcolor="#99ccd9" p={1}>
                        <Typography  className={classes.titleImageColor}>
                          <FormattedMessage id = "ProyectosPage17" defaultMessage="HONDURAS" />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaHonduras} alt=""/>
                       
                        <Typography className={classes.textImageColor}>
                          <FormattedMessage id = "ProyectosPage19" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen3} src={masRojo} ></img> 
                        </Box>                            
                      <Box className={classes.box} bgcolor="#334d5c" p={1} >
                        <Typography  className={classes.titleImage}>
                          <FormattedMessage id = "ProyectosPage20" defaultMessage="COSTA RICA" />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaCostaRica} alt=""/>
                       
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPage22" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />                          
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen4} src={masRojo} ></img>                      </Box>
                      <Box  style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformation3 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px', position: 'relative'}} className={classes.boxPopup} bgcolor="#99ccd9" >
                        
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose3}  className={classes.xCerrar}></img>                                </div>
                            </div>                   
                          <div  className={classes.divTituloPopup}>                            
                            <Typography  className={classes.titleImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage23" defaultMessage="HONDURAS" />
                            </Typography><br></br>      
                            <img className={classes.imagenBanderaPopup2} src={banderaHonduras} alt=""/>                                                    
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageH1" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageH2" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageH3" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2Color}>
                              <FormattedMessage id = "ProyectosPageH4" />
                          </Typography>
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup2} alt=""/>
                         {/* <KeyboardArrowRightIcon style={{marginTop: '300px'}} className={classes.flechaDerecha}  onClick={handleOpenArrow3} />  */}                     
                        </Box>
                      </Box>
                      <Box style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformationArrow3 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px'}} className={classes.boxPopup} bgcolor="#99ccd9" >
                    
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow3}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage25" defaultMessage="HONDURAS" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaHonduras} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage26" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '300px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft3}  />                       
                        </Box>
                      </Box>
                      <Box style={{padding: '0'}}  className={clsx(classes.moreInformation, !openInformation4 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px', position: 'relative'}} className={classes.boxPopup} bgcolor="#334d5c" >
                          
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose4}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage27" defaultMessage="COSTA RICA" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaCostaRica} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageCR1"  />  
                           </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageCR2"  /> 
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageCR3"  />   
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageCR4"  />      
                            </Typography>        
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup6} alt=""/>
                          {/*<KeyboardArrowRightIcon style={{marginTop: '300px'}} className={classes.flechaDerecha} onClick={handleOpenArrow4} />  */}                     
                        </Box>
                      </Box>
                      <Box style={{padding: '0',}} className={clsx(classes.moreInformation, !openInformationArrow4 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px'}} className={classes.boxPopup} bgcolor="#334d5c" >
                          
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow4}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage29" defaultMessage="COSTA RICA" />  
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaCostaRica} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage30" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />                               
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '300px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft4}  />                       
                        </Box>
                      </Box>
                    
                      <Box className={classes.box} bgcolor="#334d5c" p={1} >
                        <Typography  className={classes.titleImage}>
                          <FormattedMessage id = "ProyectosPage31" defaultMessage="PANAMÁ" />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaPanama} alt=""/>
                       
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPage33" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                           
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen5} src={masRojo} ></img>                       </Box>
                      <Box className={classes.boxIcono} bgcolor="#00bc9d" p={1}>            
                        <img className={classes.imagenIconoTornado} src={tornadoAzul} alt=""/>
                      </Box> 
                      <Box className={classes.box} bgcolor="#99ccd9" p={1}>
                        <Typography style={{lineHeight: '1'}} className={classes.titleImageColor}>
                        <FormattedMessage id = "ProyectosPage47"/>
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaGuinea} alt=""/>                       
                        <Typography className={classes.textImageColor}>
                          <FormattedMessage id = "ProyectosPage36" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen6} src={masRojo} ></img>                       </Box>         
                      <Box style={{padding: '0'}}   className={clsx(classes.moreInformation, !openInformation5 && classes.moreInformationClose)}>
                        <Box  style={{marginTop: '1028px', position: 'relative'}} className={classes.boxPopup} bgcolor="#334d5c" >
                        
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose5}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>                     
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage37" defaultMessage="PANAMÁ" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaPanama} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2}>
                              <FormattedMessage id = "ProyectosPageP1" />
                          </Typography>
                          <Typography className={classes.descriptionImagePopup2}>
                              <FormattedMessage id = "ProyectosPageP2" />
                          </Typography>                          
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup4} alt=""/>
                         {/*<KeyboardArrowRightIcon style={{marginTop: '600px'}}  className={classes.flechaDerecha} onClick={handleOpenArrow5} />*/}                       
                        </Box>
                      </Box>
                      <Box style={{padding: '0'}}   className={clsx(classes.moreInformation, !openInformationArrow5 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '1028px'}} className={classes.boxPopup} bgcolor="#334d5c" >
                                                
                        <div>
                          <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow5}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage39" defaultMessage="PANAMÁ" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaPanama} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage40" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />                               
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '600px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft5}  />                       
                        </Box>
                      </Box>
                      <Box style={{padding: '0'}}  className={clsx(classes.moreInformation, !openInformation6 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '1028px', position: 'relative'}} className={classes.boxPopup} bgcolor="#99ccd9" >
                          
                        <div>
                            <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose6}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                            <FormattedMessage id = "ProyectosPage47"/>
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaGuinea} alt="" />                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageGE1"  />      
                            </Typography>  
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageGE2"  />      
                            </Typography>  
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageGE3"  />      
                            </Typography>  
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup3} alt=""/>
                          {/*<KeyboardArrowRightIcon style={{marginTop: '600px'}} className={classes.flechaDerecha} onClick={handleOpenArrow6} /> */}                     
                        </Box>
                      </Box>     
                      <Box style={{padding: '0'}}  className={clsx(classes.moreInformation, !openInformationArrow6 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '1028px'}} className={classes.boxPopup} bgcolor="#99ccd9" >
                          
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow6}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                            <FormattedMessage id = "ProyectosPage47"/>
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaGuinea} alt="" />                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage44" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />                               
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '600px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft6}  />                       
                        </Box>
                      </Box>  
                      </Box>
                    </animated.div>
                      {show && <div className={classes.contenedor}>
                      <Box  style={{display: 'grid', width:'950px', gridTemplateColumns: "repeat(3, 300px)", gap: '1em',justifyContent: 'center', margin: 'auto', marginBottom: '80px'}}>
                      <Box className={classes.boxIcono} bgcolor="#df5a49" p={1}>            
                        <img className={classes.imagenIconoBola} src={bolaAzul} alt=""/>
                      </Box> 
                    <Box className={classes.box} bgcolor="#334d5c" p={1} >
                        <Typography className={classes.titleImage}>
                          VENEZUELA
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaVenezuela} alt=""/>                        
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPage45" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />                          
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen} src={masRojo} ></img> 
                      </Box>                               
                      <Box className={classes.box} bgcolor="#99ccd9" p={1}>
                        <Typography  className={classes.titleImageColor}>
                        <FormattedMessage id = "ProyectosPage49" />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaEcuador} alt=""/>                      
                        <Typography className={classes.textImageColor}>
                          <FormattedMessage id = "ProyectosPage46" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                          
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen2} src={masRojo} ></img>        
                      </Box>                          
                      <Box  style={{ padding: '0'}} className={clsx(classes.moreInformation, !openInformation && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#334d5c">
                          
                          <div>
                          <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>

                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              VENEZUELA
                             </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaVenezuela} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageV"  />      
                          </Typography>                   

                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup5} alt=""/>

                          {/*<KeyboardArrowRightIcon className={classes.flechaDerecha} onClick={handleOpenArrow} /> */} 
                        </Box>                            
                      </Box>      
                      <Box  style={{ padding: '0', }}   className={clsx(classes.moreInformation, !openInformationArrow && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#334d5c" >
                
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              VENEZUELA
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaVenezuela} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage12" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon  className={classes.flechaIzquierda} onClick={handleOpenArrowLeft} />                       
                        </Box>
                      </Box>                    
                      <Box  style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformation2 && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#99ccd9" >
                       
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose2}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor} >
                            <FormattedMessage id = "ProyectosPage49" />
                             </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaEcuador} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageE1"  />      
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageE2"  />      
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageE3"  />      
                            </Typography>
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup7} alt=""/>
                          {/*<KeyboardArrowRightIcon  className={classes.flechaDerecha} onClick={handleOpenArrow2} />   */}                    
                        </Box>
                      </Box>
                      <Box style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformationArrow2 && classes.moreInformationClose)}>
                        <Box  className={classes.boxPopup} bgcolor="#99ccd9" >
   
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow2}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                            <FormattedMessage id = "ProyectosPage49" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaEcuador} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage16" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon  className={classes.flechaIzquierda} onClick={handleOpenArrowLeft2} />                       
                        </Box>
                      </Box>                                           
                      <Box className={classes.box} bgcolor="#334d5c" p={1}>
                        <Typography  className={classes.titleImage}>
                          GUATEMALA
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaGuatemala} alt=""/>
                       
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPage7" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen3} src={masRojo} ></img> 
                        </Box> 
                        <Box className={classes.boxIcono} bgcolor="#efc94c" p={1}>            
                        <img className={classes.imagenIconoCirculo} src={circuloAzul} alt=""/>
                      </Box>                            
                      <Box className={classes.box} bgcolor="#334d5c" p={1} >
                        <Typography  style={{lineHeight: '1'}} className={classes.titleImage}>
                        <FormattedMessage id = "ProyectosPage34"  />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaUsa} alt=""/>
                       
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPageUSAM" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto. " />                          
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen4} src={masRojo} ></img>                      </Box>
                      <Box  style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformation3 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px', position: 'relative'}} className={classes.boxPopup} bgcolor="#334d5c" >
                        
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose3}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>                   
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              GUATEMALA
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaGuatemala} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageG"  />      
                          </Typography>  
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup6} alt=""/>
                          {/*<KeyboardArrowRightIcon style={{marginTop: '300px'}} className={classes.flechaDerecha}  onClick={handleOpenArrow3} />*/}                       
                        </Box>
                      </Box>
                      <Box style={{ padding: '0'}}  className={clsx(classes.moreInformation, !openInformationArrow3 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px'}} className={classes.boxPopup} bgcolor="#334D5C" >
                    
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow3}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              GUATEMALA
                           </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaGuatemala} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage26" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '300px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft3}  />                       
                        </Box>
                      </Box>
                      <Box style={{padding: '0'}}  className={clsx(classes.moreInformation, !openInformation4 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px', position: 'relative'}} className={classes.boxPopup} bgcolor="#334d5c" >
                          
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose4}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                            <FormattedMessage id = "ProyectosPage34"  />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaUsa} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageUSA1"  />      
                            </Typography> 
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageUSA2"  />      
                            </Typography>
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup7} alt=""/>
                          {/*<KeyboardArrowRightIcon style={{marginTop: '300px'}} className={classes.flechaDerecha} onClick={handleOpenArrow4} />  */}                     
                        </Box>
                      </Box>
                      <Box style={{padding: '0',}} className={clsx(classes.moreInformation, !openInformationArrow4 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '514px'}} className={classes.boxPopup} bgcolor="#334d5c" >
                          
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow4}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                            <FormattedMessage id = "ProyectosPage34"  />                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaUsa} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage30" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />                               
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '300px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft4}  />                       
                        </Box>
                      </Box>
                      {/* 
                      <Box className={classes.boxIcono} bgcolor="#00bc9d" p={1}>            
                        <img className={classes.imagenIconoTornado} src={tornadoAzul} alt=""/>
                      </Box> 
                      
                      <Box className={classes.box} bgcolor="#334d5c" p={1} >
                        <Typography  className={classes.titleImage}>
                          <FormattedMessage id = "ProyectosPage31" defaultMessage="PANAMÁ" />
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaPanama} alt=""/>
                        
                        <Typography className={classes.textImage}>
                          <FormattedMessage id = "ProyectosPage33" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />                           
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen5} src={masRojo} ></img>  
                      </Box>
                     */}
                     {/*
                      <Box className={classes.box} bgcolor="#99ccd9" p={1}>
                        <Typography  className={classes.titleImageColor}>
                          <FormattedMessage id = "ProyectosPage43" defaultMessage="COLOMBIA" />                          
                        </Typography>
                        <img className={classes.imagenBandera} src={banderaColombia} alt=""/>
                        
                        <Typography className={classes.textImageColor}>
                          <FormattedMessage id = "ProyectosPage8" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto.relleno de las imprentas y archivos de texto." />
                        </Typography>
                        <img className={classes.imagenMas2}  onClick={handleOpen6} src={masRojo} ></img>                       </Box>*/}         
                      <Box style={{padding: '0'}}   className={clsx(classes.moreInformation, !openInformation5 && classes.moreInformationClose)}>
                        <Box  style={{marginTop: '1028px', position: 'relative'}} className={classes.boxPopup} bgcolor="#334d5c" >
                        
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose5}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>                     
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage37" defaultMessage="PANAMÁ" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaPanama} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageP1"  />      
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2}>
                            <FormattedMessage id = "ProyectosPageP2"  />      
                            </Typography>
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup4} alt=""/>
                          {/*<KeyboardArrowRightIcon style={{marginTop: '600px'}}  className={classes.flechaDerecha} onClick={handleOpenArrow5} /> */}                      
                        </Box>
                      </Box>
                      <Box style={{padding: '0'}}   className={clsx(classes.moreInformation, !openInformationArrow5 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '1028px'}} className={classes.boxPopup} bgcolor="#334d5c" >
                                                
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow5}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopup}>
                              <FormattedMessage id = "ProyectosPage39" defaultMessage="PANAMÁ" />
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaPanama} alt=""/>                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopup}>
                              <FormattedMessage id = "ProyectosPage40" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />                               
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '600px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft5}  />                       
                        </Box>
                      </Box>
                      <Box style={{padding: '0'}}  className={clsx(classes.moreInformation, !openInformation6 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '1028px', position: 'relative'}} className={classes.boxPopup} bgcolor="#99ccd9" >
                          
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleClose6}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                            <FormattedMessage id = "ProyectosPage48"/>
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaColombia} alt="" />                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                          <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageC1"  />      
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageC2"  />      
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageC3"  />      
                            </Typography>
                            <Typography className={classes.descriptionImagePopup2Color}>
                            <FormattedMessage id = "ProyectosPageC4"  />      
                            </Typography>
                          </div >
                          <img className={classes.imagenDescription} src={imagenPopup} alt=""/>

                          {/*<KeyboardArrowRightIcon style={{marginTop: '600px'}} className={classes.flechaDerecha} onClick={handleOpenArrow6} />  */}                   
                        </Box>
                      </Box>     
                      <Box style={{padding: '0'}}  className={clsx(classes.moreInformation, !openInformationArrow6 && classes.moreInformationClose)}>
                        <Box style={{marginTop: '1028px'}} className={classes.boxPopup} bgcolor="#99ccd9" >
                          
                        <div>
                        <div align="right" >                                
                            <img src={cerrarRojo} onClick={handleCloseArrow6}  className={classes.xCerrar}></img>                       
                            </div>
                          </div>
                          <div align="left" className={classes.divTituloPopup}>
                            <Typography  className={classes.titleImagePopupColor}>
                            <FormattedMessage id = "ProyectosPage48"/>  
                            </Typography><br></br>  
                            <img className={classes.imagenBanderaPopup2} src={banderaColombia} alt="" />                               
                          </div>
                          <div className={classes.divDescriptionPopup} >
                            <Typography className={classes.descriptionImagePopupColor}>
                              <FormattedMessage id = "ProyectosPage44" defaultMessage="Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto y muchas cosas mas" />                               
                            </Typography>
                          </div >
                          <KeyboardArrowLeftIcon style={{marginTop: '600px'}} className={classes.flechaIzquierda} onClick={handleOpenArrowLeft6}  />                       
                        </Box>
                      </Box>  
                      </Box>
                  
                    
                  </div>}  
                      <Box className={classes.boxLargo} p={1} >
                      <Typography variant="h5" className={classes.titleVerMas} onClick={() => toggleShow(!show)}>{show ? <div><FormattedMessage id = "InicioPage50"/><ArrowDropUpIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div> :<div> <FormattedMessage id = "InicioPage43"/><ArrowDropDownIcon className={classes.flechaVerMas} onClick={() => toggleShow(!show)} fontSize='large'/></div>}</Typography>
                    </Box>               
               
      </Container>  
    </div>
  );
}

return (
  <header>
      {mobileView ? displayMobile() : displayDesktop()}
  </header>
);
}
