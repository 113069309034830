import React from 'react';

import InicioPage from './InicioPage';
import SolucionesPage from './SolucionesPage';
import ProyectosPage from './ProyectosPage';
import EquipoPage from './EquipoPage';
import { HashRouter, Route, Redirect} from 'react-router-dom';



export default function Routes() {
    

    return (
        <HashRouter>  
            <Route exact path="/">
                <Redirect to="/inicio" />
            </Route>          
            <Route exact path={`/inicio`}>
                <InicioPage />
            </Route>
            <Route exact path={`/soluciones`}>
                <SolucionesPage />
            </Route>
            <Route exact path={`/proyectos`}>
                <ProyectosPage />
            </Route>
            <Route exact path={`/equipo`}>
                <EquipoPage />
            </Route>
        </HashRouter>

    );
  
}