import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './Header';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import {IntlProvider} from 'react-intl';
import Spanish from './locales/translationEs.json';
import PortuguesePt from './locales/translationPt.json';
import English from './locales/translationEn.json';
import {Helmet} from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';


const locale = navigator.language;

let lang;
var langDef 
if (localStorage.getItem("lang") === "es"){
  langDef = Spanish
}else if (localStorage.getItem("lang") === "en"){
  langDef = English
}else if (localStorage.getItem("lang") === "pt"){
  langDef = PortuguesePt
}else{
  langDef = Spanish
}


ReactDOM.render(

<React.StrictMode>
  <IntlProvider locale ={locale} messages={langDef}>
      <Header />
      <Routes />
  </IntlProvider>
  <link rel="shortcut icon" href="./logo-innovahub-web-azul2.ico"></link>
    <HelmetProvider>
      <Helmet>
        <title>INNOVAHUB</title>
        <meta name="description" content="INNOVAHUB" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
    </HelmetProvider>     
</React.StrictMode>,
document.getElementById('root')
);

reportWebVitals();
